import { AuthedFetch, datadogRum } from '@avenue-8/platform-shared-util-frontend'
import { createContext, PropsWithChildren, useCallback, useRef } from 'react'
import { PaginatedResponse } from '../../projects/domain/paginated-response'
import {
  DesignHuddleProxyJob,
  DesignHuddleProxyProject,
  DesignHuddleProxyJobStatus,
} from '../domain/design-huddle-proxy-project.type'
import { DesignHuddleProxyTemplate } from '../domain/design-huddle-proxy-template.type'
import { DesignHuddleTemplateCustomizationsObject } from '../domain/design-huddle-template-customizations-object'

type CreateProjectRenderJobResponse = {
  msg: string
  jobId: number
  project: { id: string; pages: { pageId: string; pageNumber: number }[] }
}

export enum ContentContextEnum {
  'LISTING_LOCATION' = 'LISTING_LOCATION',
  'LISTING_INFORMATION_FROM_PROPERTY_SEARCH' = 'LISTING_INFORMATION_FROM_PROPERTY_SEARCH',
}

interface PropertyInfoDto {
  mlsId?: string | null
  mlsSource?: string | null
  addressLine1?: string
  addressLine2?: string | null
  area?: string | null
  numBathrooms?: number | null
  numBedrooms?: number | null
  lat?: number | null
  lng?: number | null
  listingStatus?: string | null
  city?: string
  description?: string | null
  parkingGarageSpaces?: number | null
  listDate?: Date | null
  listPrice?: number | null
  livingSpace?: number | null
  lotSize?: number | null
  neighborhood?: string | null
  propertyType?: string | null
  state?: string
  yearBuilt?: number | null
  zipCode?: string
  soldPrice?: number | null
  soldDate?: Date | null
  county?: string | null
}

export type GenerateContentParams = {
  captionContext?: string
  address?: string
  contentContexts?: ContentContextEnum[]
  maxNumberOfWords?: number
  propertyInfo?: PropertyInfoDto
  numberOfOutputs?: number
  tonality?: string
  contentType?: 'CAPTION' | 'SOCIAL_MEDIA_POST'
  targetAudience?: string
  targetAudienceTopic?: string
  hashtagsOption?: 'INCLUDE' | 'ONLY' | 'DO_NOT_INCLUDE'
}

// eslint-disable-next-line camelcase
type DesignHuddleCloneProjectResponse = { success: true; data: { project_id: string } } | { success: false }

type DesignHuddleDeleteProjectResponse = { success: boolean }

type ProjectJobResponse = { pages: { url: string; page: number }[]; status: 'complete' | 'incomplete' }

export type PresentationJobResponse = { data: { jobId: string }; success: boolean }
export type PresentationJobStatus = {
  data: { dateStarted: string; completed: boolean; dateCompleted: string; presentationUrl: string }
  success: boolean
}

export type PublishJobStatus = {
  data: { dateStarted: string; completed: boolean; dateCompleted: string }
  success: boolean
}

export type PaginationParams = {
  limit?: number
  page?: number
}

export type DesignHuddlePaginatedApiResponse<T> = {
  data: T[]
  page: number
  limit: number
  total: number
}

export type PublishTemplateResponse = {
  success: boolean
  data: {
    jobId: string
  }
}

const validatePositiveNumberToString = (param: number, defaultValue: number): string => {
  if (Number.isNaN(param)) return NaN.toString()
  if (param < 1) {
    return defaultValue.toString()
  }
  return Math.ceil(param).toString()
}

export type DesignHuddleProxyContextValue =
  | {
      getTemplates(
        categoryId: string,
        limit?: number,
        accessType?: 'admin' | 'user'
      ): Promise<PaginatedResponse<DesignHuddleProxyTemplate>>
      createProject(
        templateId: number,
        categoryId: number,
        tco?: DesignHuddleTemplateCustomizationsObject
      ): Promise<DesignHuddleProxyProject>
      getProjects(
        page?: number,
        limit?: number,
        assetType?: string
      ): Promise<DesignHuddlePaginatedApiResponse<DesignHuddleProxyProject>>
      getProjectData(projectId: string): Promise<DesignHuddleProxyProject>
      deleteProject(projectId: string): Promise<DesignHuddleDeleteProjectResponse>
      cloneProject(projectId: string): Promise<DesignHuddleCloneProjectResponse>
      createDownloadJob(
        projectId: string,
        format: 'png' | 'pdf' | 'mp4',
        selectedPage?: string
      ): Promise<DesignHuddleProxyJob>
      getDownloadJobStatus(jobId: number): Promise<DesignHuddleProxyJobStatus>
      renderProject(projectId: string, format?: string): Promise<{ pageId: string; pageNumber: number; url: string }[]>
      createPresentationLinkJob(projectId: string): Promise<PresentationJobResponse>
      downloadPresentationLinkJob(projectId: string, jobId: string): Promise<PresentationJobStatus>
      publishTemplate(projectId: string, templateTitle: string, categoryId: number): Promise<PublishTemplateResponse>
      downloadPublishJob(projectId: string, jobId: string): Promise<PublishJobStatus>
      generateContent(params: GenerateContentParams): Promise<{ outputs: Array<string> }>
    }
  | Record<string, never>

export const DesignHuddleProxyContext = createContext<DesignHuddleProxyContextValue>({})

export const DesignHuddleProxyContextProvider = ({
  baseUrl,
  children,
}: PropsWithChildren<{ baseUrl: string }>): JSX.Element => {
  const getProjectDataPendingQueriesRef = useRef(new Map<string, Promise<DesignHuddleProxyProject>>([]))

  const getTemplates = useCallback(
    (categoryId: string, limit = 100, accessType = 'user') => {
      const queryParams = new URLSearchParams({ categoryId, limit, accessType })
      return AuthedFetch(`${baseUrl}/design/templates`, { queryParams })
        .then((response): Promise<PaginatedResponse<DesignHuddleProxyTemplate>> => response.json())
        .catch(() => null)
    },
    [baseUrl]
  )

  const getProjectData = useCallback(
    (projectId: string) => {
      if (getProjectDataPendingQueriesRef.current.has(projectId)) {
        return getProjectDataPendingQueriesRef.current.get(projectId)
      }
      const promise = AuthedFetch(`${baseUrl}/design/projects/${projectId}`)
        .then((response): Promise<DesignHuddleProxyProject> => response.json())
        .catch(() => null)
        .finally(() => {
          getProjectDataPendingQueriesRef.current.delete(projectId)
        })
      getProjectDataPendingQueriesRef.current.set(projectId, promise)
      return promise
    },
    [baseUrl]
  )

  const deleteProject = useCallback<DesignHuddleProxyContextValue['deleteProject']>(
    (projectId: string) => {
      return AuthedFetch(`${baseUrl}/design/projects/${projectId}`, { method: 'DELETE' }).then(
        (response): Promise<DesignHuddleDeleteProjectResponse> => response.json()
      )
    },
    [baseUrl]
  )

  const cloneProject = useCallback<DesignHuddleProxyContextValue['cloneProject']>(
    (projectId: string) => {
      return AuthedFetch(`${baseUrl}/design/projects/${projectId}/clone`, {
        method: 'POST',
      }).then((response): Promise<DesignHuddleCloneProjectResponse> => response.json())
    },
    [baseUrl]
  )

  const PAGE_DEFAULT = 1
  const LIMIT_DEFAULT = 20

  const getProjects = useCallback(
    (page = PAGE_DEFAULT, limit = LIMIT_DEFAULT, assetType) => {
      let queryParams
      if (assetType) {
        queryParams = new URLSearchParams({
          page: validatePositiveNumberToString(page, 1),
          limit: validatePositiveNumberToString(limit, 20),
          assetType,
        })
      } else {
        queryParams = new URLSearchParams({
          page: validatePositiveNumberToString(page, 1),
          limit: validatePositiveNumberToString(limit, 20),
        })
      }
      return AuthedFetch(`${baseUrl}/design/projects`, { queryParams })
        .then((response): Promise<DesignHuddlePaginatedApiResponse<DesignHuddleProxyProject>> => response.json())
        .catch(() => null)
    },
    [baseUrl]
  )

  const createProject = useCallback(
    (templateId: number, categoryId: number, tco: DesignHuddleTemplateCustomizationsObject = {}) => {
      const headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      })
      const body = {
        templateId,
        tco,
        categoryId,
      }
      const url = `${baseUrl}/design/projects`
      return AuthedFetch(url, { method: 'POST', headers, body: JSON.stringify(body) })
        .then((response): Promise<DesignHuddleProxyProject> => response.json())
        .catch(() => null)
    },
    [baseUrl]
  )

  const createDownloadJob = (projectId: string, format: 'png' | 'pdf' | 'mp4', selectedPage?: string) => {
    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    })
    let body
    if (selectedPage) {
      body = {
        projectId,
        format,
        pageIds: [selectedPage],
      }
    } else {
      body = {
        projectId,
        format,
      }
    }
    const url = `${baseUrl}/downloads/projects`
    return AuthedFetch(url, { method: 'POST', headers, body: JSON.stringify(body) })
      .then((response): Promise<DesignHuddleProxyJob> => response.json())
      .catch(() => null)
  }

  const getDownloadJobStatus = (jobId: number) => {
    const url = `${baseUrl}/downloads/${jobId}`
    return AuthedFetch(url)
      .then((response): Promise<DesignHuddleProxyJobStatus> => response.json())
      .catch(() => null)
  }

  async function renderProject(
    projectId: string,
    format = 'png'
  ): Promise<{ pageId: string; pageNumber: number; url: string }[]> {
    const url = `${baseUrl}/downloads/projects`
    const projectResponse: CreateProjectRenderJobResponse | null = await AuthedFetch(url, {
      method: 'POST',
      headers: [['Content-Type', 'application/json']],
      body: JSON.stringify({
        projectId,
        format,
      }),
      hideAgentId: true,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to create export job')
        }
        return response.json()
      })
      .then((data: CreateProjectRenderJobResponse) => data)
      .catch(() => null)

    if (projectResponse?.jobId && projectResponse?.project?.pages?.length) {
      const {
        project: { pages },
      } = projectResponse
      const { jobId } = projectResponse

      let job: ProjectJobResponse
      do {
        job = await AuthedFetch(`${baseUrl}/downloads/${jobId}`)
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            return response.json()
          })
          .catch((error) => {
            datadogRum.addError(`Failed to fetch export job ${jobId} status: ${error.message}`)
            return null
          })
      } while (job && job?.status !== 'complete')

      if (job) {
        const renderedPages = pages
          .map(({ pageId, pageNumber }) => {
            try {
              const page = job.pages.find(({ page }) => page === pageNumber)
              if (!page)
                throw new Error(`Page #${pageNumber} (${pageId}) of ${projectId} in job ${jobId} was not rendered`)
              return {
                pageId,
                pageNumber,
                url: page.url,
              }
            } catch (error) {
              datadogRum.addError(error.message)
              return null
            }
          })
          .filter((page) => !!page)
        return renderedPages
      }

      return []
    }

    return []
  }

  async function createPresentationLinkJob(projectId: string): Promise<PresentationJobResponse> {
    const url = `${baseUrl}/design/presentation/${projectId}/share`
    return AuthedFetch(url, {
      method: 'POST',
      headers: [['Content-Type', 'application/json']],
      hideAgentId: true,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to create presentation link export job')
        }
        return response.json()
      })
      .then((result) => {
        return {
          success: result.success,
          data: {
            jobId: result.data.job_id,
          },
        }
      })
      .catch((error) => {
        datadogRum.addError(error.message)
        return null
      })
  }

  async function downloadPresentationLinkJob(projectId: string, jobId: string): Promise<PresentationJobStatus> {
    const url = `${baseUrl}/design/presentation/${projectId}/share/${jobId}`
    return AuthedFetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to create presentation link export job')
        }
        return response.json()
      })
      .then((result) => {
        return {
          success: result.success,
          data: {
            dateStarted: result.data.date_started,
            completed: result.data.completed,
            dateCompleted: result.data.date_completed,
            presentationUrl: result.data.presentation_url,
          },
        }
      })
      .catch((error) => {
        datadogRum.addError(error)
        return null
      })
  }

  async function downloadPublishJob(projectId: string, jobId: string): Promise<PublishJobStatus> {
    const url = `${baseUrl}/design/publish/${projectId}/status/${jobId}`
    return AuthedFetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch the publish job')
        }
        return response.json()
      })
      .then((result) => {
        return {
          success: result.success,
          data: {
            dateStarted: result.data.date_started,
            completed: result.data.completed,
            dateCompleted: result.data.date_completed,
          },
        }
      })
      .catch((error) => {
        datadogRum.addError(error)
        return null
      })
  }

  const publishTemplate = (projectId: string, templateTitle: string, categoryId: number) => {
    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    })
    const body = {
      projectId,
      templateCode: '',
      templateTitle,
      templateStatus: 'active',
      categoryItemId: categoryId,
    }
    const url = `${baseUrl}/design/templates`
    return AuthedFetch(url, { method: 'POST', headers, body: JSON.stringify(body) })
      .then((response): Promise<DesignHuddleProxyJob> => response.json())
      .catch((error) => {
        datadogRum.addError(error)
        return error
      })
  }

  const generateContent = async (params: GenerateContentParams) => {
    const {
      captionContext,
      address,
      contentContexts,
      maxNumberOfWords,
      propertyInfo,
      numberOfOutputs,
      tonality,
      contentType,
      targetAudience,
      targetAudienceTopic,
      hashtagsOption,
    } = params

    const body = {
      captionContext,
      address,
      contentContexts,
      maxNumberOfWords,
      propertyInfo,
      numberOfOutputs,
      tonality,
      contentType,
      targetAudience,
      targetAudienceTopic,
      hashtagsOption,
    }

    return AuthedFetch(`${baseUrl}/content-generation`, {
      headers: new Headers({ 'Content-Type': 'application/json' }),
      method: 'post',
      body: JSON.stringify(body),
    })
      .then((response): Promise<{ outputs: Array<string> }> => response.json())
      .catch(() => null)
  }

  const value: DesignHuddleProxyContextValue = {
    getTemplates,
    getProjects,
    getProjectData,
    createProject,
    createDownloadJob,
    getDownloadJobStatus,
    renderProject,
    deleteProject,
    cloneProject,
    createPresentationLinkJob,
    downloadPresentationLinkJob,
    publishTemplate,
    downloadPublishJob,
    generateContent,
  }

  return <DesignHuddleProxyContext.Provider value={value}>{children}</DesignHuddleProxyContext.Provider>
}
