import { EditorTitleCard } from './editor-title-card'

type DhEditorTitleCardProps = {
  projectPreview: string
  title: string
  lastSaved: Date
  handleEditTitleClick: () => void
}

export const DhEditorTitleCard = ({
  projectPreview,
  title,
  lastSaved,
  handleEditTitleClick,
}: DhEditorTitleCardProps): JSX.Element => {
  return (
    <EditorTitleCard
      src={projectPreview}
      alt={''}
      title={title}
      lastSaved={lastSaved}
      onEditClick={handleEditTitleClick}
    />
  )
}
