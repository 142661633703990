import { ListingDetails } from '@avenue-8/platform-shared-util-frontend'
import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import React, { useCallback } from 'react'
import { MLSDataPhotoType } from '../../../../shared/domain/agent-listing.dto'
import { SuccessIcon } from '../../../assets/icons/success.icon'
import { ImageUnavailable } from '../../image-unavailable'
import { ListingInfo } from '../../listing-info'
import { ListingPhotoSelectorPreview } from './listing-photo-selector-preview'

const Container = styled.div`
  background: white;
  border-radius: 5px;
  max-width: 100%;
  border: 1px solid #73738c;
  padding: 17px 20px;
`

const FoundText = styled(Typography)`
  && {
    color: #107f10;
    font-size: 11px;
    letter-spacing: 2px;
    font-weight: 400;
    text-transform: uppercase;
  }
  && svg {
    margin-right: 2px;
  }
`

const ListingHead = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(3, auto);
  column-gap: 12px;
  p {
    font-size: 13px;
    margin-bottom: 3px;
  }
  .last-line {
    display: flex;
  }
  .last-line p:last-child {
    color: #73738c;
    margin-left: 20px;
  }
`

const ListingPhoto = styled.img`
  width: 74px;
  height: 58px;
  object-fit: cover;
  border-radius: 4px;
  grid-column: 1 / 2;
  grid-row: 1 / 4;
`

const UnavailableListingPhoto = styled(ImageUnavailable)`
  width: 74px;
  height: 58px;
  object-fit: cover;
  border-radius: 4px;
  grid-column: 1 / 2;
  grid-row: 1 / 4;
`

const ApplyButton = styled(Button)`
  width: 100%;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  color: #131319;
  font-size: 12px;
  padding: 15px 18px;
  border: 2px solid #131319;
  background: #fafaff;
  border-radius: 4px;
  margin-top: 25px;
  cursor: pointer;
  &&.Mui-disabled {
    cursor: default;
    color: #73738c;
    border: 2px solid #73738c;
  }
`

const { format: numberFormatter } = new Intl.NumberFormat('en-us', { maximumFractionDigits: 0 })
const { format: moneyFormatter } = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
})

export type FoundListingBoxProps = {
  listing: ListingDetails
  onOpenImageSelector: () => void
  applyButtonText?: string
  applyDisable?: boolean
  maxSelectedPhotos?: number
  onApplyClick?: (listing: ListingDetails) => void
  selectedPhotos?: MLSDataPhotoType[]
}

export const FoundListingBox = (props: FoundListingBoxProps): JSX.Element => {
  const {
    applyButtonText,
    applyDisable,
    listing,
    maxSelectedPhotos,
    onOpenImageSelector,
    onApplyClick,
    selectedPhotos,
  } = props

  const handleOnClick = useCallback(() => onApplyClick(listing), [onApplyClick, listing])

  return (
    <Container>
      <FoundText>
        <SuccessIcon />
        <span>Found on MLS</span>
      </FoundText>
      <ListingHead>
        {listing.photos?.length > 0 ? (
          <ListingPhoto src={listing.photos[0].photoUrl} alt='Listing' />
        ) : (
          <UnavailableListingPhoto />
        )}
        <Typography variant='body1'>
          {listing.addressLine1}
          {listing.addressLine2 && <span>,&nbsp;{listing.addressLine2}</span>}
        </Typography>
        <Typography variant='body1'>
          <span>
            {listing.city}, {listing.state} {listing.zipCode}
          </span>
        </Typography>
        <div className='last-line'>
          <Typography variant='body1'>{moneyFormatter(listing.currentPrice)}</Typography>
          <Typography variant='body1'>
            {listing.numBedrooms || '-'}BD | {listing.numBathrooms || '-'}BA
          </Typography>
        </div>
      </ListingHead>
      <ListingInfo>
        <Typography variant='body1'>
          <strong>Property Type</strong>
          <span>{listing.propertyType?.propertyType || '-'}</span>
        </Typography>
        <Typography variant='body1'>
          <strong>sq. ft.</strong>
          <span>{listing.lotSize ? `${numberFormatter(listing.lotSize)} sq ft` : '-'}</span>
        </Typography>
        <Typography variant='body1'>
          <strong>Neighborhood</strong>
          <span>{listing.neighborhood || '-'}</span>
        </Typography>
        <Typography variant='body1'>
          <strong>year built</strong>
          <span>{listing.yearBuilt || '-'}</span>
        </Typography>
      </ListingInfo>
      {(listing?.photos ?? null) && (
        <ListingPhotoSelectorPreview
          photos={listing.photos}
          onSeeAllClick={onOpenImageSelector}
          selectedPhotos={selectedPhotos ?? []}
          maxSelectedPhotos={maxSelectedPhotos ?? 0}
          stackSx={{ marginTop: 2 }}
        />
      )}
      {onApplyClick && (
        <ApplyButton disabled={applyDisable} onClick={handleOnClick}>
          {applyButtonText ?? 'Apply Listing'}
        </ApplyButton>
      )}
    </Container>
  )
}
