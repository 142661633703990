import { useState, useEffect } from 'react'
import Close from '@mui/icons-material/Close'
import {
  Backdrop,
  Box,
  Button,
  Modal,
  ThemeProvider,
  styled,
  Grid,
  CircularProgress,
  Typography,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormHelperText,
  OutlinedInput,
  ClickAwayListener,
  Tooltip,
} from '@mui/material'
import { DownloadFormat, theme } from '@avenue-8/platform-style-util-frontend'

const ModalContent = styled(Box)`
  box-shadow: 1px 4px 40px rgba(0, 0, 0, 0.25);
  background: white;
  outline: none;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: min(16em, 100vh);
  min-width: 20em;
  border: none;

  @media (max-width: 641px) {
    border-radius: 14px 14px 0 0;
    width: 100%;
    height: calc(100vh - 24px);
    overflow-y: scroll;
  }

  @media (min-width: 641px) {
    border-radius: 7px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
`
const StyledWrapperBox = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
`

export type ExportFileType = 'pdf' | 'png'

export type DefaultFile = {
  label: string
  value: ExportFileType
  disabled?: boolean
}

export type Title = {
  title: string
  onClose: () => void
}

export type Handlers =
  | 'preview'
  | 'emailShare'
  | 'socialShare'
  | 'download'
  | 'caption'
  | 'customize'
  | 'generateCaptionWithAI'

export const ModalTitle = ({ title, onClose }: Title): JSX.Element => {
  return (
    <StyledWrapperBox paddingX={1} paddingY={2}>
      <div style={{ flexShrink: 1 }}>
        <Typography variant='h4' component='h1'>
          {title}
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'flex-end',
        }}
      >
        <Button variant='text' onClick={onClose} sx={{ marginRight: '-8px' }}>
          <Close />
        </Button>
      </div>
    </StyledWrapperBox>
  )
}

export type OneFieldHandlersFn = Partial<Record<'onChange' | 'onClick', (...args: any) => any>>

export type MultipleFieldsHandlersFn = Partial<Record<'fields', Record<string, OneFieldHandlersFn>>>

export type Triggers = Partial<Record<Handlers, boolean | OneFieldHandlersFn | MultipleFieldsHandlersFn>>

export type Page = {
  pageId: string
  pageNumber: number
}

export type ExportPresentationModalProps = {
  open: boolean
  onClose: () => void
  title: string
  status: 'IDLE' | 'LOADING' | 'ERROR'
  values?: {
    fileName?: string
    fileType?: DownloadFormat
    presentationUrl?: string
    targetAudience?: string
    targetAudienceTopic?: string
    captionContext?: string
    tonality?: string
  }
  exportFileTypes?: DefaultFile[]
  onDownloadClick?: (fileName: string, fileType: DownloadFormat, selectedPage?: string) => void
  handlers: Handlers[]
  pages: Page[]
}

export default function EditorExportPresentationModal({
  onClose,
  open,
  status,
  title,
  exportFileTypes = [
    { label: 'PDF', value: 'pdf', disabled: false },
    { label: 'PNG', value: 'png', disabled: false },
  ],
  values,
  onDownloadClick,
  handlers,
  pages,
}: ExportPresentationModalProps): JSX.Element {
  const [selectedPage, setSelectedPage] = useState<string>('')
  const [openTooltip, setOpenTooltip] = useState<boolean>(false)
  const [fileName, setFileName] = useState<string>(values?.fileName ?? null)
  const [fileType, setFileType] = useState<DownloadFormat | null>(values?.fileType ?? null)

  useEffect(() => {
    setFileType(fileType ?? exportFileTypes?.[0]?.value)
    setFileName(fileName ?? values?.fileName)
    setSelectedPage(selectedPage ?? '')
  }, [exportFileTypes, values, selectedPage])

  const handleFileNameChange = (event) => {
    const {
      target: { value },
    } = event
    setFileName(value)
  }

  const handleFileTypeChange = (event) => {
    const {
      target: { value },
    } = event
    setFileType(value)
  }

  const handlePageChange = (event) => {
    const {
      target: { value },
    } = event
    setSelectedPage(value)
  }

  const handleDownloadClick = () => {
    onDownloadClick?.(fileName, fileType, selectedPage)
    setFileType('pdf')
    setFileName(null)
    setSelectedPage(null)
  }

  const triggers: Triggers = {
    preview: handlers.includes('preview'),
    download: handlers.includes('download')
      ? {
          fields: {
            fileName: {
              onChange: handleFileNameChange,
            },
            fileType: {
              onChange: handleFileTypeChange,
            },
            selectedPage: {
              onChange: handlePageChange,
            },
          },
          onClick: handleDownloadClick,
        }
      : false,
  }

  const handleTooltipOpen = () => {
    navigator.clipboard.writeText(values.presentationUrl)
    setOpenTooltip(true)
    setTimeout(() => {
      setOpenTooltip(false)
    }, 5000)
  }

  const handleTooltipClose = () => {
    setOpenTooltip(false)
  }

  return (
    <div>
      <Modal open={open} BackdropComponent={StyledBackdrop}>
        <ModalContent
          sx={{
            flexDirection: 'column',
            p: 1,
            minWidth: '75vw',
            maxWidth: '100vw',
            minHeight: '60vh',
            maxHeight: '100vh',
          }}
        >
          <ThemeProvider theme={theme}>
            {status !== 'IDLE' ? (
              <>
                <ModalTitle title={''} onClose={onClose} />
                <Grid container justifyContent='center' alignItems='center'>
                  {status === 'LOADING' && <CircularProgress />}
                  {status === 'ERROR' && <Typography>Oops, something went wrong. Please try again later.</Typography>}
                </Grid>
              </>
            ) : (
              <>
                <ModalTitle title={''} onClose={onClose} />
                <Grid
                  container
                  flexDirection='row'
                  flexWrap='nowrap'
                  spacing={2}
                  paddingX={3}
                  paddingBottom={4}
                  marginTop={0}
                >
                  <Grid
                    item
                    container
                    flexDirection='column'
                    rowSpacing={3}
                    flex='1 1 0px'
                    style={{ paddingTop: '24px' }}
                  >
                    <>
                      <Grid item container flexDirection='row' spacing={2} alignItems='flex-end'>
                        {triggers.download && (
                          <>
                            <Grid item xs={3} sm={3}>
                              <TextField
                                label='File Name'
                                placeholder='File Name'
                                value={fileName}
                                onChange={(triggers.download as MultipleFieldsHandlersFn)?.fields?.fileName?.onChange}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                  style: { border: 'none' },
                                  notched: false,
                                }}
                                sx={{ paddingLeft: 0, marginLeft: 0 }}
                              />
                            </Grid>
                            <Grid item xs={3} sm={3}>
                              <FormHelperText>Select pages</FormHelperText>
                              <Select
                                label=''
                                variant='outlined'
                                input={<OutlinedInput />}
                                defaultValue={''}
                                displayEmpty
                                onChange={
                                  (triggers.download as MultipleFieldsHandlersFn)?.fields?.selectedPage?.onChange
                                }
                                MenuProps={{ hideBackdrop: true }}
                                sx={{ minWidth: 100, border: 0 }}
                              >
                                <MenuItem value=''>{`All Pages (${pages.length})`}</MenuItem>
                                {pages.map(({ pageId, pageNumber }, idx) => (
                                  <MenuItem key={`filetype-${idx}-${pageId}`} value={pageId}>
                                    {`Page ${pageNumber}`}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>
                            <Grid item xs={3} sm={3}>
                              <FormHelperText>Format</FormHelperText>
                              <Select
                                label=''
                                variant='outlined'
                                input={<OutlinedInput />}
                                defaultValue={'pdf'}
                                onChange={(triggers.download as MultipleFieldsHandlersFn)?.fields?.fileType?.onChange}
                                MenuProps={{ hideBackdrop: true }}
                                sx={{ minWidth: 100, border: 0 }}
                              >
                                {exportFileTypes.map(({ label, value }, idx) => (
                                  <MenuItem key={`filetype-${idx}-${value}`} value={value}>
                                    {label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>
                            <Grid item xs={3} sm={3}>
                              <Button
                                variant='contained'
                                size='large'
                                fullWidth
                                onClick={(triggers.download as OneFieldHandlersFn)?.onClick}
                                sx={{
                                  paddingX: 4,
                                  marginRight: 2,
                                  textTransform: 'uppercase',
                                  color: 'white',
                                  backgroundColor: '#2a29a6 !important',
                                  '&:hover': { backgroundColor: '#2a29a6 !important' },
                                }}
                              >
                                Download
                              </Button>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      {/* TODO: Uncomment these lines to allow the user to get the presentation url
                      <Grid item container flexDirection='row' spacing={2} alignItems='flex-end'>
                        <Grid item xs={9} sm={9}>
                          <TextField
                            label='Presentation URL'
                            placeholder='Presentation URL'
                            value={values.presentationUrl}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              style: { border: 'none' },
                              notched: false,
                            }}
                            sx={{ paddingLeft: 0, marginLeft: 0 }}
                          />
                        </Grid>
                        <Grid item xs={3} sm={3}>
                          <ClickAwayListener onClickAway={handleTooltipClose}>
                            <Tooltip
                              onClose={handleTooltipClose}
                              open={openTooltip}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title='Copied!'
                            >
                              <Button
                                onClick={handleTooltipOpen}
                                variant='contained'
                                size='large'
                                fullWidth
                                sx={{
                                  paddingX: 4,
                                  marginRight: 2,
                                  textTransform: 'uppercase',
                                  color: 'white',
                                  backgroundColor: '#2a29a6 !important',
                                  '&:hover': { backgroundColor: '#2a29a6 !important' },
                                }}
                              >
                                Copy Link
                              </Button>
                            </Tooltip>
                          </ClickAwayListener>
                        </Grid>
                      </Grid>
                      */}
                    </>
                  </Grid>
                </Grid>
              </>
            )}
          </ThemeProvider>
        </ModalContent>
      </Modal>
    </div>
  )
}
