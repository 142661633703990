import { styled } from '@mui/material/styles'
import { ListingInfo } from '../listing-info'

export const TitleWrapper = styled(ListingInfo)`
  margin-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1rem;
`
