import { useScreenSize } from '@avenue-8/platform-style-util-frontend'
import styled from '@emotion/styled'
import { ChevronLeft } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { ReactNode, useContext, useEffect } from 'react'
import { DhEditorTitleCard } from './dh-editor-title-card'
import { EditorTopBar } from './editor-topbar'
import { EditorContext } from '../contexts/editor.context'
import { MobileEditorBottomBarSection } from './sections/mobile/mobile-editor-bottom-bar'
import { MobileEditorTopBar } from './sections/mobile/mobile-editor-topbar'
import { generateDataAttrs } from '@avenue-8/platform-shared-util-frontend'
import kebabCase from 'lodash.kebabcase'

const ColumnsWrapper = styled.div`
  --top-bar-height: 104px;
  display: flex;
  width: 100%;
  // height -80px to take the top bar into account
  height: calc(100vh - var(--top-bar-height, 80px));
`

const EditorColumn = styled.div`
  flex-grow: 1;
  min-width: 75%; // this should be the starting size of the iframe (must change in mobile viewports to start smaller)
`

const MobileEditorWrapper = styled(ColumnsWrapper)`
  --bottom-nav-height: 62px;
  height: calc(100vh - calc(var(--top-bar-height, 80px) + var(--bottom-nav-height, 62px)));
`

const RightSideOptionsColumn = styled.div`
  --padding: 8px;
  --distance-from-the-top: var(--top-bar-height, 80px);
  flex-shrink: 1;
  min-width: 12em;
  max-width: 25%;
  height: calc(100vh - var(--distance-from-the-top));
  overflow-y: auto;
`

export type EditorLayoutProps = {
  children: ReactNode
  topBarSection?: JSX.Element
  bottomBarSections?: Record<string, MobileEditorBottomBarSection>
  rightColumn?: ReactNode
  backCtaLink?: string
  backCtaText?: ReactNode
  onExportClick: () => void
}

export const EditorLayout = ({
  children,
  topBarSection = <></>,
  rightColumn,
  backCtaLink,
  backCtaText,
  onExportClick,
}: EditorLayoutProps): JSX.Element => {
  const { isDesktop } = useScreenSize()

  const { title, onTitleChange, editorRef, lastSaved, projectThumbnail } = useContext(EditorContext)

  const handleEditTitleClick = () => {
    editorRef.showChangeTitle()
  }

  const handleTitleClick = () => {
    onTitleChange()
  }

  const titleComponent = (
    <Box display='flex' flexDirection='column' alignItems='flex-start'>
      <Typography variant='h3' component='h2' onClick={handleTitleClick}>
        {title ? (
          <DhEditorTitleCard
            projectPreview={projectThumbnail}
            title={title}
            lastSaved={lastSaved}
            handleEditTitleClick={handleEditTitleClick}
          />
        ) : (
          'Loading Project...'
        )}
      </Typography>
      <Button
        variant='text'
        component='a'
        href={backCtaLink}
        startIcon={<ChevronLeft />}
        sx={{ paddingY: 0, marginY: 0 }}
        {...generateDataAttrs({
          metaAction: `${kebabCase(backCtaText || 'Back to Templates')}`,
        })}
      >
        {backCtaText || 'Back to Templates'}
      </Button>
    </Box>
  )

  useEffect(() => {
    return () => {
      if (editorRef) {
        editorRef.remove()
      }
    }
  }, [editorRef, isDesktop])

  if (isDesktop) {
    return (
      <>
        <EditorTopBar
          alignItemsOnTop
          showExport={!!editorRef}
          title={titleComponent}
          topBarSection={topBarSection}
          onExportClick={onExportClick}
        />
        <ColumnsWrapper>
          <EditorColumn>{children}</EditorColumn>
          {rightColumn && <RightSideOptionsColumn>{rightColumn}</RightSideOptionsColumn>}
        </ColumnsWrapper>
      </>
    )
  }
  return (
    <>
      <MobileEditorTopBar backCtaLink={backCtaLink} onShareClick={onExportClick} />
      <MobileEditorWrapper>{children}</MobileEditorWrapper>
    </>
  )
}
