import { useScreenSize } from '@avenue-8/platform-style-util-frontend'
import { CircularProgress, Container, Grid, ImageList, ImageListItem, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { ProjectsContext } from '../../../projects/contexts/projects.context'
import { DesignHuddleProxyContext } from '../../../shared/contexts/design-huddle-proxy.context'
import { DesignHuddleContext } from '../../../shared/contexts/design-huddle-sdk.context'
import { CATEGORY_ID_URL_SEARCH_KEY, EditorContext } from '../../contexts/editor.context'
import { getProjectElements } from '../../utils/get-project-elements'
import { parseTcoFromTemplateElements } from '../../utils/parse-tco-from-template-elements'

type EditorPageParams = { projectId?: string }

export const EditorTemplatesSection = ({ onClose }: { onClose?: () => void }): JSX.Element => {
  const history = useHistory()
  const { isDesktop } = useScreenSize()
  const { dhSdk } = useContext(DesignHuddleContext)
  const { getTemplates, deleteProject } = useContext(DesignHuddleProxyContext)
  const { editorRef, replaceTemplate, projectCategoryId } = useContext(EditorContext)
  const [status, setStatus] = useState<'IDLE' | 'LOADING' | 'ERROR'>('LOADING')
  const [templatesIds, setTemplatesIds] = useState<number[]>([])
  const [previews, setPreviews] = useState<Record<string, string>>({})
  const { projectId } = useParams<EditorPageParams>()

  const redirectNewProject = (projectId: string) => {
    history.push(`./${projectId}?${CATEGORY_ID_URL_SEARCH_KEY}=${projectCategoryId}`)
    onClose?.()
  }

  const handleTemplateClick = (templateId: number) => {
    const confirmationMessage =
      'This will create a new project with the current data using this new template, do you want to continue?'
    if (confirm(confirmationMessage)) {
      replaceTemplate(templateId)
        .then((newProjectId: string) => {
          const deletePreviousProjectMessage = 'Do you want to delete the previous project?'
          if (confirm(deletePreviousProjectMessage)) {
            deleteProject(projectId)
              .then(() => {
                redirectNewProject(newProjectId)
              })
              .catch((error) => {
                console.error('Failed to delete project', projectId, error)
                window.alert(`Failed to delete the project: ${error?.message || error}`)
              })
          } else {
            redirectNewProject(newProjectId)
          }
        })
        .catch((error) => {
          console.error('Failed to create project with new template', error)
        })
    }
  }

  useEffect(() => {
    setStatus('LOADING')
    if (!projectCategoryId || !getTemplates) {
      setTemplatesIds([])
      setStatus('IDLE')
      return
    }
    getTemplates(projectCategoryId.toFixed(0), 20)
      .then((result) => {
        setTemplatesIds(result.data.map((template) => template.templateId))
        setStatus('IDLE')
      })
      .catch((error) => {
        console.error('Failed to fetch templates', error)
        setStatus('ERROR')
      })
  }, [getTemplates, projectCategoryId])

  useEffect(() => {
    if (dhSdk && editorRef && templatesIds.length) {
      getProjectElements(editorRef).then((pages) => {
        const tco = pages.reduce((obj, page) => {
          return { ...parseTcoFromTemplateElements(page), ...obj }
        }, {} as Record<string, any>)
        const promises = templatesIds.map((templateId): Promise<{ templateId: number; url: string }> => {
          const params = { template_id: templateId, customizations: tco }
          return new Promise((resolve, reject) => {
            dhSdk.getVariableTemplatePreviewURL(params, {}, (error, url: string) => {
              if (error) {
                console.error('Failed to get template preview URL', error)
                reject(error)
              } else {
                resolve({ templateId, url })
              }
            })
          })
        })
        Promise.all(promises).then((previewItems) => {
          setPreviews(Object.fromEntries(previewItems.map(({ templateId, url }) => [templateId, url])))
        })
      })
    }
  }, [editorRef, dhSdk, templatesIds])

  const images = Object.entries(previews).map(([templateId, url], idx) => {
    const handleClick = () => handleTemplateClick(+templateId)
    return (
      <ImageListItem key={idx} onClick={handleClick} sx={{ cursor: 'pointer' }}>
        <img
          src={url}
          alt={`preview thumbnail for template ${templateId}`}
          loading='lazy'
          style={{ maxWidth: '240px', cursor: 'pointer', borderRadius: '8px' }}
        />
      </ImageListItem>
    )
  })

  return (
    <Container sx={{ paddingTop: 1, height: '100%' }}>
      {!Object.keys(previews).length &&
        (status !== 'ERROR' ? (
          <Grid container justifyContent='center'>
            <CircularProgress />
          </Grid>
        ) : (
          <Typography variant='body2' component='p'>
            Sorry, we could not get templates for you, please try again later.
          </Typography>
        ))}
      <ImageList cols={isDesktop ? 1 : 3} gap={16}>
        {images}
      </ImageList>
    </Container>
  )
}
