import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import Stack from '@mui/material/Stack'
import { useCallback, useMemo } from 'react'
import { MLSDataPhotoType } from '../../../../shared/domain/agent-listing.dto'
import { TitleTypographyStyled } from '../title-typography.styled'
import { SelectableListingPhoto } from './selectable-listing-photo'

export type ListingPhotoSelectorProps = {
  photos: MLSDataPhotoType[]
  selectedImages: MLSDataPhotoType[]
  onGoBack: () => void
  onSelectedImagesChange:
    | ((handler: (images: MLSDataPhotoType[]) => void) => void) & ((images: MLSDataPhotoType[]) => void)
  maxSelectedPhotos?: number
}

export const ListingPhotoSelector = (props: ListingPhotoSelectorProps): JSX.Element => {
  const { maxSelectedPhotos = 1, onGoBack, onSelectedImagesChange, photos, selectedImages } = props

  const handleGoBack = () => {
    onGoBack()
  }

  const handleImageClick = useCallback(
    (url: string) => {
      onSelectedImagesChange((previouslySelected) => {
        // swaps image
        if (maxSelectedPhotos === 1) {
          const [firstSelected] = previouslySelected
          const updatedSelection = photos.find((photo) => photo.photoUrl === url)
          return updatedSelection && firstSelected !== updatedSelection ? [updatedSelection] : previouslySelected
        }
        // adds image to set
        const photo = photos.find((photo) => photo.photoUrl === url)
        if (previouslySelected.indexOf(photo) > -1) {
          return previouslySelected.filter((selectedPhoto) => selectedPhoto !== photo)
        } else if (previouslySelected.length + 1 <= maxSelectedPhotos) {
          return [...previouslySelected, photo]
        } else {
          return previouslySelected
        }
      })
    },
    [maxSelectedPhotos, photos]
  )

  const photoList = useMemo<Array<MLSDataPhotoType & { isSelected: boolean; selectedIdx: number }>>(() => {
    return photos.map((photo) => {
      const selectedIdx = selectedImages.findIndex(({ photoUrl }) => photoUrl === photo.photoUrl)
      return {
        ...photo,
        isSelected: selectedIdx > -1,
        selectedIdx,
      }
    })
  }, [selectedImages, photos])

  return (
    <Stack spacing={1} sx={{ padding: { md: '8px' } }}>
      <Grid container flexDirection='row'>
        <Grid item>
          <Button variant='text' onClick={handleGoBack} startIcon={<ChevronLeftIcon />} sx={{ padding: 0, margin: 0 }}>
            Back
          </Button>
        </Grid>
      </Grid>
      <Grid container flexDirection={'row'} justifyContent={'space-between'}>
        <Grid item>
          <TitleTypographyStyled variant={'button'}>Images from MLS ({photos.length})</TitleTypographyStyled>
        </Grid>
        <Grid item>
          {maxSelectedPhotos > 0 ? (
            <TitleTypographyStyled variant={'button'} sx={{ textAlign: 'right' }}>
              <small>{`${selectedImages.length} / ${maxSelectedPhotos}`}</small>
            </TitleTypographyStyled>
          ) : (
            <TitleTypographyStyled variant={'button'} color='red'>
              No image slots found
            </TitleTypographyStyled>
          )}
        </Grid>
      </Grid>
      <ImageList variant='quilted' cols={2} sx={{ overflowY: 'auto' }}>
        {photoList
          .sort(({ displayOrder: a }, { displayOrder: b }) => a - b)
          .map((image, idx) => {
            return (
              <ImageListItem
                key={`listing-mls-photo-${idx}`}
                onClick={() => handleImageClick(image.photoUrl)}
                sx={{ cursor: 'pointer' }}
              >
                <img
                  src={image.thumbnailUrl ?? image.photoUrl}
                  alt={`Listing image #${idx}`}
                  style={{ borderRadius: '8px' }}
                />
                <SelectableListingPhoto showCheckOnly={maxSelectedPhotos === 1} {...image} />
              </ImageListItem>
            )
          })}
      </ImageList>
    </Stack>
  )
}
