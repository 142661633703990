import Close from '@mui/icons-material/Close'
import { Box, BoxProps, Button, styled } from '@mui/material'
import { ReactNode } from 'react'

const StyledWrapperBox = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
`

export const ModalTitle = (props: BoxProps & { children: ReactNode; onClose: () => void }): JSX.Element => {
  const { children, onClose, ...boxProps } = props
  return (
    <StyledWrapperBox paddingX={1} paddingY={2} {...boxProps}>
      <div style={{ flexShrink: 1 }}>
        {typeof children === 'string' ? (
          <Button
            variant='text'
            sx={{
              textDecoration: 'none',
              letterSpacing: '2px',
              fontWeight: 500,
            }}
          >
            {children}
          </Button>
        ) : (
          children
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'flex-end' }}>
        <Button variant='text' onClick={onClose} sx={{ marginRight: '-8px' }}>
          <Close />
        </Button>
      </div>
    </StyledWrapperBox>
  )
}
