import { generateDataAttrs } from '@avenue-8/platform-shared-util-frontend'
import styled from '@emotion/styled'
import Edit from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import kebabCase from 'lodash.kebabcase'

const SquareRoundedImage = styled.img`
  width: 100%;
  border-radius: 8px;
  max-width: 56px;
`

export type EditorTitleCardProps = {
  src: string
  alt: string
  title: string
  lastSaved: Date
  onEditClick: () => void
}

const { format: dateFormatter } = new Intl.DateTimeFormat('en-us', { month: 'long', day: 'numeric' })
const { format: timeFormatter } = new Intl.DateTimeFormat('en-us', {
  hour: 'numeric',
  minute: '2-digit',
  hour12: true,
  hourCycle: 'h12',
})

export const EditorTitleCard = (props: EditorTitleCardProps): JSX.Element => {
  const lastSavedDate = dateFormatter(props.lastSaved)
  const lastSavedTime = timeFormatter(props.lastSaved)
  return (
    <Grid container flexDirection='row' flexWrap='nowrap'>
      <Grid item flexShrink={1} paddingRight={1}>
        <SquareRoundedImage src={props.src} alt={props.alt} />
      </Grid>
      <Grid item flexDirection='column'>
        <Typography variant='body2' color='secondary'>
          Last saved {lastSavedDate} at {lastSavedTime}
        </Typography>
        <Grid item display='flex' flexDirection='row' flexWrap='nowrap'>
          <Grid item flexShrink={1}>
            <Typography variant='h3' component='h1'>
              {props.title ?? ''}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant='text'
              color='secondary'
              startIcon={<Edit />}
              {...generateDataAttrs({
                metaAction: `edit-${kebabCase(props.title)}-project-title`,
              })}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
