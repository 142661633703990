import {
  useWebviewBridgeContext,
  WebviewBridgeHandlerName,
  UrlDownload,
  ZipDownload,
  ExportShareModal as ExportShareModalWrapper,
} from '@avenue-8/platform-shared-util-frontend'
import {
  useScreenSize,
  ExportShareModal,
  DownloadFormat,
  SocialShareOption,
  Handlers,
} from '@avenue-8/platform-style-util-frontend'
import kebabCase from 'lodash.kebabcase'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { DesignHuddleProxyContext } from '../../contexts/design-huddle-proxy.context'
import { DesignHuddleProxyProject } from '../../domain/design-huddle-proxy-project.type'

export type RtgExportModalProps = {
  open: boolean
  onClose: () => void
  project: DesignHuddleProxyProject | null
}

export const ProjectExportModal = (props: RtgExportModalProps): JSX.Element => {
  const { project } = props
  const DHProxy = useContext(DesignHuddleProxyContext)
  const { postMessage } = useWebviewBridgeContext()
  const { isDesktop } = useScreenSize()
  const [previews, setPreviews] = useState<{ url: string; id: string }[]>([])
  const [status, setStatus] = useState<'IDLE' | 'LOADING' | 'ERROR'>('LOADING')
  const handleClose = () => {
    props.onClose()
  }
  useEffect(() => {
    setPreviews(project?.thumbnailURL ? [{ url: project.thumbnailURL, id: project.thumbnailURL.toString() }] : [])
    setStatus('IDLE')
  }, [project])

  const suggestedFileName = useMemo(() => (project ? kebabCase('avenue 8 ' + project.title) : undefined), [project])

  const renderPages = useCallback(
    async (
      fileName: string,
      fileType: DownloadFormat,
      callback: (pages: { id: string; url: string }[], project?: DesignHuddleProxyProject) => Promise<void>
    ) => {
      if (project) {
        setStatus('LOADING')
        DHProxy.renderProject(project.projectId, fileType)
          .then((renderedPages) =>
            callback(
              renderedPages
                .sort(({ pageNumber: a }, { pageNumber: b }) => a - b)
                .map((page) => ({ id: page.pageId, url: page.url })),
              project
            )
          )
          .catch((error) => {
            alert('Failed to render project')
            console.error(`Failed to render project: ${error?.message || '[unknown reason]'}`, error)
          })
          .finally(() => {
            setStatus('IDLE')
          })
      }
    },
    [project, DHProxy]
  )

  const handleDownload = (fileName: string, fileType: DownloadFormat) => {
    renderPages(fileName, fileType, (pages, project) => {
      setPreviews(pages)
      const safeFileName = fileName.trim() || suggestedFileName
      if (postMessage) {
        postMessage(WebviewBridgeHandlerName.download, { url: pages.map((page) => page.url) })
      } else {
        if (pages.length > 1) {
          return ZipDownload(safeFileName, pages, fileType)
        } else {
          const [singlePage] = pages
          return UrlDownload(singlePage.url, `${safeFileName}.${fileType}`)
        }
      }
    })
  }

  const handleSocialShare = useCallback(
    (socialNetwork: SocialShareOption, _mediaArray: string[]) => {
      if (postMessage) {
        renderPages(suggestedFileName, 'png', async (pages) => {
          const urlList = pages.map((page) => page.url)
          const payload = { url: urlList }
          switch (socialNetwork) {
            case 'facebook':
              postMessage(WebviewBridgeHandlerName.facebook, payload)
              break
            case 'instagram':
              postMessage(WebviewBridgeHandlerName.instagram, payload)
              break
          }
        })
      } else {
        alert(`Can't share to social media through desktop, try using the Avenue 8 app.`)
      }
    },
    [postMessage, renderPages, suggestedFileName]
  )

  const handlers = useMemo<Handlers[]>(() => {
    return Object.entries({
      preview: isDesktop,
      download: true,
      socialShare: postMessage && handleSocialShare,
    })
      .filter(([_, handlerValue]) => !!handlerValue)
      .map(([handlerName]) => handlerName as Handlers)
  }, [isDesktop, postMessage, handleSocialShare])

  return (
    <ExportShareModalWrapper
      PresentionalComponent={ExportShareModal}
      open={props.open}
      onClose={handleClose}
      status={status}
      title={'You‘re almost done! Please review your asset.'}
      subtitle='Please make sure everything looks right before downloading.'
      pagePreviews={previews}
      mediaType={project?.mediaType}
      handlers={handlers}
      onDownloadClick={handleDownload}
      onSocialShareClick={postMessage && handleSocialShare}
      socialMediaShareMessage='Use in the Avenue 8 App'
      defaultValues={{
        fileName: suggestedFileName,
      }}
    />
  )
}
