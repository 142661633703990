import { CloseIcon, Button } from '@avenue-8/platform-style-util-frontend'
import styled from '@emotion/styled'
import { FileCopy } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import { generateDataAttrs, useUserDataContext } from '@avenue-8/platform-shared-util-frontend'

const InformationHeading = styled(Typography)`
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400;
`

const Information = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
`

const CustomDialogContent = styled(DialogContent)`
  overflow: hidden;
  padding: 24px 32px;
`

const CustomDialogActions = styled(DialogActions)`
  padding: 0 32px 32px;
`

export type RequestReceivedModalProps = {
  assetType: string
  order?: { id: string; cost: string }
  onClose: () => void
  open: boolean
}

export function RequestReceivedModal(props: RequestReceivedModalProps): JSX.Element {
  const { order, onClose, open } = props
  const agent = useUserDataContext()

  return (
    <Dialog maxWidth='md' disableEscapeKeyDown disableAutoFocus onClose={onClose} open={open}>
      <DialogTitle id='alert-dialog-title' sx={{ padding: 4, paddingBottom: 3 }}>
        <Grid container justifyContent='space-between' position='relative'>
          <Grid item xs={8} container direction='column'>
            <Typography fontSize={24} fontFamily='Canela' component='h2' fontWeight={300}>
              We’ve received your request!
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            onClick={onClose}
            position='absolute'
            right='0'
            top='5px'
            style={{ cursor: 'pointer' }}
            {...generateDataAttrs({
              metaAction: 'close-request-received-modal',
            })}
          >
            <CloseIcon size={24} />
          </Grid>
        </Grid>
      </DialogTitle>
      <CustomDialogContent>
        <Typography>Order confirmation ID</Typography>

        <Typography variant='body2' color='#8C8EA1' fontWeight={400} marginTop='8px'>
          Order #{' '}
          <Button
            size='small'
            variant='text'
            color='secondary'
            onClick={() => {
              if (order?.id) navigator.clipboard.writeText(order?.id)
            }}
            endIcon={<FileCopy />}
            {...generateDataAttrs({
              metaAction: 'go-to-orders-page',
            })}
          >
            {order?.id}
          </Button>
        </Typography>

        <Typography fontWeight={400}>
          We’ve received your request and are processing it now. You’ll receive an email confirming your request within
          24 hours.
        </Typography>

        <Grid container mt={3}>
          <Grid item xs={12} md={6}>
            <InformationHeading>Confirmation Email</InformationHeading>
          </Grid>
          <Grid item xs={12} md={6} mb={3}>
            <Information>{agent?.email}</Information>
          </Grid>
          <Grid item xs={12} md={6}>
            <InformationHeading>Asset</InformationHeading>
          </Grid>
          <Grid item xs={12} md={6} mb={3}>
            <Information>
              <span style={{ display: 'block' }}>{props.assetType}</span>
            </Information>
          </Grid>
          <Grid item xs={12} md={6}>
            <InformationHeading>Total</InformationHeading>
          </Grid>
          <Grid item xs={12} md={6} mb={3}>
            <Information>{order?.cost}</Information>
          </Grid>
        </Grid>
      </CustomDialogContent>
      <CustomDialogActions>
        <Button
          onClick={onClose}
          {...generateDataAttrs({
            metaAction: 'done-project',
          })}
        >
          Done
        </Button>
      </CustomDialogActions>
    </Dialog>
  )
}
