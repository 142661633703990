import { Tooltip } from '@avenue-8/platform-style-util-frontend'
import styled from '@emotion/styled'
import { Select, MenuItem, TextField } from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { InputFieldContainer, InputLabel } from './common'

const PrintDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 570px;
  padding: 16px;
  margin: auto;
`

const LabelTooltipContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export type PaperQuality = 'standard'

export type PostcardPrintDetailsFormData = {
  postcardQuantity: number
  paperWeight: PaperQuality
}

export type PostcardPrintDetailsFormProps = {
  quantity?: number
  tooltipDescription?: string
  onFormChange: ({ isValid, payload }: { isValid: boolean; payload: PostcardPrintDetailsFormData }) => void
}

export const PostcardPrintDetailsForm = ({
  quantity,
  tooltipDescription,
  onFormChange,
}: PostcardPrintDetailsFormProps): JSX.Element => {
  const [postcardQuantity, setPostcardQuantity] = useState(0)
  const [formPayload, setFormPayload] = useState<PostcardPrintDetailsFormData>({
    postcardQuantity: 0,
    paperWeight: 'standard',
  })

  const validatePayload = useCallback(
    (payload: PostcardPrintDetailsFormData) => {
      let isValid = true
      const errors = []

      if (payload?.postcardQuantity < 1 || payload?.postcardQuantity > quantity) {
        isValid = false
        errors.push('Postcard quantity should be greater than 0 and lower than estimates')
      }

      return isValid
    },
    [quantity]
  )

  const updateValue = useCallback(
    (partial: Partial<PostcardPrintDetailsFormData>) => {
      const { postcardQuantity, paperWeight } = formPayload as Partial<PostcardPrintDetailsFormData>

      setFormPayload({
        postcardQuantity,
        paperWeight,
        ...partial,
      })
    },
    [formPayload]
  )

  useEffect(() => {
    setPostcardQuantity(quantity)
    updateValue({ postcardQuantity: quantity })
  }, [quantity])

  useEffect(() => {
    const isValid = validatePayload(formPayload)
    onFormChange({ isValid, payload: formPayload })
  }, [formPayload])

  const handleQuantityChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const isNumber = /^[0-9]+$/g.test(e.target.value ?? '')
    const value = isNumber ? parseInt(e.target.value, 10) : 0
    // block quantity to avoid to be greater than estimates
    if (value > quantity) {
      return
    }
    setPostcardQuantity(value)
    updateValue({ postcardQuantity: value })
  }

  return (
    <PrintDetailsContainer>
      <InputFieldContainer>
        <InputLabel>PAPER WEIGHT</InputLabel>
        <Select sx={{ width: '250px', marginTop: '10px', height: '48px' }} value={'standard'}>
          <MenuItem value={'standard'}>Standard (100lb)</MenuItem>
        </Select>
      </InputFieldContainer>
      <InputFieldContainer>
        <LabelTooltipContainer>
          <InputLabel>QUANTITY</InputLabel>
          {tooltipDescription?.length ? (
            <Tooltip style={{ marginBottom: '8px' }} title='' description={tooltipDescription} />
          ) : null}
        </LabelTooltipContainer>
        <TextField
          sx={{ width: '250px', marginTop: '10px', height: '48px' }}
          label=''
          type='number'
          value={postcardQuantity}
          onChange={handleQuantityChange}
        />
      </InputFieldContainer>
    </PrintDetailsContainer>
  )
}
