import { PageElement } from '../domain/project-data.type'

const filterOnlyAllowedFields = (list: Record<string, PageElement>) =>
  Object.fromEntries(Object.entries(list).map(([key, { text, url }]) => [key, { text, url }]))

export const parseTcoFromTemplateElements = (
  page: Record<'elements' | 'classes', Record<string, PageElement & { elementId: string }>>,
  withClasses = false
):
  | Record<'elements', Record<string, { text?: string; url?: string }>>
  | {
      elements: Record<string, { text?: string; url?: string }>
      classes: Record<string, { text?: string; url?: string }>
    } => {
  const classesElements = Object.fromEntries(
    Object.values(page.classes).map((element) => {
      return [element.elementId, element]
    })
  )

  if (withClasses) {
    const classes = filterOnlyAllowedFields(page.classes)
    const elements = filterOnlyAllowedFields(page.elements)
    return {
      classes,
      elements,
    }
  }

  const elements = filterOnlyAllowedFields({ ...page.elements, ...classesElements })

  return { elements }
}
