import { styled } from '@mui/material'
import Box from '@mui/material/Box'

export const ImageOverlay = styled(Box)`
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`
