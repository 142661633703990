import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  ErrorBoundary,
  PaymentMethod,
  ResponseType,
  useAuthContext,
  useBilling,
} from '@avenue-8/platform-shared-util-frontend'
import { Typography } from '@mui/material'
import { useParams } from 'react-router'
import { PostcardMailingDetailsFormData, PostcardMailingListForm } from '../../forms/postcard/mailing-list.form'
import { PostcardPrintDetailsForm, PostcardPrintDetailsFormData } from '../../forms/postcard/print-details.form'
import FinalizeWrapper, { PriceData } from '../finalize-wrapper'
import { UseType } from '../../forms/postcard/common'
import { AddressDetails } from '../../../../shared/services/backend-app/backend-app.service.type'
import { DesignHuddleProxyContext } from '../../../../shared/contexts/design-huddle-proxy.context'
import { VisualMarketingServiceContext } from '../../../../shared/services/visual-marketing/visual-marketing.service.context'
import {
  PostcardRequest,
  AssetTypePriceRequest,
} from '../../../../shared/services/visual-marketing/visual-marketing.service.type'
import { addressDetailsToOneLine } from '../../../utils/addressDetailsToOneLine'
import OrderConfirmation from './order-confirmation'

// prettier-ignore
const BUILD_LIST_TOOLTIP_DESCRIPTION = 'If the quantity entered is less than the reach displayed above, postcards will only get sent to the quantity entered. Addresses will be randomly selected.'
// prettier-ignore
const UPLOAD_LIST_TOOLTIP_DESCRIPTION = 'If the quantity entered is less than the reach displayed above, postcards will only get sent to the quantity entered, starting with those at the top of your CSV.'

export type PostcardFinalizePageProps = {
  handleClose: () => void
  handleSubmit: () => void
  onPriceChange: (priceChanged: { [k: string]: PriceData }) => void
  setIsFormValid: (isValid: boolean) => void
  setSubmitFn: any
  isLoading: boolean
  price: PriceData
  priceList: PriceData[]
}

export type FormPayload = {
  agentId: string
  projectId: string
  listing?: any
  paymentMethodId: string
  addresses: {
    csvFileUrl: string
    type: UseType
    radius?: string
    startingPoint?: string
  }
  size: string
  paperWeight: string
  metadata: {
    addressDetails: AddressDetails
    radius: number
    estimatedQuantity: number
    finalQuantity: number
    entireBuilding?: boolean
  }
}

export const PostcardFinalizePage = ({
  handleSubmit,
  onPriceChange,
  handleClose,
  setIsFormValid,
  setSubmitFn,
  isLoading,
  priceList,
}: PostcardFinalizePageProps): JSX.Element => {
  const { agentId } = useAuthContext()
  const { projectId } = useParams<{ projectId?: string }>()
  const { getProjectData } = useContext(DesignHuddleProxyContext)
  const { getDefaultPaymentMethod } = useBilling()
  // prettier-ignore
  const {
    postPostcardRequest,
    getAssetTypePrice,
    uploadFile,
    generatePeopleFileByGeoLocation,
    generatePeopleFileByBuildingAddress,
  } = useContext(VisualMarketingServiceContext)

  const [enableSubmit, setEnableSubmit] = useState(false)
  const [isMailingListFormValid, setIsMailingListFormValid] = useState(false)
  const [isPrintDetailsFormValid, setIsPrintDetailsFormValid] = useState(false)
  const [postcardQuantity, setPostcardQuantity] = useState<number>(0)
  const [projectThumbnail, setProjectThumbnail] = useState<string>()
  const [uploadedData, setUploadedData] = useState<{ parsed: Record<string, string>[]; raw: string[] }>()
  const [tooltipDescription, setTooltipDescription] = useState<string>(BUILD_LIST_TOOLTIP_DESCRIPTION)
  const [postcardUnitPrice, setPostcardUnitPrice] = useState<number>(0)
  const [formPayload, setFormPayload] = useState<FormPayload>({
    agentId: '',
    projectId: projectId,
    listing: null,
    paymentMethodId: '',
    addresses: {
      csvFileUrl: '',
      type: 'data-built-list',
      radius: '1mi',
      startingPoint: '',
    },
    size: '',
    paperWeight: 'standard',
    metadata: {
      addressDetails: null,
      estimatedQuantity: 0,
      finalQuantity: 0,
      radius: 1,
      entireBuilding: false,
    },
  })
  const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState(false)

  const updateValue = useCallback(
    (partial: Partial<FormPayload>) => {
      // prettier-ignore
      const {
        agentId,
        projectId,
        listing,
        paymentMethodId,
        addresses,
        size,
        paperWeight,
        metadata,
    } = formPayload as Partial<FormPayload>

      setFormPayload({
        agentId,
        projectId,
        listing,
        paymentMethodId,
        addresses,
        size,
        paperWeight,
        metadata,
        ...partial,
      })
    },
    [formPayload]
  )

  const handleQuantityChange = (quantity: number) => {
    setPostcardQuantity(quantity)
  }

  const handleListTypeChange = (listType: UseType) => {
    let description = BUILD_LIST_TOOLTIP_DESCRIPTION
    if (listType === 'uploaded-list') {
      description = UPLOAD_LIST_TOOLTIP_DESCRIPTION
    }
    setTooltipDescription(description)
  }

  const handleMailingListPayloadChange = (mailingListData: {
    isValid: boolean
    payload: PostcardMailingDetailsFormData
  }) => {
    const { payload } = mailingListData
    setIsMailingListFormValid(mailingListData.isValid)
    updateValue({
      addresses: {
        csvFileUrl: null,
        type: payload.type,
        radius: `${payload.radius}mi`,
        startingPoint: payload.addressDetails && addressDetailsToOneLine(payload.addressDetails),
      },
      metadata: {
        ...formPayload?.metadata,
        entireBuilding: payload?.radius && payload.radius < 0,
        estimatedQuantity: payload.estimatesCount,
        addressDetails: payload.addressDetails,
        radius: payload.radius,
      },
    })
  }

  const handlePrintDetailsPayloadChange = (printDetailsData: {
    isValid: boolean
    payload: PostcardPrintDetailsFormData
  }) => {
    setIsPrintDetailsFormValid(printDetailsData.isValid)
    updateValue({
      metadata: {
        ...formPayload?.metadata,
        finalQuantity: printDetailsData.payload.postcardQuantity,
      },
      paperWeight: printDetailsData.payload.paperWeight,
    })
  }

  const handlePriceChange = useCallback(
    async (postcardQuantity: number) => {
      const value = (postcardUnitPrice * postcardQuantity) / 100
      onPriceChange({
        postcard: {
          order: 0,
          label: 'Postcards',
          value: value > 0 ? value : 0,
        },
      })
    },
    [agentId, postcardUnitPrice]
  )

  const generateFinalDataFile = async (): Promise<string> => {
    let fileURL = ''
    const { metadata } = formPayload
    const { addressDetails, entireBuilding, radius, finalQuantity } = metadata
    if (formPayload.addresses.type === 'uploaded-list') {
      // limit csv data based on quantity field (+1 to include the csv file header)
      const finalData = uploadedData.raw.slice(0, finalQuantity + 1).join('\n')
      const fileBlob = new Blob([finalData as any], { type: 'text/csv' })
      const file = new File([fileBlob], `${projectId}_${new Date().getTime()}.csv`, {
        type: 'text/csv',
        lastModified: new Date().getTime(),
      })

      // upload file to S3
      const { data } = await uploadFile(file, 'authenticated-read')
      fileURL = data.Location
    } else {
      if (entireBuilding) {
        const addressOptions = {
          address: `${addressDetails.firstAddressLine} ${addressDetails.secondAddressLine}`.trim(),
          city: addressDetails.city,
          postalCode: addressDetails.zip,
        }
        const fileInfo = await generatePeopleFileByBuildingAddress(addressOptions, finalQuantity)
        fileURL = fileInfo.Location
      } else {
        const locationOptions = {
          lat: addressDetails.lat,
          lon: addressDetails.lng,
        }
        const fileInfo = await generatePeopleFileByGeoLocation(locationOptions, radius, 'mi', finalQuantity)
        fileURL = fileInfo.Location
      }
    }
    return fileURL
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmitRequest = async () => {
    const billingResponse: ResponseType<{ defaultPaymentMethod: PaymentMethod }> = await getDefaultPaymentMethod()

    if (billingResponse.success === false) {
      throw new Error('Error getting payment method')
    }

    const { metadata, projectId, size, paperWeight, addresses } = formPayload ?? {}

    const body: PostcardRequest = {
      agentId,
      metadata,
      projectId,
      size,
      paperWeight,
      paymentMethodId: billingResponse.data.defaultPaymentMethod.id,
      addresses,
    }

    // upload CSV file to bucket
    const fileResponse = await generateFinalDataFile()
    body.addresses.csvFileUrl = fileResponse
    const response = await postPostcardRequest(body)
    return response
  }

  useEffect(() => {
    handlePriceChange(formPayload?.metadata?.finalQuantity ?? 0)
    setSubmitFn(() => handleSubmitRequest)
  }, [formPayload])

  useEffect(() => {
    if (projectId) {
      getProjectData(projectId)
        .then((data) => {
          setProjectThumbnail(data.thumbnailURL)
          const projectSize = `${data.size.height}x${data.size.width}`
          updateValue({ size: projectSize })
          return projectSize
        })
        .then((size) => {
          const body: AssetTypePriceRequest = {
            agentId,
            assetType: 'print',
            categoryType: 'postcard',
          }
          getAssetTypePrice(body)
            .then(({ data: postcardPrices }) => {
              const price = postcardPrices.find((price) => size === price.variant)
              if (!price) {
                throw new Error('Error finding postcard price')
              }
              setPostcardUnitPrice(price.unitPrice)
            })
            .catch((error) => console.error(error))
        })
        .catch((error) => console.error(error))
    }
  }, [projectId])

  useEffect(() => {
    setEnableSubmit(isMailingListFormValid && isPrintDetailsFormValid)
    setIsFormValid(isMailingListFormValid && isPrintDetailsFormValid)
  }, [isMailingListFormValid, isPrintDetailsFormValid])

  const steps = [
    {
      title: 'Mailing details'.toUpperCase(),
      subtitle: 'Set your mailing details.',
      component: (
        <ErrorBoundary fallback={<Typography>Looks like some went wrong here, please try again</Typography>}>
          <PostcardMailingListForm
            handlePostcardQuantityChange={handleQuantityChange}
            handleListTypeChange={handleListTypeChange}
            onFormChange={handleMailingListPayloadChange}
            handleFileUpload={(data) => setUploadedData(data)}
          />
        </ErrorBoundary>
      ),
    },
    {
      title: 'Print details'.toUpperCase(),
      subtitle: 'Set your printing details.',
      component: (
        <ErrorBoundary fallback={<Typography>Looks like some went wrong here, please try again</Typography>}>
          <PostcardPrintDetailsForm
            quantity={postcardQuantity}
            tooltipDescription={tooltipDescription}
            onFormChange={handlePrintDetailsPayloadChange}
          />
        </ErrorBoundary>
      ),
    },
  ]
  return (
    <>
      <FinalizeWrapper
        assetType={'postcard'}
        formValid={enableSubmit}
        formSteps={steps}
        isFinalizeDialogOpen
        priceList={priceList}
        handleClose={handleClose}
        submitting={isLoading}
        onSubmit={() => setIsConfirmationModalOpened(true)}
        projectThumbnail={projectThumbnail}
      />
      <OrderConfirmation
        open={isConfirmationModalOpened}
        sendOrderFn={() => {
          setIsConfirmationModalOpened(false)
          handleSubmit()
        }}
        backToReviewFn={() => setIsConfirmationModalOpened(false)}
      />
    </>
  )
}
