import { ConfirmDialog, useScreenSize } from '@avenue-8/platform-style-util-frontend'
import { Box, CircularProgress, Modal, Typography } from '@mui/material'
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { PriceData } from './finalize-wrapper'
import {
  AssetType,
  CategoryDetails,
  DesignHuddleCategoriesContext,
} from '../../../shared/contexts/design-huddle-categories.context'
import { useHistory, useParams } from 'react-router'
import { toMoney } from '../../../shared/utils/formatters'
import { RequestReceivedModal } from '../modal/request-received-modal'
import { SocialMediaFinalizePage } from './social-media/social-media.finalize'
import { PostcardFinalizePage } from './postcard/postcard.finalize'
import { DesignHuddleProxyContext } from '../../../shared/contexts/design-huddle-proxy.context'

type SubmitFnType =
  | null
  | (() => Promise<
      | { success: true; data: { orderConfirmationId: string; totalCharged: number } }
      | (Error & { success?: false; title?: string; body?: string })
    >)

export const FinalizePage = (): JSX.Element => {
  const history = useHistory<{ projectCategory?: CategoryDetails }>()
  const { getProjectData } = useContext(DesignHuddleProxyContext)

  const [projectCategory, setProjectCategory] = useState<CategoryDetails | null>(null)

  const { projectId } = useParams<{ projectId?: string }>()
  const { assetTypesDict, categoriesDict } = useContext(DesignHuddleCategoriesContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [messageDialog, showMessageDialog] = useState<{ title: string; body: ReactNode } | null>(null)
  const [messageDialogOnClose, setMessageDialogOnClose] = useState<(() => void) | null>(null)

  const [orderData, setOrderData] = useState<{ id: string; cost: string } | undefined>()
  const [requestReceivedOpen, setRequestReceivedOpen] = useState<boolean>(false)
  const [prices, setPrices] = useState<{ [k: string]: PriceData }>({})
  const priceList = useMemo(() => Object.values(prices), [prices])
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [submitFn, setSubmitFn] = useState<SubmitFnType>(null)

  useEffect(() => {
    const params = new URLSearchParams(history.location.search)
    if (history.location.state?.projectCategory) {
      setProjectCategory(history.location.state.projectCategory)
    } else if (params.has('categoryId')) {
      const projectCategory = categoriesDict.get(+params.get('categoryId'))
      if (projectCategory) {
        setProjectCategory(projectCategory)
      }
    } else {
      getProjectData?.(projectId)
        .then(({ category }) => {
          const projectCategory = categoriesDict.get(category.id)
          if (projectCategory) {
            setProjectCategory(projectCategory)
          }
        })
        .catch((error) => {
          console.error('Failed to get project Data', error)
        })
    }
  }, [history.location.search, history.location.state, getProjectData, projectId, categoriesDict])

  const handlePriceChange = (priceChanged: { [k: string]: PriceData }) => {
    setPrices({ ...prices, ...priceChanged })
  }

  const handleCloseFinalize = () => {
    history.push(`/${projectId}`)
    window.location.reload()
  }

  const handleSubmit = () => {
    if (!isFormValid) {
      return
    }
    setLoading(true)

    submitFn?.()
      .then((response) => {
        if (response.success === true) {
          const {
            data: { orderConfirmationId, totalCharged },
          } = response
          const formattedChargeValue = toMoney(totalCharged / 100, 2)
          setLoading(false)
          setOrderData({ id: orderConfirmationId, cost: formattedChargeValue })
          setRequestReceivedOpen(true)
        } else {
          const error: Error & { title?: string; body?: string } = Error(response.message)
          error.title = response.title
          error.body = response.body
          throw error
        }
      })
      .catch((error: Error & { title?: string; body?: string }) => {
        console.error(error)
        setMessageDialogOnClose(null)
        setLoading(false)
        showMessageDialog({
          title: error.title || 'Request failed',
          body: error.body || error?.message || 'Unknown error, please try again later.',
        })
      })
  }

  const handleCloseRequestReceived = () => {
    if (messageDialogOnClose) {
      messageDialogOnClose()
    }
    setRequestReceivedOpen(false)
    handleCloseFinalize()
  }

  const closeMessageDialog = () => {
    if (messageDialogOnClose) {
      messageDialogOnClose()
    }
    showMessageDialog(null)
  }

  const renderFinalizePage = (assetType: AssetType) => {
    const dict: { [key in AssetType]: JSX.Element } = {
      'social-media': (
        <SocialMediaFinalizePage
          handleSubmit={handleSubmit}
          handlePriceChange={handlePriceChange}
          handleClose={handleCloseFinalize}
          setSubmitFn={setSubmitFn}
          setIsFormValid={(isValid) => setIsFormValid(isValid)}
          isLoading={loading}
          price={prices['social-media']}
          priceList={priceList}
        />
      ),
      postcard: (
        <PostcardFinalizePage
          handleSubmit={handleSubmit}
          onPriceChange={handlePriceChange}
          handleClose={handleCloseFinalize}
          setSubmitFn={setSubmitFn}
          setIsFormValid={(isValid) => setIsFormValid(isValid)}
          isLoading={loading}
          priceList={priceList}
          price={prices['postcard']} // eslint-disable-line dot-notation
        />
      ),
      brochure: <></>,
      flyer: <></>,
      'display-ads': <></>,
    }

    return dict[assetType]
  }

  return (
    <>
      <Modal open={loading}>
        <Box
          sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      </Modal>

      {projectCategory?.assetType ? renderFinalizePage(projectCategory.assetType) : null}

      <RequestReceivedModal
        assetType={assetTypesDict[projectCategory?.assetType]?.label ?? 'Marketing'}
        order={orderData}
        onClose={handleCloseRequestReceived}
        open={requestReceivedOpen}
      />

      <ConfirmDialog
        onConfirm={closeMessageDialog}
        onCancel={closeMessageDialog}
        dialogTitle={messageDialog?.title}
        open={!!messageDialog}
        confirmLabel='Close'
      >
        {typeof messageDialog?.body === 'string' ? (
          <Typography variant='body2'>{messageDialog?.body}</Typography>
        ) : (
          messageDialog?.body
        )}
      </ConfirmDialog>
    </>
  )
}
