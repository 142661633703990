import { DesignHuddleCategoriesProvider } from '../shared/contexts/design-huddle-categories.context'
import { GalleryPage } from './components/gallery.page'

export const GalleryModule = (): JSX.Element => {
  return (
    <DesignHuddleCategoriesProvider>
      <GalleryPage />
    </DesignHuddleCategoriesProvider>
  )
}
