import { useScreenSize } from '@avenue-8/platform-style-util-frontend'
import { ReactNode } from 'react'

export const ViewportReloader = ({ children }: { children: ReactNode }): JSX.Element => {
  const { isDesktop } = useScreenSize()
  if (isDesktop) {
    return <div key='desktop-editor'>{children}</div>
  }
  return <div key='mobile-editor'>{children}</div>
}
