import { CloseIcon, CheckboxIcon, theme } from '@avenue-8/platform-style-util-frontend'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import { generateDataAttrs } from '@avenue-8/platform-shared-util-frontend'
import { ComputerIcon } from '../../../assets/icons/computer-icon'
import { useEffect, useState } from 'react'

type OrderConfirmationProps = {
  open: boolean
  sendOrderFn: () => void
  backToReviewFn: () => void
}

export default function OrderConfirmation({ open, sendOrderFn, backToReviewFn }: OrderConfirmationProps): JSX.Element {
  const [isItReviewed, setIsItReviewed] = useState<boolean>(false)

  useEffect(() => {
    if (!open) {
      setIsItReviewed(false)
    }
  }, [open])

  return (
    <Dialog open={open} maxWidth='md' disableEnforceFocus>
      <Box display='flex' justifyContent='center' alignItems='center' sx={{ p: 3, pb: 0 }} position='relative'>
        <Box sx={{ pt: 3 }}>
          <ComputerIcon />
        </Box>
        <Box
          onClick={backToReviewFn}
          position='absolute'
          right='32px'
          top='32px'
          style={{ cursor: 'pointer' }}
          {...generateDataAttrs({
            metaAction: 'postcard-order-confirmation-modal-close',
          })}
        >
          <CloseIcon size={24} />
        </Box>
      </Box>
      <Typography variant='h5' sx={{ p: 3, pb: 0 }}>
        You are about to submit the order!
      </Typography>

      <DialogContent sx={{ mt: 1 }}>
        <Typography color='#73738c' sx={{ lineHeight: '26px' }}>
          Once a request is submitted, it cannot be cancelled. Have you reviewed your postcard design and the details on
          the finalize page?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isItReviewed}
              onChange={() => setIsItReviewed(!isItReviewed)}
              checkedIcon={<CheckboxIcon color={theme.palette.primary.main} backgroundColor='transparent' />}
              icon={<CheckboxIcon color='transparent' backgroundColor='transparent' />}
            />
          }
          label={<Typography color='#73738c'>Yes, I&apos;ve reviewed.</Typography>}
        />
        <div>
          <Button variant='text' onClick={backToReviewFn}>
            Back to Review
          </Button>
          <Button disabled={!isItReviewed} onClick={sendOrderFn}>
            Send Order
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}
