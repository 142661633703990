import { Autocomplete, TextField } from '@mui/material'
import { ReactNode, SyntheticEvent } from 'react'

export type AutoCompleteInputProps<T = Record<string, any>> = {
  label: string
  value: T
  options: T[]
  loading: boolean
  onChange: (item: string | T | null) => void
  inputValue: string
  onInputValueChange: (inputValue: string) => void
  labelOption?: string | ((item: T) => string)
  popupIcon?: ReactNode
  forcePopupIcon?: boolean
  doNotRotatePopupIcon?: boolean
  placeholder?: string
  disableClearable?: boolean
  disabled?: boolean
}

export function AutoCompleteInput<ValueType = { label: string; [k: string]: any }>(
  props: AutoCompleteInputProps<ValueType>
): JSX.Element {
  const {
    label,
    options,
    onChange,
    onInputValueChange,
    labelOption = 'label',
    disableClearable = false,
    disabled,
  } = props
  return (
    <Autocomplete
      autoComplete
      disableClearable={disableClearable}
      options={options}
      popupIcon={props.popupIcon}
      forcePopupIcon={props.forcePopupIcon}
      filterOptions={(x) => x}
      getOptionLabel={(option: ValueType) =>
        typeof labelOption === 'string' ? option[labelOption] ?? '' : labelOption(option)
      }
      onChange={(event: SyntheticEvent, newValue: string | ValueType | null) => {
        onChange(newValue)
      }}
      onInputChange={(event: SyntheticEvent, newInputValue) => {
        onInputValueChange(newInputValue)
      }}
      renderInput={(params) => {
        return <TextField {...params} placeholder={props.placeholder ?? ''} label={label} fullWidth />
      }}
      sx={
        props.doNotRotatePopupIcon
          ? {
              '& .MuiAutocomplete-popupIndicatorOpen': { transform: 'none !important' },
              '& .MuiAutocomplete-inputRoot': { padding: '4px' },
            }
          : {}
      }
      disabled={disabled}
    />
  )
}
