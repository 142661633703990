/* eslint-disable camelcase */
import { datadogRum } from '@avenue-8/platform-shared-util-frontend'
import { DesignHuddleSdkType } from '../../shared/domain/design-huddle-sdk.type'
import { DesignHuddleTemplateCustomizationsObject } from '../../shared/domain/design-huddle-template-customizations-object'

export const createProject = (
  hdSdk: DesignHuddleSdkType,
  templateId: number,
  tco?: DesignHuddleTemplateCustomizationsObject
): Promise<string> =>
  new Promise((resolve, reject) => {
    const params = { template_id: templateId, customizations: tco }

    hdSdk.createProject(params, (error, { project_id: projectId }: { project_id: string }) => {
      if (!error) {
        resolve(projectId)
        return
      }
      if (!tco || tco === {} || (tco.classes === {} && tco.elements === {})) {
        console.error('failed to create project', error.message, error)
        datadogRum.addError(error, { extra: { templateId } })
        reject(error)
        return
      }
      hdSdk.createProject({ ...params, customizations: {} }, (error, { project_id: projectId }) => {
        if (error) {
          console.error('failed to create project, even without TCO', error.message, error)
          datadogRum.addError(error, { extra: { templateId, tco } })
          reject(error)
          return
        }
        resolve(projectId)
      })
    })
  })
