/* eslint-disable camelcase */
import { DesignHuddleProject } from './design-huddle-project'
import { DesignHuddleProxyProject } from './design-huddle-proxy-project.type'
import { DesignHuddleProxyTemplate } from './design-huddle-proxy-template.type'

export class DesignHuddleProxyAdapter {
  static convertProject(
    dhProject: DesignHuddleProject,
    template?: DesignHuddleProxyTemplate
  ): DesignHuddleProxyProject {
    const {
      project_title,
      project_id,
      last_edited,
      date_updated,
      date_created,
      source_template,
      thumbnail_url,
      last_opened,
      dimensions,
    } = dhProject
    const project: DesignHuddleProxyProject = {
      projectId: project_id,
      thumbnailURL: thumbnail_url,
      title: project_title,
      template: {
        id: template?.templateId ?? source_template.source_template_id,
        name: template?.templateTitle ?? source_template.source_template_title,
      },
      category: {
        id: template?.templateCategory?.id ?? null,
        name: template?.templateCategory?.name ?? null,
      },
      dateCreated: date_created,
      dateUpdated: date_updated,
      lastEdited: last_edited,
      lastOpened: last_opened,
      size: {
        width: dimensions.width,
        height: dimensions.height,
        unit: dimensions.unit_type.unit_type_id,
      },
      mediaType: dhProject.media_type,
    }
    return project
  }
}
