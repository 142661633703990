import styled from '@emotion/styled'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ListItemButton from '@mui/material/ListItemButton'
import List from '@mui/material/List'
import { useContext, useEffect, useMemo, useState } from 'react'
import { generateDataAttrs } from '@avenue-8/platform-shared-util-frontend'
import kebabCase from 'lodash.kebabcase'
import { EditorContext } from '../contexts/editor.context'
import { Divider, FormControlLabel, Switch, Tooltip } from '@mui/material'

const ColumnContainer = styled.div`
  --padding: 0;
  --paddingX: var(--padding, 0);
  --distance-from-the-top: var(--top-bar-height, 80px);
  background-color: #eff0f2;
  flex-shrink: 1;
  min-width: 240px;
  max-width: calc(100% - calc(2 * var(--paddingX, 8px)));
  padding: var(--padding, 8px);
  height: calc(100vh - var(--distance-from-the-top));
  overflow-y: auto;
`

const ActionsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
  margin-top: 8px;
`

const ActionItem = styled(Box)`
  background-color: #f7f7f9;
  padding: 4px 16px;
  border-radius: 8px;
`

export type RightSideEditorColumnValue = {
  label: string
  Component: (props?: { onClose?: () => void }) => JSX.Element
  hideBackButton?: boolean
  onClose?: () => void
}

export type OptionEnabledKeys = 'AI_CONTENT_ASSIST'

export type RightSideEditorColumnProps = {
  options: Record<string, RightSideEditorColumnValue>
  optionsEnabled: Record<OptionEnabledKeys, boolean>
  handleToggleOptionEnabled: (featureKey: OptionEnabledKeys, checked: boolean) => void
}

export const RightSideEditorColumn = (props: RightSideEditorColumnProps): JSX.Element => {
  const { options, optionsEnabled, handleToggleOptionEnabled } = props
  const { editorRef, editorElementToolbarExtraFeatures } = useContext(EditorContext)
  const [activeOptionKey, setActiveOptionKey] = useState<string | null>(null)

  const { Component, hideBackButton, onClose } = useMemo<RightSideEditorColumnValue | Record<string, any>>(() => {
    return options[activeOptionKey || ''] ?? {}
  }, [activeOptionKey, options])

  const handleClose = () => {
    setActiveOptionKey(null)
    onClose?.()
  }

  useEffect(() => {
    if (!activeOptionKey && editorElementToolbarExtraFeatures.elementId) {
      setActiveOptionKey('aiAssist')
      return
    }
    if (activeOptionKey === 'aiAssist' && !editorElementToolbarExtraFeatures.elementId) {
      setActiveOptionKey(null)
    }
  }, [activeOptionKey, editorElementToolbarExtraFeatures])

  const backButton = hideBackButton ? (
    <></>
  ) : (
    <Button
      fullWidth
      variant='text'
      onClick={handleClose}
      endIcon={<CloseIcon style={{ color: 'white' }} />}
      sx={{
        fontWeight: 300,
        backgroundColor: 'black',
        color: 'white',
        marginX: 0,
        justifyContent: 'end',
        borderRadius: '0 !important',
        '&:hover': {
          color: 'white',
          backgroundColor: 'black',
        },
      }}
    >
      Close
    </Button>
  )

  return (
    <ColumnContainer>
      {Component ? (
        <>
          {backButton}
          <Box p={1}>
            <Component onClose={handleClose} />
          </Box>
        </>
      ) : (
        <>
          <List sx={{ minWidth: '240px', margin: 1, marginBottom: 2 }}>
            {Object.entries(options).map(([key, { label }]) => (
              <ListItemButton
                key={key}
                sx={{
                  paddingX: 2,
                  paddingY: 1,
                  backgroundColor: '#F7F7F9',
                  borderRadius: '8px',
                  marginBottom: '8px',
                }}
                disabled={!editorRef}
                onClick={() => {
                  setActiveOptionKey(key)
                }}
                {...generateDataAttrs({
                  metaAction: `open-${kebabCase(label)}-right-side-menu`,
                  metaName: `open-${kebabCase(label)}-right-side-menu`,
                })}
              >
                <Typography>{label}</Typography>
              </ListItemButton>
            ))}
          </List>

          <Divider variant='middle' />

          <ActionsContainer>
            <ActionItem>
              <Tooltip
                title={<Typography>{optionsEnabled.AI_CONTENT_ASSIST ? 'Enabled' : 'Disabled'}</Typography>}
                placement='top'
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={optionsEnabled.AI_CONTENT_ASSIST}
                      onChange={(_, checked) => handleToggleOptionEnabled('AI_CONTENT_ASSIST', checked)}
                    />
                  }
                  label={<Typography>AI Content Assist</Typography>}
                />
              </Tooltip>
            </ActionItem>
          </ActionsContainer>
        </>
      )}
    </ColumnContainer>
  )
}
