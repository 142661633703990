import { Card, CardContent, CardProps } from '@mui/material'
import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'

const CustomCard = styled(Card)`
  background-color: #ffffff;
  width: 100%;
  cursor: pointer;
  border: solid 1px #ffffff;

  &:hover {
    border: solid 1px #72728c;
  }

  .MuiCardContent-root {
    padding: 1rem;
  }
`

export interface ContentOutputCardProps extends CardProps {
  text: string
  charactersCount: number
  wordsCount: number
}
export const ContentOutputCard = (props: ContentOutputCardProps): JSX.Element => {
  const { text, charactersCount, wordsCount, ...rest } = props
  return (
    <CustomCard {...rest}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color='primary' gutterBottom>
          {text}
        </Typography>
        <Typography sx={{ fontSize: 10 }} variant='body2' color='secondary'>
          {wordsCount} words / {charactersCount} characters
        </Typography>
      </CardContent>
    </CustomCard>
  )
}
