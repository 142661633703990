import { useCallback, useContext, useMemo } from 'react'
import { Box, Grid } from '@mui/material'
import {
  useScreenSize,
  SwiperComponent,
  SwiperSlideComponent,
  SwiperCore,
  theme,
} from '@avenue-8/platform-style-util-frontend'
import { DesignHuddleProxyTemplate as Template } from '../../shared/domain/design-huddle-proxy-template.type'
import GalleryItem from './gallery-item'
import { SwiperContext } from '../context/swiper-context'

type ProjectsRowType = {
  projects: Template[]
  onTemplateClick: (template: Template) => void
}

export default function GalleryRow({ projects, onTemplateClick }: ProjectsRowType): JSX.Element {
  const { isSmallScreen, isMobile } = useScreenSize()
  const { id: swiperId, updateNavInfo } = useContext(SwiperContext) ?? {}

  const swiperBreakpoints = useMemo((): { [width: number]: any } => {
    const breakpoints = theme.breakpoints.values
    return {
      [breakpoints.xs]: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      [breakpoints.sm]: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      [breakpoints.md]: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      [breakpoints.lg]: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      [breakpoints.xl]: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        speed: 600,
      },
    }
  }, [])

  const setUpNavigationInformation = useCallback(
    (swiper: SwiperCore & { snapIndex: number }) => {
      if (updateNavInfo) return
      const slidesPerView = swiperBreakpoints[swiper.currentBreakpoint].slidesPerView as number
      updateNavInfo({
        isPreviousDisabled: swiper.isBeginning,
        isNextDisabled: swiper.isEnd,
        currentSlide: swiper.snapIndex + 1,
        slidesPerView,
        totalSnaps: Math.ceil(projects.length / slidesPerView),
      })
    },
    [projects, updateNavInfo, swiperBreakpoints]
  )

  const swiperOptions = useMemo(
    () => ({
      onInit: setUpNavigationInformation,
      onBreakpoint: setUpNavigationInformation,
      breakpoints: swiperBreakpoints,
      onSnapIndexChange: (swiper: SwiperCore & { snapIndex: number }) => {
        updateNavInfo((prevNavigationInformation) => ({
          ...prevNavigationInformation,
          isPreviousDisabled: swiper.isBeginning,
          isNextDisabled: swiper.isEnd,
          currentSlide: swiper.snapIndex + 1,
          totalSnaps: Math.ceil(
            projects.length / (swiperBreakpoints[swiper.currentBreakpoint].slidesPerView as number)
          ),
        }))
      },
      navigation: {
        prevEl: `.previous-${swiperId}`,
        nextEl: `.next-${swiperId}`,
      },
    }),
    [projects, setUpNavigationInformation, swiperBreakpoints, updateNavInfo, swiperId]
  )

  return (
    <Box width='100%'>
      {isSmallScreen ? (
        <Grid container>
          {projects.map((template, index) => {
            const isOdd = index % 2 === 1
            const key = `template-${template.templateId}`
            return (
              <Box sx={{ mr: isOdd && isMobile ? 0 : isOdd && !isMobile ? '11%' : 2, mb: 3 }} key={key}>
                <GalleryItem template={template} onClick={onTemplateClick} />
              </Box>
            )
          })}
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            '& .swiper-container': { justifyContent: 'space-between', width: '100%' },
            mt: { xs: 1 },
            maxWidth: !isSmallScreen ? 'calc(100vw - 184px) !important;' : 'initial',
          }}
        >
          <SwiperComponent swiperOptions={swiperOptions}>
            <div>
              {projects.map((template) => {
                const key = `template-${template.templateId}`
                return (
                  <SwiperSlideComponent key={key}>
                    <Box sx={{ mb: 3 }}>
                      <GalleryItem template={template} onClick={onTemplateClick} />
                    </Box>
                  </SwiperSlideComponent>
                )
              })}
            </div>
          </SwiperComponent>
        </Grid>
      )}
    </Box>
  )
}
