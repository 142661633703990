import { DesignHuddleSdkType } from '../../shared/domain/design-huddle-sdk.type'
import { DesignHuddleTemplateCustomizationsObject } from '../../shared/domain/design-huddle-template-customizations-object'

export const registerTco =
  (dhSdk: DesignHuddleSdkType) =>
  async (tco: DesignHuddleTemplateCustomizationsObject): Promise<string> => {
    return new Promise((resolve, reject) => {
      dhSdk.storeTemplateCustomizationObject({ object: tco }, (error, data) => {
        if (error) {
          return reject(error)
        }
        const { object_hash: hash } = data
        resolve(hash)
      })
    })
  }
