import { useWebviewBridgeContext } from '@avenue-8/platform-shared-util-frontend'
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { DesignHuddleProxyContext, PublishTemplateResponse } from '../../shared/contexts/design-huddle-proxy.context'
import { DesignHuddleProxyProject } from '../../shared/domain/design-huddle-proxy-project.type'

export type ProjectsContextStatus = 'LOADING' | 'READY' | 'ERROR'

export type ProjectsContextType = {
  count: number
  status: ProjectsContextStatus
  projects: DesignHuddleProxyProject[]
  saveAsTemplateDialog: boolean
  templateToSave: TemplateToSaveType
  cloneProject: (projectId: string, projectName?: string) => Promise<string>
  deleteProject: (projectId: string) => Promise<void>
  publishTemplate: (projectId: string, templateTitle: string, categoryId: number) => Promise<PublishTemplateResponse>
  openSaveAsTemplateDialog: (
    projectId?: string,
    templateTitle?: string,
    categoryId?: number,
    selectedAssetType?: string
  ) => void
  closeSaveAsTemplateDialog: () => void
}

export const ProjectsContext = createContext<ProjectsContextType>(null)
export type TemplateToSaveType = {
  projectId: string
  templateTitle: string
  categoryId: number
  selectedAssetType: string
}

export const ProjectsProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { postMessage = null } = useWebviewBridgeContext() || {}
  const { cloneProject, deleteProject, getProjects, publishTemplate } = useContext(DesignHuddleProxyContext)

  const [status, setStatus] = useState<ProjectsContextStatus>('LOADING')
  const [projectList, setProjectList] = useState<DesignHuddleProxyProject[]>([])
  const [projectsCount, setProjectsCount] = useState<number>(0)
  const [saveAsTemplateDialog, setSaveAsTemplateDialog] = useState<boolean>(false)
  const [templateToSave, setTemplateToSave] = useState<TemplateToSaveType>({
    projectId: '',
    templateTitle: '',
    categoryId: 0,
    selectedAssetType: '',
  })

  const cloneProjectWrapper = useCallback<(id: string, name: string) => Promise<string>>(
    async (projectId: string, _projectName: string) => {
      setStatus('LOADING')
      return cloneProject(projectId)
        .then((response) => {
          if (!response.success) {
            throw new Error('There was an error deleting the project')
          }
          const { project_id: cloneId } = response.data
          // To avoid re-fetching the projects list
          setProjectList((previousList) => {
            const clonedProject = previousList.find(({ projectId: id }) => id === projectId)
            if (!clonedProject) {
              return previousList
            }
            const clone: DesignHuddleProxyProject = {
              ...clonedProject,
              projectId: cloneId,
              title: `${clonedProject.title} copy`,
              dateCreated: new Date().toString(),
              dateUpdated: new Date().toString(),
            }
            return [clone, ...previousList]
          })
          return response.data.project_id
        })
        .finally(() => {
          setStatus('READY')
        })
    },
    [deleteProject]
  )
  const deleteProjectWrapper = useCallback(
    async (projectId: string) => {
      setStatus('LOADING')
      return deleteProject(projectId)
        .then((response) => {
          if (!response.success) {
            throw new Error('There was an error cloning the project')
          }
          setProjectList((projects) => {
            return projects.filter(({ projectId: id }) => projectId !== id)
          })
        })
        .finally(() => {
          setStatus('READY')
        })
    },
    [deleteProject]
  )

  const publishTemplateWrapper = useCallback(
    async (projectId: string, templateTitle: string, categoryId: number) => {
      setStatus('LOADING')
      return publishTemplate(projectId, templateTitle, categoryId)
        .then((response) => {
          if (!response.success) {
            throw new Error('There was an error publishing as a template')
          }
          return response
        })
        .finally(() => {
          setStatus('READY')
        })
    },
    [publishTemplate]
  )

  const openSaveAsTemplateDialogWrapper = useCallback(
    (projectId?: string, templateTitle?: string, categoryId?: number, selectedAssetType?: string) => {
      setSaveAsTemplateDialog(true)
      setTemplateToSave({
        projectId,
        templateTitle,
        categoryId,
        selectedAssetType,
      })
    },
    [setSaveAsTemplateDialog, setTemplateToSave]
  )

  const closeSaveAsTemplateDialogWrapper = useCallback(() => {
    setSaveAsTemplateDialog(false)
  }, [setSaveAsTemplateDialog])

  const value = useMemo<ProjectsContextType>(
    () => ({
      status,
      count: projectsCount,
      projects: projectList,
      saveAsTemplateDialog: saveAsTemplateDialog,
      cloneProject: cloneProjectWrapper,
      deleteProject: deleteProjectWrapper,
      publishTemplate: publishTemplateWrapper,
      openSaveAsTemplateDialog: openSaveAsTemplateDialogWrapper,
      closeSaveAsTemplateDialog: closeSaveAsTemplateDialogWrapper,
      templateToSave: {
        projectId: templateToSave.projectId,
        templateTitle: templateToSave.templateTitle,
        categoryId: templateToSave.categoryId,
        selectedAssetType: templateToSave.selectedAssetType,
      },
    }),
    [status, projectList, cloneProject, deleteProject, saveAsTemplateDialog, templateToSave]
  )

  useEffect(() => {
    const assetType = postMessage === null ? '' : 'social-media'
    getProjects(1, 999, assetType)
      .then((response) => {
        setProjectsCount(response.total)
        setProjectList(response.data)
        setStatus('READY')
      })
      .catch((error) => {
        setStatus('ERROR')
        console.error('Failed to fetch projects', { error })
      })
  }, [postMessage, getProjects])

  return <ProjectsContext.Provider value={value}>{children}</ProjectsContext.Provider>
}
