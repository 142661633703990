/* eslint-disable camelcase */

export type ZoneType = 'mask' | 'fit'

export type PageElement = {
  element_name: string
  element_classes: string[]
  template_element: boolean
  type: string
  text?: string
  url?: string
  line_count?: number
  colors?: string[]
  masking_vector?: {
    width: number
    height: number
    vector_type: 'shape'
    path_count: number
  }
  zone?: {
    zone_type?: ZoneType
    width: number
    height: number
  }
  zone_type?: ZoneType
}

export type PageElementWithId = PageElement & {
  elementId: string
}

export type ProjectPageDimensions = {
  width: number
  height: number
  unit_type_id: 'px' | 'cm' | 'mm' | 'in' | 'ft'
}

export type ProjectPage = {
  page_id: string
  dimensions: ProjectPageDimensions
  background_element_id: string
  elements: Record<string, PageElement>
}

export type ProjectScene = {
  scene_id: string
  dimensions: ProjectPageDimensions
  background_color: string
  duration: number
  elements: Record<string, PageElement>
}

export type ProjectSlide = {
  slide_id: string
  dimensions: ProjectPageDimensions
  background_color: string
  elements: Record<string, PageElement>
}

export const ProjectMediaType = Object.freeze(['digital', 'video', 'print', 'presentation'] as const)

export type ProjectMediaTypeEnum = typeof ProjectMediaType[number]

export type BaseProjectData = {
  project_id: string
  project_title: string
  media_type: ProjectMediaTypeEnum
}

export type DigitalProjectData = BaseProjectData & {
  media_type: 'digital'
  pages: Array<ProjectPage>
}

export type PrintProjectData = BaseProjectData & {
  media_type: 'print'
  pages: Array<ProjectPage>
}

export type VideoProjectData = BaseProjectData & {
  media_type: 'video'
  scenes: Array<ProjectScene>
  duration: number
  global_audio_tracks: Array<any> // unknown type
  scene_slide_groups: Record<any, any> // unknown type
}

export type PresentationProjectData = BaseProjectData & {
  slides: Array<ProjectSlide>
  duration: number
  global_audio_tracks: Array<any>
  scene_slide_groups: Record<string, any>
}

export type ProjectDataType =
  | BaseProjectData
  | DigitalProjectData
  | PrintProjectData
  | VideoProjectData
  | PresentationProjectData

export function isDigitalProjectData(data: ProjectDataType): data is DigitalProjectData {
  return data.media_type === 'digital' && 'pages' in data && Array.isArray(data.pages)
}

export function isPrintProjectData(data: ProjectDataType): data is PrintProjectData {
  return data.media_type === 'print' && 'pages' in data && Array.isArray(data.pages)
}

export function isVideoProjectData(data: ProjectDataType): data is VideoProjectData {
  return data.media_type === 'video' && 'scenes' in data && Array.isArray(data.scenes)
}

export function isPresentationProjectData(data: ProjectDataType): data is PresentationProjectData {
  return data.media_type === 'presentation' && 'slides' in data && Array.isArray(data.slides)
}
