import { Box, styled } from '@mui/material'

export const ModalContent = styled(Box)`
  box-shadow: 1px 4px 40px rgba(0, 0, 0, 0.25);
  background: white;
  outline: none;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: min(16em, 100vh);
  min-width: 20em;
  border: none;

  @media (max-width: 641px) {
    border-radius: 14px 14px 0 0;
    width: 100%;
    height: calc(100vh - 24px);
  }

  @media (min-width: 641px) {
    border-radius: 7px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
