import { useListings } from '@avenue-8/platform-shared-util-frontend'
import { InfoOutlined } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from '@mui/material'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import { useCallback, useContext, useEffect, useState } from 'react'
import { AgentListingDto } from '../../../../shared/domain/agent-listing.dto'
import { EditorContext } from '../../../contexts/editor.context'
import { getFullAddress } from '../../../utils/get-full-address'
import { ListingsAutoCompleteInput } from '../../listings-auto-complete-input'
import { FoundListingBox } from './found-listing-box'
import {
  ContentContextEnum,
  DesignHuddleProxyContext,
  GenerateContentParams,
} from '../../../../shared/contexts/design-huddle-proxy.context'
import { Button } from '@avenue-8/platform-style-util-frontend'
import { OpenAiIcon } from '../../../assets/icons/openai.icon'
import { DesignHuddleTemplateCustomizationsObject } from '../../../../shared/domain/design-huddle-template-customizations-object'
import { ContentOutputCard } from './content-output-card'
import { countCharactersWithoutSpaces, countWordsWithMinLength } from '../../../utils/count-characters'

const termsKey = 'pam2-visual-accepted-listing-usage-terms'

const tonalitiesOptions = [
  { label: 'Professional', value: 'authoritative, formal' },
  { label: 'Luxurious', value: 'commanding, refined, articulate, eloquent, formal' },
  { label: 'Bold', value: 'engaging, informative' },
  { label: 'Friendly', value: 'enthusiastic, warm' },
  { label: 'Relaxed', value: 'informal, casual, conversational' },
]

export const EditorAIAssistSection = ({ onClose: _onClose }: { onClose?: () => void }): JSX.Element => {
  const { listings } = useListings()
  const { editorRef } = useContext(EditorContext)
  const [selectedListings, setSelectedListings] = useState<AgentListingDto[]>([])
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false)
  const [requireTerms, setRequireTerms] = useState<boolean>(false)
  const { generateContent } = useContext(DesignHuddleProxyContext)
  const { editorElementToolbarExtraFeatures } = useContext(EditorContext)
  const [isGeneratingContent, setIsGeneratingContent] = useState(false)
  const [contentBasedOn, setContentBasedOn] = useState<ContentContextEnum>(ContentContextEnum.LISTING_LOCATION)
  const [tonality, setTonality] = useState<typeof tonalitiesOptions[0]['value']>(tonalitiesOptions[0].value)
  const [generatedContent, setGeneratedContent] = useState<Array<string>>([])
  const [currentTab, setCurrentTab] = useState<string>('search')

  const handleGenerateContent = useCallback(async () => {
    if (!selectedListings?.[0]) {
      alert('You must select a listing first.')
      return
    }

    if (!contentBasedOn) {
      alert('Content based on is required.')
      return
    }

    const listing = selectedListings[0]
    const address = [listing.addressLine1, listing.city, listing.state, listing.zipCode].join(' ').trim()

    function getMaxNumberOfWords(sentence: string, numberOfLettersPerWord: number): number {
      const sentenceWithoutSpaces: string = sentence.replace(/\s/g, '')
      const sentenceLength: number = sentenceWithoutSpaces.length
      const maxWordCount: number = Math.floor(sentenceLength / numberOfLettersPerWord)

      return maxWordCount
    }

    editorRef?.getElementData(editorElementToolbarExtraFeatures.elementId, async (e, element) => {
      // Used 5 as an arbitrary number of characters per word
      const maxNumberOfWords = getMaxNumberOfWords(element.text?.toString(), 5)

      setIsGeneratingContent(true)

      // TODO: Allow select multiple options
      const contentContexts = [contentBasedOn]

      const propertyInfo: GenerateContentParams['propertyInfo'] = contentContexts.includes(
        ContentContextEnum.LISTING_INFORMATION_FROM_PROPERTY_SEARCH
      )
        ? {
            mlsId: listing.mlsId,
            mlsSource: listing.mlsSource,
            addressLine1: listing.addressLine1,
            addressLine2: listing.addressLine2,
            area: listing.area,
            numBathrooms: listing.numBathrooms,
            numBedrooms: listing.numBedrooms,
            lat: listing.lat,
            lng: listing.lng,
            listingStatus: listing.listingStatus?.listingStatus,
            city: listing.city,
            description: listing.description,
            parkingGarageSpaces: listing.parkingGarageSpaces,
            listDate: listing.listDate,
            listPrice: listing.listPrice,
            lotSize: listing.lotSize,
            neighborhood: listing.neighborhood,
            propertyType: listing.propertyType?.propertyType,
            state: listing.state,
            yearBuilt: listing.yearBuilt,
            zipCode: listing.zipCode,
            soldPrice: listing.soldPrice,
            soldDate: listing.soldDate,
            county: listing.county,
          }
        : undefined

      try {
        const content = await generateContent({
          address,
          contentContexts,
          maxNumberOfWords,
          propertyInfo,
          numberOfOutputs: 5,
          tonality,
          contentType: 'SOCIAL_MEDIA_POST',
        })

        if (!content) {
          alert('Failed to generate the requested content.')
          return
        }

        setGeneratedContent(content.outputs)
        setCurrentTab('outputs')
      } catch (error) {
        console.error(error)
      } finally {
        setIsGeneratingContent(false)
      }
    })
  }, [selectedListings, contentBasedOn, editorElementToolbarExtraFeatures])

  const applyContent = (content: string) => {
    const tco: DesignHuddleTemplateCustomizationsObject = {
      elements: {
        [editorElementToolbarExtraFeatures.elementId]: {
          text: content,
        },
      },
    }

    editorRef?.changeElements(tco)
  }

  useEffect(() => {
    const value = sessionStorage.getItem(termsKey) ?? ''
    setAcceptedTerms(value === 'true')
  }, [])

  useEffect(() => {
    sessionStorage.setItem(termsKey, JSON.stringify(acceptedTerms))
  }, [acceptedTerms])

  const handleListingSelect =
    (doRequireTerms = false) =>
    (listing: AgentListingDto | null) => {
      setRequireTerms(doRequireTerms)
      setSelectedListings(listing ? [listing] : [])
    }

  const handleChangeTab = (_event: React.SyntheticEvent, newTab: string) => {
    setCurrentTab(newTab)
  }
  const a11yTabProps = (tabName: string) => {
    return {
      id: `tab-${tabName}`,
      'aria-controls': `tabpanel-${tabName}`,
      value: tabName,
    }
  }

  const isGenerateContentDisabled =
    isGeneratingContent || selectedListings.length !== 1 || (requireTerms && !acceptedTerms)

  return (
    <Stack spacing={2} sx={{ padding: { md: '8px' } }}>
      <Grid container>
        <Grid item>
          <Typography
            variant='button'
            color='secondary'
            sx={{ textTransform: 'uppercase', color: 'black' }}
            fontSize={16}
          >
            AI Content Assist{' '}
            <Tooltip title={'Use AI to generate content for the selected text box in the editor.'}>
              <InfoOutlined fontSize={'inherit'} style={{ marginBottom: '-4px', fontSize: '120%', color: 'gray' }} />
            </Tooltip>
          </Typography>
          <Typography variant='body1' color='secondary' sx={{ mt: '4px' }}>
            Search for a listing or select from the MLS
          </Typography>
        </Grid>
        <Grid item sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
            <Tabs value={currentTab} onChange={handleChangeTab} variant='fullWidth' style={{ width: '100%' }}>
              <Tab label='Search' {...a11yTabProps('search')} />
              <Tab label='Outputs' {...a11yTabProps('outputs')} />
            </Tabs>
          </Box>
        </Grid>
      </Grid>

      <Stack
        spacing={2}
        sx={{ padding: { md: '8px' } }}
        style={{ display: currentTab === 'search' ? 'inherit' : 'none' }}
      >
        <ListingsAutoCompleteInput
          label='SEARCH'
          selectedListing={selectedListings[0]}
          onSelectedListingChange={handleListingSelect(true)}
          disabled={isGeneratingContent}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptedTerms}
                onChange={({ target: { checked } }) => {
                  setAcceptedTerms(checked)
                }}
              />
            }
            label={
              <small>
                By selecting a listing that is not submitted to the MLS, I agree to follow the guidelines of the Clear
                Cooperation Policy, regarding marketing to the public.
              </small>
            }
          />
        </FormGroup>
        <TextField
          select
          label='Listings'
          variant='outlined'
          inputProps={{ notched: false, sx: { padding: '4px' } }}
          SelectProps={{ native: true }}
          value={''}
          onChange={(event) => {
            const listingId = event.target.value
            const selected = listings.find(({ mlsSource, mlsId }) => listingId === `${mlsSource}/${mlsId}`) ?? null
            handleListingSelect(false)(selected)
          }}
          disabled={isGeneratingContent}
        >
          <option value=''>Select a listing to apply</option>
          {listings.map((listing, key) => {
            return (
              <option key={key} value={`${listing.mlsSource}/${listing.mlsId}`}>
                {getFullAddress(listing)}
              </option>
            )
          })}
        </TextField>
        <List dense>
          {selectedListings.map((listing, idx) => {
            return <FoundListingBox key={`listing-${idx + 1}`} listing={listing} />
          })}
        </List>
        <TextField
          select
          label='Content based on'
          variant='outlined'
          inputProps={{ notched: false, sx: { padding: '4px' } }}
          SelectProps={{ native: true }}
          value={contentBasedOn}
          onChange={(e) => {
            setContentBasedOn(e.target.value as ContentContextEnum)
          }}
          disabled={isGeneratingContent}
        >
          <option key={ContentContextEnum.LISTING_LOCATION} value={ContentContextEnum.LISTING_LOCATION}>
            Listing Location
          </option>
          <option
            key={ContentContextEnum.LISTING_INFORMATION_FROM_PROPERTY_SEARCH}
            value={ContentContextEnum.LISTING_INFORMATION_FROM_PROPERTY_SEARCH}
          >
            Listing information from Property Search
          </option>
        </TextField>
        <TextField
          select
          label='Tonality'
          variant='outlined'
          inputProps={{ notched: false, sx: { padding: '4px' } }}
          SelectProps={{ native: true }}
          value={tonality}
          onChange={(e) => {
            setTonality(e.target.value as typeof tonalitiesOptions[0]['value'])
          }}
          disabled={isGeneratingContent}
        >
          {tonalitiesOptions.map(({ label, value }) => (
            <option key={`tonality-${value}`} value={value}>
              {label}
            </option>
          ))}
        </TextField>
        <Tooltip
          title={
            'The size of the generated content will be based on the previous text inside the text box in the editor.'
          }
        >
          <Typography
            variant='body1'
            color='secondary'
            fontSize={12}
            style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}
          >
            About the generated content
            <InfoOutlined fontSize={'inherit'} style={{ fontSize: '120%', color: 'gray' }} />
          </Typography>
        </Tooltip>

        {!!editorElementToolbarExtraFeatures?.elementId && (
          <div>
            {editorElementToolbarExtraFeatures.features.openaiContentGeneration && (
              <Button
                onClick={!isGenerateContentDisabled ? handleGenerateContent : () => null}
                fullWidth
                style={{ gap: '0.75rem' }}
                disabled={isGenerateContentDisabled}
              >
                {!isGeneratingContent ? (
                  <>
                    <OpenAiIcon /> Generate content
                  </>
                ) : (
                  <>
                    <CircularProgress size={24} /> Generating...
                  </>
                )}
              </Button>
            )}
            <Typography variant='body1' color='secondary' fontSize={12} mt={2}>
              Disclaimer: AI-generated output may not be fully accurate. Cross-verify before using the information.
            </Typography>
          </div>
        )}
      </Stack>

      <div
        style={{
          display: currentTab === 'outputs' ? 'flex' : 'none',
          gap: '1rem',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        {generatedContent.map((content, i) => {
          const charactersCount = countCharactersWithoutSpaces(content)
          const wordsCount = countWordsWithMinLength(content)
          return (
            <ContentOutputCard
              text={content}
              charactersCount={charactersCount}
              wordsCount={wordsCount}
              key={`content-${charactersCount * wordsCount * (i + 1)}`}
              onClick={() => applyContent(content)}
            />
          )
        })}
      </div>
    </Stack>
  )
}
