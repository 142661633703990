import React from 'react'
import styled from '@emotion/styled'
import { ImageIcon } from '../assets/icons/image.icon'

const ImageUnavailableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: #fafaff;
  max-height: 100%;
  max-width: 100%;
  color: #73738c;
  letter-spacing: 2px;
`

const Message = styled.span`
  text-transform: uppercase;
  margin-top: 0.5rem;
  text-align: center;
  font-size: 12px;
`

type ImageUnavailableProps = {
  message?: string
}

export const ImageUnavailable: React.FC<ImageUnavailableProps> = ({ message = 'Image Unavailable' }) => {
  return (
    <ImageUnavailableContainer>
      <ImageIcon />
      <Message>{message}</Message>
    </ImageUnavailableContainer>
  )
}
