import { generateDataAttrs } from '@avenue-8/platform-shared-util-frontend'
import { Box, Card, Grid, Typography } from '@mui/material'
import kebabCase from 'lodash.kebabcase'
import { MouseEvent, useContext } from 'react'
import { ProjectsContext } from '../contexts/projects.context'
import ProjectCardMoreActionsMenu from './project-card-more-actions-menu'

export type ProjectCardType = {
  projectId: string
  projectTitle: string
  thumbnailUrl: string
  dateUpdated: string
  alt: string
  href: string
  onClick?: (event: MouseEvent) => void
  label?: string
  fullWidthImage?: boolean
  categoryId: number
}

export default function ProjectCard(props: ProjectCardType): JSX.Element {
  const {
    projectId,
    projectTitle,
    thumbnailUrl,
    dateUpdated,
    alt,
    href,
    onClick,
    label = '',
    fullWidthImage = false,
    categoryId,
  } = props
  const projectsContext = useContext(ProjectsContext)
  const date = new Date(dateUpdated)
  const formattedLastUpdatedDate = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear() || ''

  return (
    <Card
      component='a'
      href={href}
      onClick={onClick}
      elevation={2}
      style={{ textDecoration: 'none' }}
      {...generateDataAttrs({
        metaId: projectId,
        metaName: `${kebabCase(projectTitle)}-project-card`,
        metaAction: `${kebabCase(projectTitle)}-project-card`,
      })}
    >
      <Grid
        container
        flexDirection='column'
        wrap='nowrap'
        sx={{
          border: '1px solid #d5d6ea',
          borderRadius: '4px',
          background: '#f8f8f9',
          width: { xs: '156px', md: '270px' },
          height: { xs: '177px', md: '270px' },
          cursor: 'pointer',
          '&:hover': {
            background: '#fafaff',
          },
        }}
      >
        <Grid container justifyContent='center' sx={{ backgroundColor: 'white' }}>
          <Box
            sx={{
              border: {
                xs: fullWidthImage ? '0' : '2px solid transparent',
                md: fullWidthImage ? '0' : '8px solid transparent',
              },
              width: { xs: fullWidthImage ? '100%' : '124px', md: fullWidthImage ? '100%' : '200px' },
              height: { xs: '92px', md: '130px' },
              borderRadius: '4px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img height='100%' src={thumbnailUrl} alt={alt} style={{ objectFit: 'contain' }} />
          </Box>
        </Grid>
        <Grid
          container
          flexDirection='column'
          justifyContent='space-around'
          flex={1}
          sx={{ height: { xs: 90, md: 'auto' }, p: { xs: 1, md: 2 } }}
        >
          <Grid sx={{ width: '100%', overflow: 'hidden' }}>
            <Typography fontWeight={400} variant='body2' sx={{ fontSize: { xs: '14px', md: '16px' } }} noWrap>
              {projectTitle}
            </Typography>
          </Grid>

          <Grid
            container
            flexDirection='row'
            justifyContent='space-between'
            flex={1}
            sx={{ mt: 3, width: '100%', overflow: 'hidden' }}
          >
            <Grid>
              <Typography
                fontWeight={400}
                variant='body2'
                sx={{ color: '#8C8EA1', fontSize: { xs: '10px', md: '12px' } }}
                noWrap
              >
                {label}
              </Typography>
              <Typography
                fontWeight={400}
                variant='body2'
                sx={{ color: '#8C8EA1', fontSize: { xs: '10px', md: '12px' } }}
                noWrap
              >
                updated {formattedLastUpdatedDate}
              </Typography>
            </Grid>
            {projectsContext && (
              <Grid>
                <ProjectCardMoreActionsMenu
                  projectId={projectId}
                  categoryId={categoryId}
                  templateTitle={projectTitle}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}
