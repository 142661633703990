import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import {
  FinalizeDialog,
  CreditCardSelector,
  CreditCardInfo,
  PagePreviews,
} from '@avenue-8/platform-style-util-frontend'
import {
  ResponseType,
  PaymentMethod,
  useBilling,
  generateDataAttrs,
  useWebviewBridgeContext,
} from '@avenue-8/platform-shared-util-frontend'
import { Button, Typography, Box, Grid } from '@mui/material'
import { AgentListingDto } from '../../../shared/domain/agent-listing.dto'
import { capitalize } from '../../../shared/utils/formatters'
import { AssetType, DesignHuddleCategoriesContext } from '../../../shared/contexts/design-huddle-categories.context'

export type PriceData = { order: number; label: string; value: number }

export type FormError = false | string | string[]

export type FormStep = {
  title: string
  subtitle: string
  component: JSX.Element
}

export type FinalizeWrapperType = {
  assetType: AssetType
  formSteps: FormStep[]
  priceList: PriceData[]
  isFinalizeDialogOpen: boolean
  handleClose: () => void
  onSubmit: () => void
  formValid: boolean
  hidePayment?: boolean
  submitting?: boolean
  listing?: AgentListingDto
  projectThumbnail?: string
}

const { format: moneyFormatter } = new Intl.NumberFormat('en-us', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  minimumIntegerDigits: 1,
})

export default function FinalizeWrapper(props: FinalizeWrapperType): JSX.Element {
  const { formSteps, isFinalizeDialogOpen, handleClose, listing, projectThumbnail } = props
  const billingService = useBilling()
  const { assetTypesDict } = useContext(DesignHuddleCategoriesContext)
  const [paymentMethods, setPaymentMethods] = useState<CreditCardInfo[]>([])
  const [pagesPreviews, setPagesPreviews] = useState<{ url: string; id: string }[]>([])
  const [categoryLabel, setCategoryLabel] = useState<string>('')
  const { postMessage } = useWebviewBridgeContext() ?? {}

  const priceSum = useMemo(
    () => props.priceList.map(({ value }) => value).reduce((a, c) => a + c, 0),
    [props.priceList]
  )

  const handleCreditCardChange = (_cardId: string) => {
    console.error(new Error('Not implemented yet'))
  }

  // Mocked info for demo purpose
  const enumeratedComponentList: FormStep[] = [...formSteps]

  if (!props.hidePayment) {
    enumeratedComponentList.push({
      title: 'Payment Details'.toUpperCase(),
      subtitle: 'Set your payment details.',
      component: (
        <CreditCardSelector
          cards={paymentMethods}
          onCreditCardChange={handleCreditCardChange}
          creditCardComponent={
            <Typography>
              {postMessage ? (
                <span>
                  To use another credit card, please change your credit card on the Billing page on your desktop device.
                </span>
              ) : (
                <span>
                  To use another credit card, please change your main credit card on{' '}
                  <a href='/account/billing'>Billing</a>
                </span>
              )}
            </Typography>
          }
        />
      ),
    })
  }

  // Adding just the project thumbnail to the preview as we don't have a DH editor reference
  // to ask a render for every page in this context
  useEffect(() => {
    if (projectThumbnail) {
      setPagesPreviews([{ id: '1', url: projectThumbnail }])
    }
  }, [projectThumbnail])

  useEffect(() => {
    if (billingService) {
      billingService
        .getDefaultPaymentMethod()
        .then((billingResponse: ResponseType<{ defaultPaymentMethod: PaymentMethod }>) => {
          if (billingResponse.success && billingResponse.data?.defaultPaymentMethod) {
            const { data } = billingResponse
            const { card, id } = data.defaultPaymentMethod
            const { last4, brand } = card
            setPaymentMethods([{ lastFourDigits: last4, brandName: capitalize(brand), paymentProviderId: id }])
          }
        })
        .catch((err) => console.error(err))
    }
  }, [billingService])

  useEffect(() => {
    if (props.assetType) {
      const label = assetTypesDict[props.assetType]?.label.toUpperCase()
      setCategoryLabel(label)
    }
  }, [props.assetType, assetTypesDict])

  const submitButton = <SubmitButton onClick={props.onSubmit} disabled={!props.formValid || props.submitting} />

  const preview = <PreviewSection listing={listing} pages={pagesPreviews} category={categoryLabel} />

  const priceListAndSubmit = <BottomLeftSection sum={priceSum} list={props.priceList} submitSlot={submitButton} />

  return (
    <FinalizeDialog
      open={isFinalizeDialogOpen}
      totalPrice={moneyFormatter(priceSum).replace('$', '')}
      title='Finalize'
      leftSideBarFirstSlot={preview}
      leftSideBarSecondSlot={priceListAndSubmit}
      mobileSlot={preview}
      handleClose={handleClose}
      enumeratedComponentList={enumeratedComponentList}
      mobileSubmitSlot={submitButton}
    />
  )
}

const SubmitButton = (props: {
  onClick: () => void
  disabled: boolean
  label?: string
  children?: ReactNode
}): JSX.Element => {
  const { onClick, disabled, label = 'Submit', children } = props
  return (
    <Button
      variant='contained'
      fullWidth
      size='large'
      disabled={disabled}
      onClick={onClick}
      {...generateDataAttrs({
        metaAction: 'submit-project',
      })}
    >
      {children ?? label}
    </Button>
  )
}

const PriceLine = ({
  label,
  value,
  isTotal = false,
}: {
  label: string
  value: number
  isTotal?: boolean
}): JSX.Element => {
  const fontWeight = isTotal ? 700 : 400

  return (
    <Grid item container flexDirection='row' justifyContent='space-between' sx={{ marginBottom: '16px' }}>
      <Grid item sx={{ padding: '4px 4px 4px 0' }}>
        <Typography fontSize={16} fontFamily='Roboto' color='secondary' fontWeight={fontWeight}>
          {label}
        </Typography>
      </Grid>
      <Grid item sx={{ padding: '4px 4px 4px 0' }}>
        <Typography fontSize={16} fontFamily='Roboto' color='secondary' fontWeight={fontWeight}>
          {moneyFormatter(value)}
        </Typography>
      </Grid>
    </Grid>
  )
}

type BottomLeftSectionProps = {
  sum: number
  list: PriceData[]
  submitSlot: ReactNode
}

const BottomLeftSection = (props: BottomLeftSectionProps) => {
  const { sum, list } = props
  return (
    <Box>
      <Grid flexDirection='column' columnSpacing={2}>
        {list.map((priceItem, idx) => (
          <PriceLine key={idx} label={priceItem.label} value={priceItem.value} />
        ))}
        <Grid
          item
          container
          flexDirection='row'
          sx={{ borderBottom: '1px #333 solid', height: '2px', marginTop: '8px', marginBottom: '8px' }}
        >
          <hr />
        </Grid>
        <PriceLine label={'Total'} value={sum} isTotal />
        {props.submitSlot}
      </Grid>
    </Box>
  )
}

const PreviewSection = ({
  listing,
  pages,
  category,
}: {
  listing?: AgentListingDto
  pages: { url: string; id: string }[]
  category: string
}) => {
  return (
    <Box>
      <Typography variant='body2' fontSize={12} style={{ marginBottom: '8px' }} letterSpacing={2} color='secondary'>
        {listing ? `${listing.addressLine1} /` : ''} {category}
      </Typography>
      <PagePreviews imgMaxWidth='300px' pages={pages} />
    </Box>
  )
}
