import { CircularProgress, Grid, Typography } from '@mui/material'
import formatDate from 'date-fns/format'
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { VisualMarketingServiceContext } from '../../../shared/services/visual-marketing/visual-marketing.service.context'
import {
  EstimateCoefficient,
  EstimatesCoefficientDictionary,
} from '../../../shared/services/visual-marketing/visual-marketing.service.type'
import { FacebookEstimates } from './facebook-estimates'
import { defaultTheme } from '@avenue-8/platform-style-util-frontend'
import styled from '@emotion/styled'

type BasicStatus = 'IDLE' | 'STARTING' | 'LOADING' | 'ERROR'

export type VisualMarketingFacebookEstimatesProps = Partial<{
  budget: number
  city: string
  startDate: Date
  endDate: Date
  onEstimatesChange?: (estimates: any) => void
}>

const EstimatesBox = styled(Grid)`
  background-color: ${(p) => defaultTheme?.backgroundAlt};
`

const EstimatesText = styled(Typography)`
  color: ${(p) => defaultTheme?.secondaryColor};
  opacity: 0.4;
  font-size: 16px;
`

const EstimatesContainer = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <EstimatesBox container justifyContent='center' alignItems='center' p={3}>
      {children}
    </EstimatesBox>
  )
}

const calculateEstimate = (budgetValue: number, coefficients: EstimateCoefficient[]): number =>
  coefficients.reduce((sum, { order: n, coefficient: a }) => sum + a * Math.pow(budgetValue, n), 0)

export const VisualMarketingFacebookEstimates = (props: VisualMarketingFacebookEstimatesProps): JSX.Element => {
  const { budget, city, startDate, endDate } = props
  const [status, setStatus] = useState<BasicStatus>('STARTING')
  const visualMktService = useContext(VisualMarketingServiceContext)
  const [coefficients, setCoefficients] = useState<EstimatesCoefficientDictionary>({})
  const [estimates, setEstimates] = useState<{ [k: string]: number }>({})
  const [hadBudget, setHadBudget] = useState<boolean>(false)
  const [previousCity, setPreviousCity] = useState<typeof city>(city)
  const [previousStartDate, setPreviousStartDate] = useState<string>()
  const [previousEndDate, setPreviousEndDate] = useState<string>()

  const differentCity = useMemo(() => city.toString() !== previousCity.toString(), [city, previousCity])

  const payload = useMemo(() => {
    if (budget && city && startDate && endDate) {
      return {
        budget: (budget * 100).toString(),
        startDate: formatDate(startDate, 'yyyy-MM-dd'),
        endDate: formatDate(endDate, 'yyyy-MM-dd'),
        city,
      }
    }
    return null
  }, [budget, city, startDate, endDate])

  const differentStartDate = useMemo(
    () => payload?.startDate !== previousStartDate,
    [payload?.startDate, previousStartDate]
  )
  const differentEndDate = useMemo(() => payload?.endDate !== previousEndDate, [payload?.endDate, previousEndDate])

  useEffect(() => {
    const shouldRefresh = !hadBudget || differentCity || differentStartDate || differentEndDate
    if (visualMktService && payload && shouldRefresh) {
      setHadBudget(true)
      setStatus('LOADING')
      setPreviousCity(payload.city)
      setPreviousStartDate(payload.startDate)
      setPreviousEndDate(payload.endDate)
      visualMktService
        .getFacebookEstimates(payload.budget, payload.startDate, payload.endDate, payload.city)
        .then((coefficients) => {
          setCoefficients(coefficients)
          setStatus('IDLE')
        })
        .catch((error) => {
          console.error('Failed to fetch estimates coefficients:', error)
          setStatus('ERROR')

          if (props.onEstimatesChange) {
            props.onEstimatesChange(null)
          }
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [differentCity, differentStartDate, differentEndDate, hadBudget, payload, visualMktService]) // TODO: fix this effect loop with proper deps

  useEffect(() => {
    if (coefficients && !isNaN(budget) && budget > -1) {
      const estimates = Object.fromEntries(
        Object.entries(coefficients).map(([key, values]) => [key, calculateEstimate(budget, values)])
      )
      setEstimates(estimates)

      if (props.onEstimatesChange) {
        props.onEstimatesChange(estimates)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budget, coefficients]) // TODO: fix this effect loop with proper deps

  if (!budget || !city || !startDate || !endDate) {
    return (
      <EstimatesContainer>
        <EstimatesText>Enter a budget and campaign dates to see delivery estimates.</EstimatesText>
      </EstimatesContainer>
    )
  }
  if (status === 'ERROR') {
    return (
      <EstimatesContainer>
        <EstimatesText>An error occurred, please try again.</EstimatesText>
      </EstimatesContainer>
    )
  }
  if (status === 'LOADING') {
    return (
      <EstimatesContainer>
        <CircularProgress />
      </EstimatesContainer>
    )
  }
  if (status === 'IDLE' || status === 'STARTING') {
    return (
      <EstimatesContainer>
        <FacebookEstimates values={estimates} />
      </EstimatesContainer>
    )
  }
  return <></>
}
