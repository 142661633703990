import { DesignHuddleSdkType } from '../../shared/domain/design-huddle-sdk.type'
import { DesignHuddleTemplateCustomizationsObject } from '../../shared/domain/design-huddle-template-customizations-object'

export type getTemplatePreviewAsync = (
  templateId: number,
  customizationObject: string | DesignHuddleTemplateCustomizationsObject
) => Promise<{ templateId: number; imageUrl: string }>

export const getTemplatePreview =
  (dhSdk: DesignHuddleSdkType): getTemplatePreviewAsync =>
  (templateId, customizationObject) => {
    return new Promise((resolve, reject) => {
      const customizationsKey: 'customizations' | 'customizations_hash' =
        typeof customizationObject === 'string' ? 'customizations_hash' : 'customizations'
      dhSdk.getVariableTemplatePreviewURL(
        {
          template_id: templateId,
          [customizationsKey]: customizationObject,
        },
        function (error, imageUrl) {
          if (error) {
            return reject(error)
          }
          resolve({ templateId, imageUrl })
        }
      )
    })
  }
