import { ModalUnstyled, styled } from '@mui/material'

export const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 125px);
  height: 100vh;
  outline: none;

  @media (max-width: 641px) {
    width: 100%;
  }
`
