import { createContext, ReactNode, useMemo } from 'react'
import { BACKEND_APP_API_URL } from '@avenue-8/platform-shared-util-frontend'
import { BackendAppServiceFactory } from './backend-app.service.factory'
import { BackendAppService } from './backend-app.service.type'

export const BackendAppServiceContext = createContext<BackendAppService>(null)

export const BackendAppServiceProvider = ({
  children,
  baseUrl = BACKEND_APP_API_URL,
}: {
  children: ReactNode
  baseUrl?: string
}): JSX.Element => {
  const service = useMemo(() => BackendAppServiceFactory(baseUrl), [baseUrl])
  return <BackendAppServiceContext.Provider value={service}>{children}</BackendAppServiceContext.Provider>
}
