export const countWordsWithMinLength = (phrase: string): number => {
  const words = phrase.split(' ')
  let count = 0

  for (const word of words) {
    if (word.length > 2) {
      count++
    }
  }

  return count
}

export const countCharactersWithoutSpaces = (phrase: string): number => {
  const characters = phrase.replace(/\s/g, '')
  return characters.length
}
