import { VISUAL_MARKETING_API_URL } from '@avenue-8/platform-shared-util-frontend'
import { createContext, ReactNode, useMemo } from 'react'
import { VisualMarketingServiceFactory } from './visual-marketing.service.factory'
import { VisualMarketingService } from './visual-marketing.service.type'

export const VisualMarketingServiceContext = createContext<VisualMarketingService>(null)

export const VisualMarketingServiceProvider = ({
  children,
  baseUrl = VISUAL_MARKETING_API_URL,
}: {
  children: ReactNode
  baseUrl?: string
}): JSX.Element => {
  const service = useMemo(() => VisualMarketingServiceFactory(baseUrl), [baseUrl])
  return <VisualMarketingServiceContext.Provider value={service}>{children}</VisualMarketingServiceContext.Provider>
}
