/* eslint-disable camelcase */
import { useAuthContext, AuthedFetch, DH_PROXY_API_URL } from '@avenue-8/platform-shared-util-frontend'
import { createContext, ReactNode, useEffect, useState } from 'react'
import { DesignHuddleSdkType } from '../domain/design-huddle-sdk.type'

declare global {
  interface Window {
    DSHDLib?: DesignHuddleSdkType
  }
}

const dhProxyUrl = `${DH_PROXY_API_URL}/design`

const scriptEl = document.createElement('script')
scriptEl.type = 'text/javascript'
scriptEl.src = 'https://cdn.designhuddle.com/jssdk/v1/lib.min.js'
document.getElementsByTagName('head')[0].appendChild(scriptEl)

export type DesignHuddleSdkContextValue =
  | {
      dhSdk: typeof window.DSHDLib
      token: string
      appToken: string
    }
  | Record<string, never>

export const DesignHuddleContext = createContext<DesignHuddleSdkContextValue>({})

export const DesignHuddleContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { token: av8Token, agentId } = useAuthContext()
  const [ready, setReady] = useState<boolean>(false)
  const [dhSdk, setDhSdk] = useState<typeof window.DSHDLib | undefined>(window.DSHDLib)
  const [userAccessToken, setUserAccessToken] = useState<string>()
  const [appAccessToken, setAppAccessToken] = useState<string>()
  useEffect(() => {
    if (av8Token && agentId) {
      AuthedFetch(`${dhProxyUrl}/access-token`, {
        queryParams: new URLSearchParams({ accessType: 'user' }),
      })
        .then((response) => response.json())
        .then((data: { token: string; expiresIn: number }) => {
          setUserAccessToken(data.token) // assign the access token
        })
        .catch((err) => {
          console.error('Failed to fetch token:', err)
          setUserAccessToken('')
        })
      AuthedFetch(`${dhProxyUrl}/access-token`, {
        queryParams: new URLSearchParams({ accessType: 'app' }),
      })
        .then((response) => response.json())
        .then((data: { token: string; expiresIn: number }) => {
          setAppAccessToken(data.token) // assign the access token
        })
        .catch((err) => {
          console.error('Failed to fetch token:', err)
          setAppAccessToken('')
        })
    }
  }, [av8Token, agentId])

  useEffect(() => {
    if (dhSdk) {
      return
    }
    let timerRef: NodeJS.Timeout
    const setTimer = () => {
      if (window.DSHDLib) {
        setDhSdk(window.DSHDLib)
      } else {
        timerRef = setTimeout(setTimer, 250)
      }
    }
    timerRef = setTimeout(setTimer, 250)
    return () => {
      clearTimeout(timerRef)
    }
  }, [])

  useEffect(() => {
    if (dhSdk && av8Token && agentId && userAccessToken) {
      dhSdk.configure({
        domain: 'design.avenue8.com',
        access_token: userAccessToken,
      })
      setReady(true)
    }
  }, [dhSdk, av8Token, agentId, userAccessToken])

  const value: DesignHuddleSdkContextValue = ready ? { dhSdk, token: userAccessToken, appToken: appAccessToken } : {}
  return <DesignHuddleContext.Provider value={value}>{children}</DesignHuddleContext.Provider>
}
