import { PaginationButton, useScreenSize } from '@avenue-8/platform-style-util-frontend'
import styled from '@emotion/styled'
import { Box, Grid, Skeleton, Typography } from '@mui/material'
import { useContext, useMemo } from 'react'
import { DesignHuddleProxyTemplate as Template } from '../../shared/domain/design-huddle-proxy-template.type'
import { SwiperContext } from '../context/swiper-context'
import GalleryRow from './gallery-row'

const ListingSelectorContainer = styled.div`
  margin-top: 16px;
`

type EditGallerySectionType = {
  sectionTitle: string
  customSectionTitleComponent?: JSX.Element
  templatesList: Template[]
  loading: boolean
  onTemplateClick: (template: Template) => void
}

export function TemplateSlides({
  sectionTitle,
  customSectionTitleComponent,
  templatesList,
  loading,
  onTemplateClick,
}: EditGallerySectionType): JSX.Element {
  const { isSmallScreen } = useScreenSize()
  const { id: swiperId, navInfo: swiperNav } = useContext(SwiperContext) ?? {}

  const templateQty = useMemo<number>(() => templatesList?.length ?? 0, [templatesList])

  const navigationControls = useMemo(
    () =>
      !loading &&
      !isSmallScreen &&
      templateQty > 0 && (
        <PaginationButton
          backButtonProps={{
            className: `previous-${swiperId}`,
          }}
          nextButtonProps={{
            className: `next-${swiperId}`,
          }}
          backDisabled={swiperNav?.isPreviousDisabled}
          nextDisabled={swiperNav?.isNextDisabled}
          middleChildren={
            <Typography variant='body1' fontWeight='normal'>
              {swiperNav?.currentSlide}/{swiperNav?.totalSnaps}
            </Typography>
          }
        />
      ),
    [swiperNav, swiperId, templateQty, loading, isSmallScreen]
  )

  const title = useMemo(
    () => (
      <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
        <Box>
          <Typography
            fontSize={12}
            fontFamily='Roboto'
            fontWeight='700'
            color='#131319'
            lineHeight='15px'
            letterSpacing={2}
          >
            {sectionTitle.toUpperCase() || ''}
          </Typography>

          {customSectionTitleComponent && (
            <ListingSelectorContainer>{customSectionTitleComponent}</ListingSelectorContainer>
          )}
        </Box>

        {navigationControls}
      </Box>
    ),
    [sectionTitle, customSectionTitleComponent, navigationControls]
  )

  const templatesRow = useMemo(() => {
    if (loading) {
      return <Skeleton variant='rectangular' width='100%' height='30vh' />
    }
    if (templateQty < 1) {
      return (
        <Typography variant='body2' color='disabled' sx={{ marginY: 2 }}>
          No templates found.
        </Typography>
      )
    }
    return <GalleryRow projects={templatesList} onTemplateClick={onTemplateClick} />
  }, [loading, templateQty, templatesList, onTemplateClick])

  return (
    <Grid mt={3}>
      {title}
      <Grid container>{templatesRow}</Grid>
    </Grid>
  )
}
