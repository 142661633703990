import React, { useContext, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { BackendAppServiceContext } from '../../shared/services/backend-app/backend-app.service.context'
import { AutocompletedAddress } from '../../shared/services/backend-app/backend-app.service.type'
import { AutoCompleteInput } from '../components/auto-complete-input'
import { useDebounce } from '../../shared/hooks/useDebounce'

export type AddressAutoCompleteInputProps = {
  label?: string
  selectedAddress: AutocompletedAddress
  onSelectedAddressChange: (address: AutocompletedAddress | null) => void
}

const AddressAutocomplete = styled.div`
  margin-top: 10px;
`

export const AddressAutoCompleteInput = ({
  label,
  selectedAddress,
  onSelectedAddressChange,
}: AddressAutoCompleteInputProps): JSX.Element => {
  const { autocompleteAddress } = useContext(BackendAppServiceContext)
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [addresses, setAddresses] = useState<AutocompletedAddress[]>([])
  const debouncedSearchAddressTerm = useDebounce(inputValue, 300)

  useEffect(() => {
    if (autocompleteAddress && debouncedSearchAddressTerm.length > 0) {
      setIsSearching(true)
      autocompleteAddress(debouncedSearchAddressTerm)
        .then((response) => {
          if (response.success === true) {
            setAddresses(response.data)
          }
          setIsSearching(false)
        })
        .catch((error) => {
          console.error(error)
          setIsSearching(false)
        })
    }
  }, [debouncedSearchAddressTerm, autocompleteAddress])

  return (
    <AddressAutocomplete>
      <AutoCompleteInput
        forcePopupIcon
        doNotRotatePopupIcon
        placeholder='Enter an address, city or zip'
        label={label}
        labelOption={({ description }: AutocompletedAddress) => description}
        value={selectedAddress}
        onChange={onSelectedAddressChange}
        inputValue={inputValue}
        onInputValueChange={setInputValue}
        options={addresses}
        loading={isSearching}
        popupIcon={false}
      />
    </AddressAutocomplete>
  )
}
