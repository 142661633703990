import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Typography } from '@mui/material'
import { SocialMediaCampaignForm } from '../../forms/social-media-campaign.form'
import FinalizeWrapper, { FormStep, PriceData } from '../finalize-wrapper'
import { DesignHuddleProxyContext } from '../../../../shared/contexts/design-huddle-proxy.context'
import { ErrorBoundary } from '@avenue-8/platform-shared-util-frontend'

export type SocialMediaFinalizePageProps = {
  handleClose: () => void
  handleSubmit: () => void
  handlePriceChange: (priceChanged: { [k: string]: PriceData }) => void
  setIsFormValid: (isValid: boolean) => void
  setSubmitFn: any
  isLoading: boolean
  price: PriceData
  priceList: PriceData[]
}

export const SocialMediaFinalizePage = ({
  handleSubmit,
  handlePriceChange,
  handleClose,
  setSubmitFn,
  setIsFormValid,
  isLoading,
  price,
  priceList,
}: SocialMediaFinalizePageProps): JSX.Element => {
  const [isValid, setIsValid] = useState(false)
  const [projectThumbnail, setProjectThumbnail] = useState<string>()
  const { projectId } = useParams<{ projectId?: string }>()
  const { getProjectData } = useContext(DesignHuddleProxyContext)

  const handleFormChange = (isFormValid: boolean) => {
    setIsValid(isFormValid)
    setIsFormValid(isFormValid)
  }

  useEffect(() => {
    getProjectData(projectId)
      .then((data) => setProjectThumbnail(data.thumbnailURL))
      .catch((error) => console.error(error))
  }, [getProjectData, projectId])

  const steps: FormStep[] = [
    {
      title: 'Campaign details'.toUpperCase(),
      subtitle: 'Set campaign details.',
      component: (
        <ErrorBoundary fallback={<Typography>Looks like some went wrong here, please try again</Typography>}>
          <SocialMediaCampaignForm
            showCity
            price={price}
            onPriceChange={handlePriceChange}
            onFormChange={handleFormChange}
            setSubmitFn={setSubmitFn}
          />
        </ErrorBoundary>
      ),
    },
  ]

  return (
    <FinalizeWrapper
      assetType={'social-media'}
      formValid={isValid}
      formSteps={steps}
      isFinalizeDialogOpen
      priceList={priceList}
      handleClose={handleClose}
      submitting={isLoading}
      onSubmit={handleSubmit}
      projectThumbnail={projectThumbnail}
    />
  )
}
