import { Box, Typography } from '@mui/material'
import { useHistory } from 'react-router'
import { CATEGORY_ID_URL_SEARCH_KEY } from '../../editor/contexts/editor.context'
import useDHProjectId from '../../shared/hooks/useDHProjectId'

export default function CreateNewProject(): JSX.Element {
  const { categoryId, projectId, loading, error } = useDHProjectId({})
  const history = useHistory()

  if (projectId) {
    setTimeout(() => {
      history.replace(`/${projectId}?${CATEGORY_ID_URL_SEARCH_KEY}=${categoryId}`)
      window.location.reload()
    }, 1000)
  }

  return (
    <Box p={3}>
      {loading && !error && (
        <Box>
          <Typography variant='h3' component='h2'>
            Creating Project...
          </Typography>
        </Box>
      )}
      {error && (
        <Typography variant='h3' component='h2'>
          We had a problem finding this template...
        </Typography>
      )}
    </Box>
  )
}
