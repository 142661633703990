import React from 'react'
import { defaultTheme } from '@avenue-8/platform-style-util-frontend'
import styled from '@emotion/styled'
import { Typography } from '@mui/material'

export const InputFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const InputLabel = styled(Typography)`
  color: ${defaultTheme.secondaryColor};
  font-style: 'normal';
  font-weight: 400;
  line-height: 10px;
  font-size: 10px;
  letter-spacing: 2;
`

export type UseType = 'uploaded-list' | 'data-built-list'
