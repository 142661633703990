import { ConfirmDialog, useScreenSize } from '@avenue-8/platform-style-util-frontend'
import DeleteIcon from '@mui/icons-material/Delete'
import CopyIcon from '@mui/icons-material/ContentCopy'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import FileCopy from '@mui/icons-material/FileCopy'
import Grid from '@mui/material/Grid'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import { MouseEvent, useState, useContext, useMemo, useCallback, useEffect } from 'react'
import { ProjectsContext } from '../contexts/projects.context'
import { DesignHuddleCategoriesContext } from '../../shared/contexts/design-huddle-categories.context'

export type PropsType = {
  projectId: string
  categoryId: number
  templateTitle: string
}

export default function ProjectCardMoreActionsMenu(props: PropsType): JSX.Element {
  const { projectId, categoryId, templateTitle } = props
  const { isDesktop } = useScreenSize()
  const { deleteProject, cloneProject, openSaveAsTemplateDialog, closeSaveAsTemplateDialog } =
    useContext(ProjectsContext)
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null)
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState<boolean>(false)
  const open = useMemo(() => anchorEl !== null, [anchorEl])
  const { assetTypesDict } = useContext(DesignHuddleCategoriesContext)
  const [selectedAssetType, setSelectedAssetType] = useState<string>('')

  const handleClose = useCallback(() => {
    setShowDeleteConfirmationDialog(false)
    setAnchorEl(null)
  }, [setShowDeleteConfirmationDialog, anchorEl])

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
      event.preventDefault()
    },
    [setAnchorEl]
  )

  const handleConfirmDelete = useCallback(() => {
    setShowDeleteConfirmationDialog(true)
  }, [setShowDeleteConfirmationDialog])

  const handleDelete = useCallback(() => {
    setAnchorEl(null)
    deleteProject(projectId).catch((error) => {
      console.error('Failed to delete project', projectId, error)
      window.alert(`Failed to delete the project: ${error?.message || error}`)
    })
  }, [setAnchorEl, deleteProject])

  const handleClone = () => {
    handleClose()
    cloneProject(projectId).catch((error) => {
      console.error('Failed to clone project', projectId, error)
      window.alert(`Failed to clone the project: ${error?.message || error}`)
    })
  }

  const handleConfirmSaveAsTemplate = useCallback(() => {
    handleClose()
    setAnchorEl(null)
    openSaveAsTemplateDialog(projectId, templateTitle, categoryId, selectedAssetType)
  }, [openSaveAsTemplateDialog, selectedAssetType])

  useEffect(() => {
    if (!assetTypesDict) return
    for (const assetType in assetTypesDict) {
      if (assetTypesDict[assetType].listing === categoryId || assetTypesDict[assetType].general === categoryId) {
        setSelectedAssetType(assetType)
        return
      }
    }
  }, [assetTypesDict, categoryId, setSelectedAssetType, selectedAssetType])

  const cloneItem = useMemo(
    () => (
      <MenuItem onClick={handleClone} data-meta-action='clone-project' data-meta-id={projectId}>
        <ListItemIcon>
          <CopyIcon fontSize='small' />
        </ListItemIcon>
        <ListItemText>Duplicate</ListItemText>
      </MenuItem>
    ),
    [handleClone]
  )

  const deleteItem = useMemo(
    () => (
      <MenuItem onClick={handleConfirmDelete} data-meta-action='show-delete-project' data-meta-id={projectId}>
        <ListItemIcon>
          <DeleteIcon fontSize='small' />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
    ),
    [handleConfirmDelete]
  )

  const saveAsTemplateItem = useMemo(
    () => (
      <MenuItem onClick={handleConfirmSaveAsTemplate} data-meta-action='save-as-template' data-meta-id={projectId}>
        <ListItemIcon>
          <FileCopy fontSize='small' />
        </ListItemIcon>
        <ListItemText>Save As Template</ListItemText>
      </MenuItem>
    ),
    [handleConfirmSaveAsTemplate]
  )

  const confirmDialog = useMemo(
    () => (
      <ConfirmDialog
        open={showDeleteConfirmationDialog}
        onConfirm={handleDelete}
        onCancel={handleClose}
        dialogTitle={'Delete this project?'}
        confirmLabel={'Delete'}
        cancelLabel={'Keep it'}
        data-meta-id={projectId}
        data-meta-name='delete-project'
      >
        <Typography variant={'body2'}>
          Are you sure you want to delete this project? This action is irreversible.
        </Typography>
      </ConfirmDialog>
    ),
    [showDeleteConfirmationDialog, handleDelete, handleClose]
  )

  return (
    <>
      {confirmDialog}
      <Grid>
        <IconButton
          onClick={handleClick}
          sx={{ padding: isDesktop ? 1 : 0 }}
          aria-label='settings'
          data-meta-action='more-actions'
        >
          <MoreVertIcon />
        </IconButton>
      </Grid>
      <Grid>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {saveAsTemplateItem}
          {cloneItem}
          {deleteItem}
        </Menu>
      </Grid>
    </>
  )
}
