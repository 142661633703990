import { WebviewBridgeProvider } from '@avenue-8/platform-shared-util-frontend'
import { DesignHuddleCategoriesProvider } from '../shared/contexts/design-huddle-categories.context'
import { ProjectsProvider } from './contexts/projects.context'
import { ProjectsPage } from './pages/projects.page'

export const ProjectsModule = (): JSX.Element => {
  return (
    <WebviewBridgeProvider>
      <ProjectsProvider>
        <DesignHuddleCategoriesProvider>
          <ProjectsPage />
        </DesignHuddleCategoriesProvider>
      </ProjectsProvider>
    </WebviewBridgeProvider>
  )
}
