import { formatBathCount, formatBedCount, generateComboLine, toMoney, formatSquareFoot } from '../utils/formatters'
import { AgentListingDto } from './agent-listing.dto'
import { DesignHuddleTemplateCustomizationsObject } from './design-huddle-template-customizations-object'

const BLACK_LIST_IMAGES_URLS = ['matterport.com', 'youtu.be', 'youtube.com', 'vimeo.com', 'giphy.com', 'tenor.com']

export default class ListingTcoAdapter {
  static transform(listing: AgentListingDto, useMLSImages = false): DesignHuddleTemplateCustomizationsObject {
    let imagesArray = {}

    if (useMLSImages) {
      const images = listing?.photos ?? []
      imagesArray = {
        ...images
          .filter((img) => !BLACK_LIST_IMAGES_URLS.find((url) => img.photoUrl.includes(url)))
          .reduce(
            (curr, next, index) => ({
              ...curr,
              [`image${index + 1}`]: {
                url: next.photoUrl,
                masked_media: { url: next.photoUrl },
              },
            }),
            {}
          ),
      }
    }

    return {
      classes: {
        ...imagesArray,
        beds: {
          text: formatBedCount(listing?.numBedrooms),
        },
        baths: {
          text: formatBathCount(listing?.numBathrooms),
        },
        price: {
          text: toMoney(listing?.currentPrice),
        },
        comboBedsBaths: {
          text: generateComboLine([
            [
              listing?.numBedrooms > 0 ? listing?.numBedrooms + 'BD' : '',
              listing?.numBathrooms > 0 ? listing?.numBathrooms + 'BA' : '',
              ' | ',
            ],
          ]),
        },
        fullAddress: {
          text: [listing.addressLine1, listing.addressLine2, listing.city, listing.state, listing.zipCode]
            .filter((c) => c)
            .join(' '),
        },
        firstAddressLine: {
          text: listing.addressLine1,
        },
        secondAddressLine: {
          text: listing.addressLine2,
        },
        city: {
          text: listing.city,
        },
        state: {
          text: listing.state,
        },
        zipCode: {
          text: listing.zipCode,
        },
        sqft: {
          text: formatSquareFoot(listing.lotSize),
        },
        property_description: {
          text: listing.description,
        },
      },
    }
  }
}
