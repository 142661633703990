import { useEffect, useRef } from 'react'

type UseIntervalProps = {
  callback: () => void
  delay: number | null // in ms
}

export default function useInterval({ callback, delay }: UseIntervalProps): void {
  const savedCallback = useRef<any>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback?.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
