import './env-setup' // Must be first import
import {
  AuthProvider,
  FeatureFlagsProvider,
  BillingServiceProvider,
  UserDataProvider,
  ListingsContextProvider,
  DH_PROXY_API_URL,
  initDatadogRum,
} from '@avenue-8/platform-shared-util-frontend'
import { theme } from '@avenue-8/platform-style-util-frontend'
import { ThemeProvider } from '@mui/material'
import { DesignHuddleContextProvider } from './modules/shared/contexts/design-huddle-sdk.context'
import { DesignHuddleProxyContextProvider } from './modules/shared/contexts/design-huddle-proxy.context'
import { ServicesProvider } from './modules/shared/contexts/services.context'
import { PamEditDigitalModule } from './pam-edit-digital.module'
import { ErrorBoundaryProvider } from './modules/shared/components/error-boundary-provider'
import packageJson from '../package.json'
import 'swiper/swiper.min.css'

initDatadogRum({ service: packageJson.name, version: '' })

export default function Root(_props: { name?: string }): JSX.Element {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <ErrorBoundaryProvider>
          <UserDataProvider>
            <FeatureFlagsProvider>
              <ServicesProvider>
                <BillingServiceProvider>
                  <DesignHuddleContextProvider>
                    <DesignHuddleProxyContextProvider baseUrl={DH_PROXY_API_URL}>
                      <ListingsContextProvider>
                        <PamEditDigitalModule />
                      </ListingsContextProvider>
                    </DesignHuddleProxyContextProvider>
                  </DesignHuddleContextProvider>
                </BillingServiceProvider>
              </ServicesProvider>
            </FeatureFlagsProvider>
          </UserDataProvider>
        </ErrorBoundaryProvider>
      </ThemeProvider>
    </AuthProvider>
  )
}
