export const ComputerIcon = (): JSX.Element => (
  <svg width='149' height='177' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M131.87 37.96c9.04 0 16.38 7.34 16.38 16.39v79.83c0 9.24-7.5 16.73-16.73 16.73H17.64C8.39 150.91.9 143.42.9 134.18V54.7c0-9.25 7.5-16.74 16.74-16.74h15.92'
      stroke='#2A29A6'
      strokeWidth='.88'
    />
    <rect x='42.01' y='.52' width='81.63' height='53.74' rx='7.23' stroke='#2A29A6' strokeWidth='.88' />
    <path
      d='m43.55 3.33-.25.36.03.02.08.06a1090.73 1090.73 0 0 0 5.96 4.08 17788.2 17788.2 0 0 0 26.78 18.3 1059.64 1059.64 0 0 0 6.24 4.18.54.54 0 0 0 .09.04l.11.01v-.88a.8.8 0 0 1 .16.03A18409.26 18409.26 0 0 1 45.47 4.11l-1.24-.84-.32-.22-.08-.06-.03-.01-.25.35ZM82.6 30.38a.78.78 0 0 0 .16-.03.55.55 0 0 0 .1-.05l.1-.06.35-.23 1.27-.85c1.09-.72 2.62-1.76 4.46-3.01a6268.61 6268.61 0 0 0 26.78-18.3l4.4-3.01a2174.4 2174.4 0 0 0 1.56-1.07l.08-.06.02-.01-.24-.37-.25-.36-.03.02a5.88 5.88 0 0 1-.4.28 2132.33 2132.33 0 0 1-5.64 3.86 17399.06 17399.06 0 0 1-26.77 18.3 1059.53 1059.53 0 0 1-6.14 4.11.3.3 0 0 1 .07-.02.8.8 0 0 1 .11-.02v.88Z'
      fill='#2A29A6'
    />
    <path stroke='#2A29A6' strokeWidth='.88' d='M.9 131.65h147.35' />
    <path
      d='m68.88 91.27.43-.08-.07-.36h-.36v.44ZM41.81 131.6l-.18-.4.18.84v-.44Zm26.96 0v.44h.09l.08-.04-.17-.4Zm27.42-40.33v-.44h-.51l.08.51.43-.07Zm10.23 0v.44h.09l.08-.04-.17-.4Zm1.97-5.23-.38.22.38-.22Zm-39.94 5.32c1.67 8.26-1.45 28.67-26.82 39.84l.35.8c25.77-11.34 29.07-32.18 27.33-40.81l-.86.17Zm-26.64 40.67h26.96v-.87H41.8v.88Zm27.13-.03c5.28-2.29 13.01-7.25 19.01-14.24 6.01-7 10.33-16.07 8.67-26.55l-.86.13c1.6 10.15-2.57 18.97-8.47 25.85a55.58 55.58 0 0 1-18.7 14l.35.8ZM96.2 91.7h10.23v-.88H96.19v.88Zm10.4-.04a5.16 5.16 0 0 0 2.28-1.96c.6-1.02.77-2.35-.1-3.88l-.76.43c.71 1.26.55 2.25.1 3a4.29 4.29 0 0 1-1.87 1.61l.35.8Zm2.18-5.84a11.9 11.9 0 0 0-1.61-1.97 65.75 65.75 0 0 0-2.9-2.82c-2.27-2.1-5.14-4.57-8-6.93-2.88-2.35-5.77-4.6-8.09-6.27a44.73 44.73 0 0 0-2.99-2c-.4-.24-.76-.44-1.07-.57a2.17 2.17 0 0 0-.82-.23v.87c.05 0 .19.03.47.16.25.11.58.29.97.52.78.47 1.78 1.14 2.93 1.97a166.7 166.7 0 0 1 8.04 6.23c2.86 2.35 5.71 4.8 7.97 6.9a65.15 65.15 0 0 1 2.86 2.77c.76.8 1.26 1.42 1.48 1.8l.76-.43ZM83.3 65.03c-.13 0-.27.05-.38.09a13.6 13.6 0 0 0-1.48.8c-.84.53-1.92 1.28-3.17 2.19-2.48 1.8-5.63 4.23-8.74 6.7-3.1 2.47-6.17 5-8.5 7-1.16 1-2.14 1.89-2.85 2.57a17.13 17.13 0 0 0-1.14 1.2c-.07.1-.14.2-.18.32l.82.3s0-.02.07-.1l.22-.26c.19-.22.47-.5.81-.83.7-.67 1.67-1.53 2.83-2.53 2.32-2 5.37-4.52 8.47-6.99 3.1-2.46 6.23-4.88 8.71-6.67 1.24-.9 2.3-1.64 3.12-2.16a12.77 12.77 0 0 1 1.3-.72l.1-.03v-.87ZM56.85 85.9c-.32.89-.44 2.29-.11 3.49.16.6.46 1.18.93 1.62.48.44 1.12.7 1.92.7v-.88c-.6 0-1.02-.18-1.33-.47-.31-.29-.54-.7-.68-1.2a5.4 5.4 0 0 1 .1-2.97l-.83-.3Zm2.74 5.81H68.88v-.88H59.58v.88Z'
      fill='#2A29A6'
    />
    <circle cx='74.93' cy='140.11' r='2.7' stroke='#2A29A6' strokeWidth='.88' />
    <path d='m57.17 166.95 1.76-16.09H90.7l2.2 16.09H57.18Z' stroke='#2A29A6' strokeWidth='.88' />
    <path
      d='M46.92 173.87h.44v-.02l-.44.02Zm56.01 0-.44.04.44-.04Zm-53.92 1.97-.18.4.09.04h.1v-.44Zm-1.65-2c-.03-.49.17-1.13.56-1.87a13.37 13.37 0 0 1 3.28-3.94c.28-.22.53-.4.75-.52.23-.12.38-.17.47-.18l-.08-.87c-.26.02-.54.14-.81.29-.28.15-.57.36-.88.6a14.22 14.22 0 0 0-3.51 4.21c-.41.79-.7 1.6-.66 2.33l.88-.04Zm5.06-6.5c.32-.03 3.21-.05 7.43-.05h15.12l22.15.05v-.88l-22.15-.05H59.84c-4.17 0-7.12.02-7.5.05l.08.87Zm44.7 0 .04.01.22.11c.18.1.42.27.7.48a16.86 16.86 0 0 1 3.59 3.9c.47.76.77 1.47.82 2.07l.88-.08a5.77 5.77 0 0 0-.96-2.46 17.68 17.68 0 0 0-3.8-4.12 7.5 7.5 0 0 0-1.13-.71.99.99 0 0 0-.36-.08v.88Zm5.37 6.57c.03.35-.26.72-.83 1.05a4.38 4.38 0 0 1-1.06.43l-.11.02v.87c.1 0 .2-.02.3-.04l.38-.1c.28-.1.61-.24.93-.42.6-.35 1.34-.97 1.27-1.89l-.88.08Zm-53.3 1.53a5.91 5.91 0 0 1-1.21-.73c-.2-.16-.36-.32-.47-.47-.12-.16-.15-.28-.15-.37h-.88c0 .33.14.63.31.87.17.25.4.47.64.66.47.38 1.03.68 1.4.85l.36-.8Zm-.18.84h51.48v-.87H49.01v.87Z'
      fill='#2A29A6'
    />
  </svg>
)
