import CheckIcon from '@mui/icons-material/Check'
import Typography from '@mui/material/Typography'
import { ImageOverlay } from './image-overlay'

export type SelectableListingPhotoProps = {
  isSelected: boolean
  selectedIdx: number
  showCheckOnly?: boolean
}

export const SelectableListingPhoto = (props: SelectableListingPhotoProps): JSX.Element => {
  const { isSelected, selectedIdx, showCheckOnly } = props
  if (!isSelected) {
    return null
  }
  return (
    <ImageOverlay>
      {showCheckOnly ? (
        <CheckIcon style={{ color: 'white' }} />
      ) : (
        <Typography sx={{ color: 'white', fontWeight: 600 }}>{selectedIdx + 1}</Typography>
      )}
    </ImageOverlay>
  )
}
