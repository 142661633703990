import { Route, Switch, useRouteMatch } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { EditorModule } from './modules/editor/editor.module'
import { GalleryModule } from './modules/gallery/gallery.module'
import { ProjectsModule } from './modules/projects/projects.module'

const EditSubmodule = (): JSX.Element => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/gallery/:templateType?`}>
        <GalleryModule />
      </Route>
      <Route path={`${path}/project`}>
        <EditorModule />
      </Route>
    </Switch>
  )
}

export const PamEditDigitalModule = (): JSX.Element => {
  return (
    <>
      <BrowserRouter basename='/account/marketing'>
        <Route path='/projects'>
          <ProjectsModule />
        </Route>
        <Route path='/edit'>
          <EditSubmodule />
        </Route>
      </BrowserRouter>
    </>
  )
}
