import { capitalize, Grid, Stack, Typography } from '@mui/material'

const { format: formatNumber } = new Intl.NumberFormat('en-us', {
  style: 'decimal',
  maximumFractionDigits: 2,
})

const { format: bigNumberFormatter } = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  compactDisplay: 'short',
  notation: 'compact',
  maximumFractionDigits: 2,
})

const estimatePropsDict = {
  spend: {
    show: false,
  },
  actions: {
    order: 3,
  },
  reach: {
    formatter: bigNumberFormatter,
    order: 2,
  },
  impressions: {
    formatter: bigNumberFormatter,
    order: 1,
  },
}

export type FacebookEstimatesProps = {
  values: { [k: string]: number }
}

export const FacebookEstimates = (props: FacebookEstimatesProps): JSX.Element => {
  return (
    <Grid
      container
      flexDirection='row'
      flexWrap='nowrap'
      p={2}
      spacing={4}
      justifyContent={'space-around'}
      sx={{ backgroundColor: 'secondary' }}
    >
      {Object.entries(props.values)
        .filter(([name]) => estimatePropsDict[name]?.show !== false)
        .sort(([keyA], [keyB]) => {
          const { order: a } = estimatePropsDict[keyA] ?? {}
          const { order: b } = estimatePropsDict[keyB] ?? {}
          return isNaN(a - b) ? keyA.localeCompare(keyB, 'en', { numeric: true }) : a - b
        })
        .map(([name, value], idx) => {
          const { formatter = formatNumber, label = capitalize(name) } = estimatePropsDict[name] ?? {}
          const formattedValue = formatter(value)

          return (
            <Grid item key={`${idx}-${name}`}>
              <Stack spacing={2}>
                <Typography variant='button' fontWeight={400} fontSize='12px' letterSpacing='2px'>
                  {label}
                </Typography>
                <Typography variant='body2' fontSize='14px' fontWeight={700} color='#73738C' textAlign='center'>
                  {formattedValue}
                </Typography>
              </Stack>
            </Grid>
          )
        })}
    </Grid>
  )
}
