/* eslint-disable camelcase */
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { DesignHuddleProxyContext } from '../contexts/design-huddle-proxy.context'
import { DesignHuddleTemplateCustomizationsObject } from '../domain/design-huddle-template-customizations-object'

export default function useDHProjectId({
  tco = {},
}: {
  tco?: DesignHuddleTemplateCustomizationsObject | Record<string, never>
}): {
  categoryId: string
  projectId: string
  error: boolean
  loading: boolean
} {
  const [projectId, setProjectId] = useState<string>()
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const { createProject } = useContext(DesignHuddleProxyContext)
  const { templateId, categoryId } = useParams<any>()

  useEffect(() => {
    const createProjectAndStartEditing = () => {
      if (createProject) {
        createProject(templateId, categoryId, tco)
          .then((data) => {
            if (data?.projectId) {
              setProjectId(data.projectId)
              setLoading(false)
            } else {
              throw new Error('Failed to create DH project')
            }
          })
          .catch((error) => {
            console.error(error.message, error)
            setError(true)
          })
      }
    }
    if (createProject && templateId) {
      createProjectAndStartEditing()
    }
  }, [tco, createProject, templateId, categoryId])

  return { categoryId, projectId, error, loading }
}
