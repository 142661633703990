import { MouseEvent, useCallback, useMemo, useState, useContext } from 'react'
import { Grid, Skeleton, Typography, Box } from '@mui/material'
import {
  SectionContainer,
  PaginationButton,
  Tooltip,
  useScreenSize,
  SwiperComponent,
  SwiperSlideComponent,
  SwiperCore,
  theme,
} from '@avenue-8/platform-style-util-frontend'
import ProjectCard from './project-card'
import { DesignHuddleProxyProject } from '../../shared/domain/design-huddle-proxy-project.type'

type ProjectsRowType = {
  title: string
  projects: DesignHuddleProxyProject[]
  loading: boolean
  onProjectClick?: (event: MouseEvent, project: DesignHuddleProxyProject) => void
}

export default function ProjectsRow({ title, projects = [], loading, onProjectClick }: ProjectsRowType): JSX.Element {
  const { isSmallScreen, isMobile } = useScreenSize()
  const [navigationInformation, setNavigationInformation] = useState<null | {
    currentSlide: number
    slidesPerView: number
    totalSnaps: number
    isPreviousDisabled: boolean
    isNextDisabled: boolean
  }>(null)

  const swiperBreakpoints = useMemo((): Record<
    number, // breakpoints in px, value >= screen width applies
    { slidesPerView: number; slidesPerGroup: number; speed?: number }
  > => {
    const breakpoints = theme.breakpoints.values
    const baseBreakpoints = {
      [breakpoints.xs]: { slides: 2 },
      // [breakpoints.sm]: { slides: 2 },
      // [breakpoints.md]: { slides: 2 },
      [breakpoints.lg]: { slides: 3 },
      [breakpoints.xl]: { slides: 4, speed: 600 },
    }
    return Object.fromEntries(
      Object.entries(baseBreakpoints).map(([size, { slides, ...extraData }]) => {
        const slidesLimit = Math.min(slides, projects.length || 1)
        const breakpoint = { slidesPerView: slidesLimit, slidesPerGroup: slidesLimit, ...extraData }
        return [size, breakpoint]
      })
    )
  }, [projects.length])

  const setUpNavigationInformation = useCallback(
    (swiper: SwiperCore & { snapIndex: number }) => {
      const { currentBreakpoint } = swiper
      const { slidesPerView } = swiperBreakpoints[currentBreakpoint]
      const navInfo = {
        isPreviousDisabled: swiper.isBeginning,
        isNextDisabled: swiper.isEnd,
        currentSlide: Number.isNaN(swiper.snapIndex) ? 1 : (swiper.snapIndex ?? 0) + 1,
        slidesPerView: slidesPerView > projects.length ? projects.length : slidesPerView,
        totalSnaps: Math.ceil(projects.length / slidesPerView),
      }
      setNavigationInformation(navInfo)
    },
    [projects.length, swiperBreakpoints]
  )

  const swiperOptions = useMemo(
    () => ({
      centerInsufficientSlides: false,
      onInit: setUpNavigationInformation,
      onBreakpoint: setUpNavigationInformation,
      breakpoints: swiperBreakpoints,
      onSnapIndexChange: (swiper: SwiperCore & { snapIndex: number }) => {
        setNavigationInformation((prevNavigationInformation) => {
          return {
            ...prevNavigationInformation,
            isPreviousDisabled: swiper.isBeginning,
            isNextDisabled: swiper.isEnd,
            currentSlide: swiper.snapIndex + 1,
          }
        })
      },
      navigation: {
        prevEl: `.previous`,
        nextEl: `.next`,
      },
      threshold: 5,
      spaceBetween: 16,
    }),
    [setUpNavigationInformation, swiperBreakpoints]
  )

  return (
    <SectionContainer
      title={title}
      tooltip={
        <Tooltip
          title='Don’t see the asset you’re looking for?'
          description='At this time, we are only supporting these assets on desktop devices. Login on desktop to edit and request our full suite of assets.'
        />
      }
      rightActionButton={
        !isSmallScreen && projects.length ? (
          <PaginationButton
            backButtonProps={{
              className: `previous`,
            }}
            nextButtonProps={{
              className: `next`,
            }}
            backDisabled={navigationInformation?.isPreviousDisabled}
            nextDisabled={navigationInformation?.isNextDisabled}
            middleChildren={
              <Typography variant='body1' fontWeight='normal'>
                {navigationInformation?.currentSlide}/{navigationInformation?.totalSnaps || 1}
              </Typography>
            }
          />
        ) : (
          <></>
        )
      }
    >
      {loading ? (
        <Skeleton variant='rectangular' width='100%' height='400px' />
      ) : (
        <>
          {isSmallScreen ? (
            <Grid container>
              {/* <Box>
                <StartNewProjectCard
                  onClick={() => {
                    PubSubBus.publish('callNewAssetModal', '')
                  }}
                />
              </Box> */}
              {projects.map((project, index) => {
                const isOdd = index % 2 === 1
                const { projectId, title, thumbnailURL, dateUpdated, category } = project
                const alt = `Project ${title} thumbnail`
                const href = `/account/marketing/edit/project/${project.projectId}`

                return (
                  <Box key={projectId} sx={{ mr: isOdd && isMobile ? 0 : isOdd && !isMobile ? '13.5%' : 2, mb: 3 }}>
                    <ProjectCard
                      projectId={projectId}
                      projectTitle={title}
                      thumbnailUrl={thumbnailURL}
                      dateUpdated={dateUpdated}
                      alt={alt}
                      href={href}
                      onClick={(event) => {
                        onProjectClick?.(event, project)
                      }}
                      categoryId={category.id}
                    />
                  </Box>
                )
              })}
            </Grid>
          ) : (
            <Grid
              container
              sx={{
                mt: { xs: 1, md: 3 },
                maxWidth: !isSmallScreen ? 'calc(100vw - 184px) !important;' : 'initial',
              }}
            >
              {/* <Grid item xs={6} sm={6} md={6} lg={4} xl={3} sx={{ display: { xs: 'none', md: 'block' } }}>
                <StartNewProjectCard
                  onClick={() => {
                    PubSubBus.publish('callNewAssetModal', '')
                  }}
                />
              </Grid> */}
              <SwiperComponent swiperOptions={swiperOptions}>
                <div>
                  {projects.map((project) => {
                    const { projectId, title, thumbnailURL, dateUpdated, category } = project
                    const alt = `Project "${title}" preview thumbnail`
                    const href = `/account/marketing/edit/project/${project.projectId}`

                    return (
                      <SwiperSlideComponent key={projectId}>
                        <Grid item xs={6} sm={6} md={6} lg={4} xl={3} sx={{ mb: 4 }}>
                          <ProjectCard
                            projectId={projectId}
                            projectTitle={title}
                            thumbnailUrl={thumbnailURL}
                            dateUpdated={dateUpdated}
                            categoryId={category.id}
                            alt={alt}
                            href={href}
                            onClick={(event) => {
                              onProjectClick?.(event, project)
                            }}
                          />
                        </Grid>
                      </SwiperSlideComponent>
                    )
                  })}
                </div>
              </SwiperComponent>
            </Grid>
          )}
        </>
      )}
    </SectionContainer>
  )
}
