import { Slide } from '@mui/material'
import { EditorTemplatesSection } from '../editor-templates-section'
import { MobileBottomBarModalProps } from './mobile-editor-bottom-bar'
import { MobileModalContentWrapper } from '../../modal/mobile-modal-content-wrapper'
import { ModalTitle } from '../../../../shared/components/modal-title'
import { StyledBackdrop } from '../../../../shared/components/styled-backdrop'
import { StyledModal } from '../../styled-modal'

export const MobileEditorTemplatesDrawer = ({ open, onClose }: MobileBottomBarModalProps): JSX.Element => {
  return (
    <>
      <StyledModal
        aria-labelledby='editor-switch-listing-modal'
        aria-describedby='editor-switch-listing-modal-content'
        open={open}
        BackdropComponent={StyledBackdrop}
      >
        <Slide in={open} direction={'up'} timeout={{ enter: 200, exit: 200 }} style={{ paddingTop: '75vh' }}>
          <div style={{ height: '100vh', overflowY: 'scroll', width: '100%' }}>
            <MobileModalContentWrapper
              style={{ minHeight: '25vh', height: 'auto', overflowY: 'unset', paddingBottom: '50vh' }}
            >
              <ModalTitle onClose={onClose}>TEMPLATE</ModalTitle>
              <EditorTemplatesSection onClose={onClose} />
            </MobileModalContentWrapper>
          </div>
        </Slide>
      </StyledModal>
    </>
  )
}
