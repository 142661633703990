import {
  WebviewBridgeProvider,
  AuthProvider,
  FeatureFlagsProvider,
  ErrorBoundary,
} from '@avenue-8/platform-shared-util-frontend'
import { theme } from '@avenue-8/platform-style-util-frontend'
import { ThemeProvider } from '@emotion/react'
import { Route } from 'react-router'
import { BrowserRouter, Switch } from 'react-router-dom'
import CreateNewProject from '../projects/components/create-new-project'
import { DesignHuddleCategoriesProvider } from '../shared/contexts/design-huddle-categories.context'
import { VisualMarketingServiceProvider } from '../shared/services/visual-marketing/visual-marketing.service.context'
import { BackendAppServiceProvider } from '../shared/services/backend-app/backend-app.service.context'
import { EditorPage } from './components/editor.page'
import { ViewportReloader } from './components/ViewportReloader'
import { EditorProvider } from './contexts/editor.context'
import { CheckAuth } from './utils/check-auth'
import { FinalizePage } from './components/finalize/finalize-page'
import { Typography } from '@mui/material'

const reloadKey = 'pam2-editor-reloaded'

const tryToReload = () => {
  try {
    const reloaded: string = sessionStorage.getItem(reloadKey)
    const times: number = Number.isNaN(+reloaded) ? 99 : +reloaded
    if (times < 5) {
      window.location.reload()
    } else {
      throw new Error('Failed to try to recover from editor crash')
    }
  } catch (error) {
    /* eslint-disable no-debugger, no-console */
    console.log('error on try to try reload', error)
    window.location.href = '/account'
  }
}

export const EditorModule = (): JSX.Element => {
  return (
    <AuthProvider>
      <CheckAuth />
      <FeatureFlagsProvider>
        <DesignHuddleCategoriesProvider>
          <VisualMarketingServiceProvider>
            <BackendAppServiceProvider>
              <ThemeProvider theme={theme}>
                <BrowserRouter basename='/account/marketing/edit/project'>
                  <Switch>
                    <Route path='/new/:templateId/:categoryId'>
                      <CreateNewProject />
                    </Route>
                    <Route exact path='/:projectId'>
                      <ErrorBoundary onError={tryToReload} fallback={<Typography>Something went wrong.</Typography>}>
                        <WebviewBridgeProvider>
                          <ViewportReloader>
                            <EditorProvider>
                              <EditorPage />
                            </EditorProvider>
                          </ViewportReloader>
                        </WebviewBridgeProvider>
                      </ErrorBoundary>
                    </Route>
                    <Route path='/:projectId/finalize'>
                      <WebviewBridgeProvider>
                        <FinalizePage />
                      </WebviewBridgeProvider>
                    </Route>
                  </Switch>
                </BrowserRouter>
              </ThemeProvider>
            </BackendAppServiceProvider>
          </VisualMarketingServiceProvider>
        </DesignHuddleCategoriesProvider>
      </FeatureFlagsProvider>
    </AuthProvider>
  )
}
