import Search from '@mui/icons-material/Search'
import { useContext, useEffect, useState } from 'react'
import { debounce } from '@mui/material'
import { ListingsService } from '@avenue-8/platform-shared-util-frontend'
import { ServicesContext } from '../../shared/contexts/services.context'
import { AgentListingDto } from '../../shared/domain/agent-listing.dto'
import { AutoCompleteInput } from './auto-complete-input'

const ONE_SEC = 1000

const debouncedRequest = debounce(
  (
    service: ListingsService,
    inputValue: string,
    setLoading: (state: boolean) => void,
    setListingsFound: (items: AgentListingDto[]) => void
  ) => {
    setLoading(true)
    service
      .findByText(inputValue)
      .then((request) => {
        if (!request.success) {
          throw new Error('Request failed')
        }
        setListingsFound(request.data)
        return request.data
      })
      .catch((error) => {
        console.error('Failed to search listings by text:', error)
        setListingsFound([])
        return [] as AgentListingDto[]
      })
      .then(() => {
        setLoading(false)
      })
  },
  ONE_SEC / 2
)

export type ListingsAutoCompleteInputProps = {
  label?: string
  selectedListing: AgentListingDto | null
  onSelectedListingChange: (listing: AgentListingDto | null) => void
  disabled?: boolean
}

export const ListingsAutoCompleteInput = (props: ListingsAutoCompleteInputProps): JSX.Element => {
  const { label = 'Search for a listing', onSelectedListingChange, selectedListing, disabled } = props
  const { listings: listingsService } = useContext(ServicesContext)
  const [inputValue, setInputValue] = useState<string>('')
  const [listingsFound, setListingsFound] = useState<AgentListingDto[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  useEffect(() => {
    if (listingsService) {
      debouncedRequest(listingsService, inputValue, setIsLoading, setListingsFound)
    }
  }, [inputValue, listingsService])
  return (
    <AutoCompleteInput
      forcePopupIcon
      doNotRotatePopupIcon
      popupIcon={<Search />}
      label={label}
      labelOption={({ addressLine1, addressLine2 = '', city, state, zipCode: zip }: AgentListingDto) =>
        `${addressLine1} ${addressLine2} ${zip} ${city} ${state}`.replace(/\s+/gm, ' ').trim()
      }
      value={selectedListing}
      onChange={onSelectedListingChange}
      inputValue={inputValue}
      onInputValueChange={setInputValue}
      options={listingsFound}
      loading={isLoading}
      disabled={disabled}
    />
  )
}
