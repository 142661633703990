import { createContext, ReactNode, useEffect, useState } from 'react'
import { useAuthContext, ListingsServiceFactory, ListingsService } from '@avenue-8/platform-shared-util-frontend'

export type ServicesContextValue = {
  listings: ListingsService
}

export const ServicesContext = createContext<ServicesContextValue | Record<string, never>>({})

export const ServicesProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { token, agentId } = useAuthContext()
  const [value, setValue] = useState<ServicesContextValue | Record<string, never>>({})
  useEffect(() => {
    if (token && agentId) {
      setValue({
        listings: ListingsServiceFactory(token, agentId),
      })
    }
  }, [agentId, token])
  return <ServicesContext.Provider value={value}>{children}</ServicesContext.Provider>
}
