import { useFeatureFlags } from '@avenue-8/platform-shared-util-frontend'
import { createContext, ReactNode, useEffect, useMemo, useState } from 'react'
export type AssetType = 'social-media' | 'display-ads' | 'flyer' | 'postcard' | 'brochure'

export type CategoryType = 'listing' | 'general'

export type AssetTypeCategory = Record<CategoryType, number> & { label: string; hasPaid?: boolean; nextCTA?: string }

export type CategoryDetails = {
  assetType: AssetType
  categoryType: 'listing' | 'general'
  nextCTA?: string
  hasPaid?: boolean
}

const generateCategoriesMap = (assetTypesDict: Record<AssetType, AssetTypeCategory>) => {
  const dict = new Map<number, CategoryDetails>()
  Object.entries(assetTypesDict).forEach(
    ([assetType, { listing, general, ...details }]: [AssetType, AssetTypeCategory]) => {
      dict.set(listing, { assetType, categoryType: 'listing', ...details })
      dict.set(general, { assetType, categoryType: 'general', ...details })
    }
  )
  return dict
}

const isValidCategoriesDict = (obj: Record<string, any>): obj is Record<AssetType, AssetTypeCategory> => {
  const isAnObject = typeof obj === 'object'
  return (
    isAnObject &&
    Object.entries(obj).every(([key, value]) => {
      const haveValidKey = typeof key === 'string'
      const valueFields = Object.keys(value)
      const haveValueFields = valueFields.every((fieldValue) => {
        const isString = typeof fieldValue === 'string'
        const isNumber = typeof fieldValue === 'number' && !Number.isNaN(+fieldValue)
        return isString || isNumber
      })
      return haveValidKey && haveValueFields
    })
  )
}

export type DesignHuddleCategoriesContextValue =
  | {
      categoriesDict: Map<number, CategoryDetails>
      assetTypesDict: Record<AssetType, AssetTypeCategory> | Record<string, never>
    }
  | Record<string, never>

export const DesignHuddleCategoriesContext = createContext({
  assetTypesDict: null,
  categoriesDict: null,
})

export const DesignHuddleCategoriesProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { getFeatureData, addFeaturesListener, removeFeaturesListener } = useFeatureFlags()
  const [assetTypesDict, setAssetTypesDict] = useState<
    Readonly<Record<AssetType, AssetTypeCategory>> | Record<string, never>
  >({})

  useEffect(() => {
    const listener = async (_flags: any[] = []) => {
      const categories = await getFeatureData('marketing-asset-type-categories')
      if (!categories) return
      if (typeof categories !== 'object' || !isValidCategoriesDict(categories)) {
        console.error('Invalid AssetTypes-Categories Map in Flagsmith! Will use the default fallback!')
        return
      }
      setAssetTypesDict((previousValue) => {
        if (JSON.stringify(previousValue) !== JSON.stringify(categories)) {
          return categories
        }
        return previousValue
      })
    }
    addFeaturesListener(listener)
    listener()
    return () => {
      removeFeaturesListener(listener)
    }
  }, [getFeatureData, addFeaturesListener, removeFeaturesListener, setAssetTypesDict])

  const value: DesignHuddleCategoriesContextValue = {
    assetTypesDict,
    categoriesDict: generateCategoriesMap(assetTypesDict),
  }
  return <DesignHuddleCategoriesContext.Provider value={value}>{children}</DesignHuddleCategoriesContext.Provider>
}
