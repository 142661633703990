/* eslint-disable camelcase */
import { MediaType } from '@avenue-8/platform-shared-util-frontend'
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { CategoryDetails, DesignHuddleCategoriesContext } from '../../shared/contexts/design-huddle-categories.context'
import { DesignHuddleProxyContext } from '../../shared/contexts/design-huddle-proxy.context'
import { DesignHuddleContext } from '../../shared/contexts/design-huddle-sdk.context'
import { DesignHuddleEditorType } from '../../shared/domain/design-huddle-editor.type'
import {
  CustomizationOptions,
  DesignHuddleTemplateCustomizationsObject,
} from '../../shared/domain/design-huddle-template-customizations-object'
import { ProjectDataType } from '../domain/project-data.type'
import { generateReplaceableElementsTCO } from '../utils/generate-tco'
import { getProjectElements } from '../utils/get-project-elements'
import { parseTcoFromTemplateElements } from '../utils/parse-tco-from-template-elements'
import { getLowResPagesRenders } from '../utils/get-low-res-pages-renders'

export const CATEGORY_ID_URL_SEARCH_KEY = 'parentCategoryId'

export type PageRender = { encodedImage: string; pageId: string; sceneId?: string }

export type Page = {
  pageId: string
  pageNumber: number
}

export type EditorElementExtraToolbarFeatures = {
  elementId: string | null
  element: CustomizationOptions | null
  features: {
    openaiContentGeneration: boolean
  }
}

export type EditorContextValue =
  | {
      title: string
      lastSaved: Date
      projectThumbnail: string
      showNextCTA: boolean
      nextCTAText?: string
      onTitleChange: (newTitle?: string) => void
      editorRef: DesignHuddleEditorType
      setEditorRef: (ref: DesignHuddleEditorType) => void
      exportModalOpen: boolean
      setExportModalOpen: (value: boolean) => void
      tcoHash: string
      setTcoHash: (hash: string) => void
      projectCategoryId: number | null
      projectTemplateId: number | null
      projectCategory: CategoryDetails | null
      projectMediaType: MediaType | null
      getLowResPagesRenders: (timeout?: number) => Promise<PageRender[]>
      replaceTemplate: (templateId: number) => Promise<string>
      updateElements: (
        elements: DesignHuddleTemplateCustomizationsObject,
        maxGetElementsAttempts?: number
      ) => Promise<void>
      fileName?: string
      pages: Page[]
      setEditorElementToolbarExtraFeatures: React.Dispatch<React.SetStateAction<EditorElementExtraToolbarFeatures>>
      editorElementToolbarExtraFeatures: EditorElementExtraToolbarFeatures
      resetEditorElementToolbarExtraFeaturesState: () => void
    }
  | Record<string, never>

export const EditorContext = createContext<EditorContextValue>({})

export const EditorProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const history = useHistory()
  const { projectId: projectIdInUrl } = useParams<{ projectId }>()
  const { dhSdk } = useContext(DesignHuddleContext)
  const { categoriesDict } = useContext(DesignHuddleCategoriesContext)
  const { getProjectData } = useContext(DesignHuddleProxyContext)
  const [title, setTitle] = useState<string>()
  const [projectId, setProjectId] = useState<string>(projectIdInUrl ?? null)
  const [projectThumbnail, setProjectThumbnail] = useState<string>('')
  const [lastSaved, setLastSaved] = useState<Date>(new Date())
  const [editorRef, setEditorRef] = useState<DesignHuddleEditorType>()
  const [tcoHash, setTcoHash] = useState<string>()
  const [showNextCTA, setShowNextCTA] = useState<boolean>(false)
  const [nextCTAText, setNextCTAText] = useState<string>('')
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false)
  const [projectCategory, setProjectCategory] = useState<CategoryDetails | null>(null)
  const [projectTemplateId, setProjectTemplateId] = useState<number | null>(null)
  const [projectCategoryId, setProjectCategoryId] = useState<number | null>(null)
  const [projectMediaType, setProjectMediaType] = useState<MediaType | null>(null)
  const [fileName, setFileName] = useState<string>(null)
  const [pages, setPages] = useState<Page[]>([])
  const [editorElementToolbarExtraFeatures, setEditorElementToolbarExtraFeatures] =
    useState<EditorElementExtraToolbarFeatures>({
      elementId: null,
      element: null,
      features: {
        openaiContentGeneration: false,
      },
    })

  const resetEditorElementToolbarExtraFeaturesState = () =>
    setEditorElementToolbarExtraFeatures({
      elementId: null,
      element: null,
      features: {
        openaiContentGeneration: false,
      },
    })

  const onTitleChange = () => {
    if (editorRef) {
      editorRef.showChangeTitle()
    }
  }

  useEffect(() => {
    // gets the category id from the URL Search Param as we currently have issue in getting it from DH Proxy
    const params = new URLSearchParams(history.location.search)
    if (params.has(CATEGORY_ID_URL_SEARCH_KEY) && !Number.isNaN(+params.get(CATEGORY_ID_URL_SEARCH_KEY))) {
      setProjectCategoryId(+params.get(CATEGORY_ID_URL_SEARCH_KEY))
    }
  }, [history.location.search])

  useEffect(() => {
    if (categoriesDict.has(projectCategoryId)) {
      const category = categoriesDict.get(projectCategoryId)
      setProjectCategory(category ?? null)
      setShowNextCTA(category.hasPaid ?? false)
      setNextCTAText(category.hasPaid ? category.nextCTA ?? '' : '')
    }
  }, [projectCategoryId, categoriesDict])

  const updateElements = useCallback(
    (tco: DesignHuddleTemplateCustomizationsObject, getProjectElementsAttempts?: number) => {
      return getProjectElements(editorRef, getProjectElementsAttempts).then((pages) => {
        const replaceableElements = pages
          .map((page) => generateReplaceableElementsTCO(page, tco.classes))
          .reduce((acc, curr) => {
            const elements = { ...(acc?.elements ?? {}), ...(curr?.elements ?? {}) }

            const classes = { ...(acc?.classes ?? {}), ...(curr?.classes ?? {}) }

            return { elements, classes }
          })

        editorRef?.changeElements(replaceableElements)
      })
    },
    [editorRef]
  )

  const replaceTemplate: (templateId: number) => Promise<string> = useCallback(
    (templateId: number) => {
      return getProjectElements(editorRef).then((pages) => {
        const [firstPageTco] = pages
        const customizations = parseTcoFromTemplateElements(firstPageTco, true)
        return new Promise((resolve, reject) => {
          dhSdk.createProject(
            { template_id: templateId, customizations },
            {},
            (error, { project_id: newProjectId }) => {
              if (error) {
                reject(error)
                return
              }
              resolve(newProjectId)
            }
          )
        })
      })
    },
    [editorRef, dhSdk]
  )

  useEffect(() => {
    let abortMutation = false
    if (editorRef) {
      const cb = (editorRef: DesignHuddleEditorType, changes: { project_title: string }) => {
        if (!abortMutation) {
          setTitle(changes.project_title)
        }
      }
      editorRef.handleProjectTitleChange(cb)
      return () => {
        abortMutation = true
      }
    }
  }, [editorRef])

  useEffect(() => {
    if (editorRef) {
      editorRef.getProjectData({}, (error, data: ProjectDataType) => {
        if (error) return
        setTitle(data.project_title)
        setProjectId((previous) => (previous !== data.project_id ? data.project_id : previous))
      })
    }
  }, [editorRef])

  useEffect(() => {
    let abortMutation = false
    if (projectId && getProjectData) {
      getProjectData(projectId)
        .then((project) => {
          if (abortMutation) {
            return
          }
          const { template, category, mediaType } = project
          setProjectMediaType((prev) => (`${prev}` !== `${mediaType}` ? mediaType : prev))
          setProjectCategoryId((prev) => (`${prev}` !== `${category?.id}` ? category?.id : prev))
          setProjectTemplateId((prev) => (`${prev}` !== `${template?.id}` ? template?.id : prev))
          setLastSaved(new Date())
          setFileName(project.template.name)
          setPages(project.pages)
        })
        .catch((error) => {
          console.error('Failed to get project Data', error)
        })
    }
    return () => {
      abortMutation = true
    }
  }, [getProjectData, projectId])

  const value = {
    projectCategoryId,
    projectTemplateId,
    projectMediaType,
    editorRef,
    setEditorRef,
    exportModalOpen,
    setExportModalOpen,
    onTitleChange,
    title,
    projectThumbnail,
    lastSaved,
    projectCategory,
    showNextCTA,
    nextCTAText,
    tcoHash,
    setTcoHash,
    updateElements,
    replaceTemplate,
    getLowResPagesRenders: getLowResPagesRenders({ editorRef, setProjectThumbnail, setTitle }),
    fileName,
    pages,
    editorElementToolbarExtraFeatures,
    setEditorElementToolbarExtraFeatures,
    resetEditorElementToolbarExtraFeaturesState,
  }
  return <EditorContext.Provider value={value}>{children}</EditorContext.Provider>
}
