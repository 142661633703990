import { PageRender } from '../contexts/editor.context'
import { ProjectDataType } from '../domain/project-data.type'
import { DesignHuddleEditorType } from '../../shared/domain/design-huddle-editor.type'

// TODO: this is a "hack" to get the low res images for the project
export const getLowResPagesRenders: (handlers: {
  editorRef: DesignHuddleEditorType
  setTitle: (title: string) => void
  setProjectThumbnail: (src: string) => void
}) => () => Promise<PageRender[]> =
  ({ editorRef, setTitle, setProjectThumbnail }) =>
  () => {
    const getThumbnail: (pageId: string) => Promise<string> = (pageId: string) => {
      return new Promise((resolve, reject) => {
        editorRef.getRender(
          {
            page_id: pageId,
          },
          (error, data) => {
            if (error) {
              reject(error)
              return
            }

            resolve(data.url)
          }
        )
      })
    }

    return new Promise((resolve, reject) => {
      if (!editorRef) {
        reject(new Error('EditorRef is not set'))
        return
      }

      editorRef.getProjectData({}, async (error, projectData: ProjectDataType) => {
        if (error) {
          reject(error)
          return
        }

        setTitle(projectData.project_title)

        const result: PageRender[] = []
        let mediaType, mediaTypeId
        switch (projectData.media_type) {
          case 'video':
            mediaType = 'scenes'
            mediaTypeId = 'scene_id'
            break
          case 'presentation':
            mediaType = 'slides'
            mediaTypeId = 'slide_id'
            break
          default:
            mediaType = 'pages'
            mediaTypeId = 'page_id'
            break
        }

        // TODO: Sometimes this pages comes empty, so we have no way of knowing if thumbnail is present at time
        if (projectData && projectData[mediaType]) {
          for (const page of projectData[mediaType]) {
            try {
              const rendered = await getThumbnail(page[mediaTypeId])
              result.push({
                pageId: page?.page_id || null,
                encodedImage: rendered,
                sceneId: page?.scene_id || null,
              })
            } catch (error) {
              console.warn('Error getting thumbnail for page', page[mediaTypeId], error)
            }
          }
          // If we got here, we have pages, then we can set the project thumbnail
          setProjectThumbnail(result[0].encodedImage)
          resolve(result)
        }
      })
    })
  }
