import { generateDataAttrs } from '@avenue-8/platform-shared-util-frontend'
import { ChevronRight } from '@mui/icons-material'
import { Button } from '@mui/material'
import { ReactNode } from 'react'
import { BaseTopBar } from '../../shared/components/topbar/base-topbar'

export type EditorTopBarProps = {
  title?: string | ReactNode
  topBarSection?: JSX.Element
  alignItemsOnTop?: boolean
} & (
  | {
      showExport?: false
    }
  | {
      showExport: true
      onExportClick: () => void
    }
)

export const EditorTopBar = (props: EditorTopBarProps): JSX.Element => {
  const { showExport, title } = props

  return (
    <BaseTopBar title={title} alignItemsOnTop={props.alignItemsOnTop}>
      {showExport && (
        <Button
          disableElevation
          disableRipple
          variant='outlined'
          onClick={props.onExportClick}
          sx={{ marginRight: 2, paddingX: 4 }}
          endIcon={<ChevronRight />}
          {...generateDataAttrs({
            metaAction: 'export',
            metaName: 'export',
          })}
        >
          Export
        </Button>
      )}
      {props.topBarSection}
    </BaseTopBar>
  )
}
