import { AuthedFetch, ResponseType } from '@avenue-8/platform-shared-util-frontend'
import { AddressDetails, AutocompletedAddress, BackendAppService } from './backend-app.service.type'

export function BackendAppServiceFactory(baseUrl: string): BackendAppService {
  return {
    async autocompleteAddress(addressInput: string): Promise<ResponseType<AutocompletedAddress[]>> {
      const url = `${baseUrl}/google/address/autocomplete/${addressInput}`

      const response = await AuthedFetch(url, { method: 'get' })

      if (response.ok) {
        const addresses: AutocompletedAddress[] = await response.json()
        return {
          success: true,
          data: addresses,
        }
      }

      return { success: false }
    },
    async getAddressDetails(placeId: string): Promise<ResponseType<AddressDetails>> {
      const url = `${baseUrl}/google/address/details/${placeId}`

      const response = await AuthedFetch(url, { method: 'get' })

      if (response.ok) {
        const addresses: AddressDetails = await response.json()
        return {
          success: true,
          data: addresses,
        }
      }

      return { success: false }
    },
  }
}
