import styled from '@emotion/styled'
import { useContext, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { DesignHuddleContext } from '../../shared/contexts/design-huddle-sdk.context'
import { DesignHuddleEditorType } from '../../shared/domain/design-huddle-editor.type'
import { useParams } from 'react-router'
import { EditorContext } from '../contexts/editor.context'
import { useFeatureFlags } from '@avenue-8/platform-shared-util-frontend'

export type DHEditorReference = {
  projectId: string
  elementId: string
  ref: DesignHuddleEditorType
}

declare global {
  interface Window {
    dhEditorRef?: DHEditorReference
  }
}

const EditorIFrameWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
`

export type HuddleDesignEditorProps = {
  onError?: (error: any) => void
}

export const DesignHuddleEditor = (props: HuddleDesignEditorProps): JSX.Element => {
  const { setEditorRef, setEditorElementToolbarExtraFeatures } = useContext(EditorContext)
  const { dhSdk } = useContext(DesignHuddleContext)
  const { projectId: projectIdInUrl } = useParams<{ projectId }>()
  const dhEditorRef = useRef<DHEditorReference | undefined>(window.dhEditorRef)
  const editorElementId = useMemo(() => dhEditorRef.current?.elementId ?? Math.random().toString(36).slice(2), [])
  const { onError } = props
  const { hasFeature } = useFeatureFlags()

  // Handle selected editor element
  useEffect(() => {
    hasFeature('brand-studio-content-generator-temp_2021-05-23').then((enabled) => {
      if (!enabled) return
      if (!dhEditorRef.current?.ref) return

      dhEditorRef.current.ref.handleSelectionChange(function (e, elements) {
        const elementIds = Object.keys(elements)

        const resetEditorElementToolbarExtraFeaturesState = () =>
          setEditorElementToolbarExtraFeatures({
            elementId: null,
            element: null,
            features: {
              openaiContentGeneration: false,
            },
          })

        const isOnlyOneElementSelected = elementIds && elementIds.length === 1
        if (!isOnlyOneElementSelected) {
          resetEditorElementToolbarExtraFeaturesState()
          return
        }

        const elementId = elementIds[0]

        dhEditorRef.current.ref.getElementData(elementId, function (err, element) {
          if (err) {
            console.error('Error trying to get element data', err)
            return
          }

          if (element.type !== 'text') {
            resetEditorElementToolbarExtraFeaturesState()
            return
          }

          // Enable the feature for the selected element
          setEditorElementToolbarExtraFeatures({
            elementId: elementId || null,
            element: element || null,
            features: {
              openaiContentGeneration: true,
            },
          })
        })
      })
    })
  }, [dhEditorRef.current])

  useLayoutEffect(() => {
    if (!dhSdk) {
      return
    }
    if (projectIdInUrl) {
      const currentProjectId = dhEditorRef.current?.projectId
      if (projectIdInUrl && currentProjectId !== projectIdInUrl) {
        if (dhEditorRef.current?.ref) {
          dhEditorRef.current.ref.changeProject(currentProjectId)
          dhEditorRef.current.projectId = projectIdInUrl
        } else {
          const dhSdkInsertCfg = { project_id: projectIdInUrl }
          dhSdk.Editors.insert(editorElementId, dhSdkInsertCfg, (error, ref) => {
            if (error) {
              return onError ? onError(error) : console.warn(`failed to insert the editor:`, error)
            }
            dhEditorRef.current = {
              projectId: projectIdInUrl,
              elementId: editorElementId,
              ref,
            }
            setEditorRef(ref)
          })
        }
      } else if (projectIdInUrl) {
        console.error('Was not able to get the projectId')
      }
    }
    return () => {
      if (dhEditorRef.current?.ref) {
        dhEditorRef.current.ref.remove()
        dhEditorRef.current = undefined
      }
      if (setEditorRef) {
        setEditorRef(undefined)
      }
    }
  }, [dhSdk])

  return <EditorIFrameWrapper id={editorElementId} key='design-huddle-editor-iframe' data-testid='dh-editor-iframe' />
}
