import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const ListingInfo = styled(Box)`
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 13px;
  p {
    margin-bottom: 0;
  }
  p strong {
    color: #73738c;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 11px;
    display: block;
  }
  p span {
    color: #131319;
    font-weight: 400;
    font-size: 13px;
  }
`
