import { UserDataDto } from '@avenue-8/platform-shared-util-frontend'
import { DHInjectableAgentProfile } from '../domain/dh-injectable-agent-profile.type'

export function getDHInjectableAgentProfile(user: UserDataDto): DHInjectableAgentProfile {
  const { profile, dre, preferredEmail } = user
  const { photo, name, phone, email, socialMedia } = profile
  const [agentFirstName, ...otherNames] = name.split(' ')
  const [agentLastName] = otherNames.slice(0).reverse()

  const [firstLink] = socialMedia

  return {
    classes: {
      agentAvatar: { url: photo ?? '' },
      agentDRE: { text: 'DRE ' + (dre ?? '') },
      agentEmail: { text: preferredEmail ?? email ?? '' },
      agentFullName: { text: name ?? '' },
      agentFirstName: { text: agentFirstName ?? '' },
      agentLastName: { text: agentLastName ?? '' },
      agentPhoneNumber: { text: phone ?? '' },
      agentWebsite: { text: firstLink?.link ?? '' },
    },
  }
}
