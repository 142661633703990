import styled from '@emotion/styled'
import CircularProgress from '@mui/material/CircularProgress'
import { Box, Button, Container, Grid, ModalUnstyled } from '@mui/material'
import Slide from '@mui/material/Slide'
import { useState } from 'react'
import { AgentListingDto } from '../../../../shared/domain/agent-listing.dto'
import { FoundListingBox } from '../editor-listings-section/found-listing-box'
import { ListingsAutoCompleteInput } from '../../listings-auto-complete-input'
import { MobileModalContentWrapper } from '../../modal/mobile-modal-content-wrapper'
import { ModalTitle } from '../../../../shared/components/modal-title'
import { StyledBackdrop } from '../../../../shared/components/styled-backdrop'
import { StyledModal } from '../../styled-modal'

const ThinLineBreak = styled.hr`
  height: 0.8px;
  border: none;
  color: #333;
  background-color: #333;
  width: 100vw;
  margin-left: -16px;
`

export type MobileEditorListingsSearchModalProps = {
  open: boolean
  onClose: () => void
  onListingSelect: (listing: AgentListingDto) => void
}

export const MobileEditorListingsSearchModal = ({
  open,
  onClose,
  onListingSelect,
}: MobileEditorListingsSearchModalProps): JSX.Element => {
  const [status, setStatus] = useState<'LOADING' | 'IDLE'>('IDLE')
  const [selectedListing, setSelectedListing] = useState<AgentListingDto | null>(null)
  const handleListingSelect = (listing: AgentListingDto) => {
    setSelectedListing(listing)
  }
  return (
    <StyledModal open={open}>
      <Slide in={open} direction={'up'} timeout={{ enter: 200, exit: 200 }}>
        <MobileModalContentWrapper>
          <ModalUnstyled open={status === 'LOADING'} BackdropComponent={StyledBackdrop}>
            <CircularProgress />
          </ModalUnstyled>
          <ModalTitle onClose={onClose}>Search for a listing</ModalTitle>
          <Container sx={{ height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column' }}>
            <ThinLineBreak />
            <ListingsAutoCompleteInput
              selectedListing={selectedListing}
              onSelectedListingChange={handleListingSelect}
            />
            {selectedListing && <FoundListingBox listing={selectedListing} onOpenImageSelector={() => null} />}
            <Box sx={{ flexGrow: 1, width: '100%', display: 'flex', flexDirection: 'column-reverse' }}>
              <Grid container width='100%' sx={{ justifyContent: 'space-around', marginBottom: 1 }}>
                <Button variant='text' onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant='outlined'
                  disabled={!selectedListing}
                  onClick={() => {
                    onListingSelect(selectedListing)
                    onClose()
                  }}
                >
                  Add Listing
                </Button>
              </Grid>
              <ThinLineBreak />
            </Box>
          </Container>
        </MobileModalContentWrapper>
      </Slide>
    </StyledModal>
  )
}
