import { AuthedFetch, getAuthSBLInstance } from '@avenue-8/platform-shared-util-frontend'
import {
  EstimatesCoefficientDictionary,
  SocialMediaRequest,
  SocialMediaResponse,
  VisualMarketingAPIPostResponse,
  VisualMarketingService,
  RadiusUnit,
  PostcardRequest,
  AssetTypePriceRequest,
} from './visual-marketing.service.type'

export function VisualMarketingServiceFactory(baseUrl: string): VisualMarketingService {
  return {
    async getFacebookEstimates(budget: string, startDate: string, endDate: string, city: string) {
      const queryParams = new URLSearchParams({ budget, startDate, endDate, city })
      const url = `${baseUrl}/ads/facebook/delivery-estimates?${queryParams}`
      return AuthedFetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch delivery estimates')
          }
          return response.json()
        })
        .then((json: EstimatesCoefficientDictionary) => json)
        .catch(() => null)
    },
    async postSocialMediaRequest(body: SocialMediaRequest) {
      const url = `${baseUrl}/v2/social-media`
      return AuthedFetch(url, {
        headers: new Headers({ 'Content-Type': 'application/json' }),
        method: 'post',
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to submit social media request')
          }
          return response.json()
        })
        .then((data: VisualMarketingAPIPostResponse<SocialMediaResponse>) => data)
        .catch(() => null)
    },
    async postPostcardRequest(body: PostcardRequest) {
      const url = `${baseUrl}/v2/postcards`

      const response = await AuthedFetch(url, {
        headers: new Headers({ 'Content-Type': 'application/json' }),
        method: 'post',
        body: JSON.stringify(body),
      })

      if (!response.ok) {
        throw new Error('Failed to submit postcard request')
      }
      return response.json()
    },
    async uploadFile(file: File, acl: 'authenticated-read' | 'public-read') {
      const auth = getAuthSBLInstance()
      const credentials = await auth.getCredentials()

      if (!credentials) {
        throw new Error('Error uploading file - no credentials available')
      }

      const url = `${baseUrl}/files?agentId=${credentials.agentId}&acl=${acl}`
      const filePayload = new FormData()
      filePayload.append('file', file, file.name)

      return fetch(url, {
        headers: new Headers({ Authorization: `Bearer ${credentials.token}` }),
        method: 'post',
        body: filePayload,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Error uploading the file')
          }

          return response.json()
        })
        .then((data) => ({ data }))
        .catch(() => null)
    },
    async searchPeopleByGeoLocation(geoLocation: { lat: number; lon: number }, radius: number, unit: RadiusUnit) {
      const URL = `${baseUrl}/data-axle/search/geo`
      const queryParams = new URLSearchParams()
      queryParams.append('lat', geoLocation.lat.toString())
      queryParams.append('lng', geoLocation.lon.toString())
      queryParams.append('distance', `${radius}${unit}`)

      const response = await AuthedFetch(URL, {
        headers: new Headers({ 'Content-Type': 'application/json' }),
        method: 'get',
        queryParams,
      })
      if (response.ok) {
        return response.json()
      }

      throw new Error(`Error fetching people in the selected location: lat: ${geoLocation.lat} lon: ${geoLocation.lon}`)
    },
    async generatePeopleFileByGeoLocation(
      geoLocation: { lat: number; lon: number },
      radius: number,
      unit: RadiusUnit,
      limit: number
    ) {
      const URL = `${baseUrl}/data-axle/search/geo/csv`
      const queryParams = new URLSearchParams()
      queryParams.append('lat', geoLocation.lat.toString())
      queryParams.append('lng', geoLocation.lon.toString())
      queryParams.append('distance', `${radius}${unit}`)
      queryParams.append('limit', limit.toString())

      const response = await AuthedFetch(URL, {
        headers: new Headers({ 'Content-Type': 'application/json' }),
        method: 'get',
        queryParams,
      })
      if (response.ok) {
        return response.json()
      }

      throw new Error(`Error fetching people in the selected location: lat: ${geoLocation.lat} lon: ${geoLocation.lon}`)
    },
    async searchPeopleByBuildingAddress(address: string, city: string, postalCode?: string) {
      const URL = `${baseUrl}/data-axle/search/building`
      const queryParams = new URLSearchParams({ address, city })
      if (postalCode) {
        queryParams.append('postalCode', postalCode)
      }
      const response = await AuthedFetch(URL, {
        headers: new Headers({ 'Content-Type': 'application/json' }),
        method: 'get',
        queryParams,
      })
      if (response.ok) {
        return response.json()
      }

      throw new Error(`Error fetching people in the selected building: ${address}`)
    },
    async generatePeopleFileByBuildingAddress(
      params: { address: string; city: string; postalCode?: string },
      limit: number
    ) {
      const { address, city, postalCode } = params
      const URL = `${baseUrl}/data-axle/search/building/csv`
      const queryParams = new URLSearchParams({ address, city, limit: limit.toFixed(0) })
      if (postalCode) {
        queryParams.append('postalCode', postalCode)
      }
      const response = await AuthedFetch(URL, {
        headers: new Headers({ 'Content-Type': 'application/json' }),
        method: 'get',
        queryParams,
      })
      if (response.ok) {
        return response.json()
      }

      throw new Error(`Error fetching people in the selected building: ${address}`)
    },
    async getAssetTypePrice(priceRequest: AssetTypePriceRequest) {
      const { agentId, assetType, categoryType } = priceRequest
      const queryParams = new URLSearchParams({ agentId, assetType, categoryType })
      const url = `${baseUrl}/marketing/prices?${queryParams}`
      return AuthedFetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch asset type prices')
          }
          return response.json()
        })
        .then((json: EstimatesCoefficientDictionary) => json)
        .catch(() => null)
    },
  }
}
