import { DesignHuddleEditorType } from '../../shared/domain/design-huddle-editor.type'
import {
  isDigitalProjectData,
  isPresentationProjectData,
  isPrintProjectData,
  isVideoProjectData,
  PageElementWithId,
  ProjectDataType,
  ProjectPage,
  ProjectScene,
  ProjectSlide,
} from '../domain/project-data.type'

export const getProjectElements = (
  editor: DesignHuddleEditorType,
  maxAttempts = 10
): Promise<Record<'elements' | 'classes', Record<string, PageElementWithId>>[]> => {
  let count = 0
  return new Promise((resolve, reject) => {
    editor.handleProjectData((editor: DesignHuddleEditorType, data: ProjectDataType) => {
      let pages: Array<ProjectPage | ProjectScene | ProjectSlide> | null = null
      if (isDigitalProjectData(data) || isPrintProjectData(data)) {
        pages = data.pages
      } else if (isPresentationProjectData(data)) {
        pages = data.slides
      } else if (isVideoProjectData(data)) {
        pages = data.scenes
      }
      if (pages === null) {
        if (count >= maxAttempts) {
          reject(new Error('Too many attempts to fetch project pages'))
          return
        }
        count += 1
        setTimeout(() => {
          editor.requestProjectData({})
        }, 1000)
        return
      }
      const resolvedValue = pages.map(({ elements: projectElements }) => {
        const pageElementsList: Readonly<[string, PageElementWithId][]> = Object.entries(projectElements)
          .filter(([, value]) => value.element_name)
          .map(([elementId, element]) => [element.element_name, { elementId, ...element }])

        const pageClassesList: Readonly<[string, PageElementWithId][]> = Object.entries(projectElements)
          .filter(([, value]) => value.element_classes?.length)
          .map(([elementId, element]) => {
            const [firstClassName] = element.element_classes
            return [firstClassName, { elementId, ...element }]
          })

        const tcoElementsObject: Record<string, PageElementWithId> = Object.fromEntries(pageElementsList)
        const tcoClassesObject: Record<string, PageElementWithId> = Object.fromEntries(pageClassesList)

        return { elements: tcoElementsObject, classes: tcoClassesObject }
      })
      resolve(resolvedValue)
    })
    editor.requestProjectData({})
  })
}
