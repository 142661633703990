import { ConfirmDialog } from '@avenue-8/platform-style-util-frontend'
import { Box, FormControl, TextField } from '@mui/material'
import { useState, useEffect, useCallback } from 'react'

export type ProjectSaveAsTemplateDialogType = {
  open: boolean
  templateTitle: string
  projectId: string
  categoryId: number
  selectedAssetType: string
  onPublishTemplate: (
    projectId: string,
    templateTitle: string,
    categoryId: number,
    selectedAssetType: string
  ) => Promise<void>
  onClose: () => void
}

type Errors = {
  templateTitle: string
}

export default function ProjectSaveAsTemplateDialog(props: ProjectSaveAsTemplateDialogType): JSX.Element {
  const { open, templateTitle, projectId, categoryId, selectedAssetType, onPublishTemplate, onClose } = props
  const [updatedTemplateTitle, setUpdatedTemplateTitle] = useState<string>(templateTitle)
  const [errors, setErrors] = useState<Errors | null>(null)

  useEffect(() => {
    if (templateTitle) {
      setUpdatedTemplateTitle(templateTitle)
    }
  }, [templateTitle])

  const triggerValidation = ({ templateTitle }: Errors) => {
    const _errors = {} as Errors

    if (!templateTitle?.trim()) _errors.templateTitle = 'Template title is required'
    if (templateTitle && templateTitle.trim().length > 50)
      _errors.templateTitle = 'Template title must be shorter than or equal to 50 characters'

    if (Object.keys(_errors).length > 0) {
      setErrors(_errors)
      return false
    }

    if (errors) {
      setErrors(null)
    }

    return true
  }

  const handleSaveAsTemplate = () => {
    const isValid = triggerValidation({
      templateTitle: updatedTemplateTitle,
    })

    if (!isValid) return

    if (selectedAssetType) {
      onPublishTemplate(projectId, updatedTemplateTitle, categoryId, selectedAssetType)
    }
  }

  const onChangeTemplateTitle = (event) => {
    const value = event.target.value
    if (value !== updatedTemplateTitle) {
      setUpdatedTemplateTitle(value)
    }
    if (errors) {
      triggerValidation({ templateTitle: updatedTemplateTitle })
    }
  }

  const handleClose = () => {
    setUpdatedTemplateTitle(templateTitle)
    setErrors(null)
    onClose()
  }

  return (
    <ConfirmDialog
      open={open}
      onConfirm={handleSaveAsTemplate}
      onCancel={handleClose}
      dialogTitle={'Save As Template'}
      confirmLabel={'Save'}
      cancelLabel={'Cancel'}
      data-meta-id={`${projectId}-save-as-template`}
      data-meta-name={`${projectId}-save-as-template`}
    >
      <Box
        sx={{
          width: 500,
          maxWidth: '100%',
        }}
      >
        <FormControl>
          <TextField
            onChange={onChangeTemplateTitle}
            required
            id={`${projectId}-save-as-template`}
            label={'Template Title'}
            value={updatedTemplateTitle}
            fullWidth
            error={!!errors?.templateTitle}
            helperText={errors?.templateTitle}
          />
        </FormControl>
      </Box>
    </ConfirmDialog>
  )
}
