export const csvToArray = (str: string, delimiter = ','): Record<string, string>[] => {
  const headers = str.slice(0, str.indexOf('\n')).split(delimiter)
  const rows = str.slice(str.indexOf('\n') + 1).split('\n')

  const arr = rows.map(function (row) {
    const values = row.split(delimiter)
    const el = headers.reduce((object, header, index) => {
      object[header.trim()] = values[index]
      return object
    }, {})
    return el
  })

  return arr
}
