import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

export const TitleTypographyStyled = styled(Typography)`
  color: #73738c;
  font-size: 11px;
  letter-spacing: 2px;
  font-weight: 400;
  line-height: 1.5;
`
