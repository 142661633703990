import { generateDataAttrs } from '@avenue-8/platform-shared-util-frontend'
import { Box, Grid, Typography } from '@mui/material'
import kebabCase from 'lodash.kebabcase'
import { DesignHuddleProxyTemplate as Template } from '../../shared/domain/design-huddle-proxy-template.type'
import styled from '@emotion/styled'

interface GalleryItemProps {
  template: Template
  onClick: (template: Template) => void
}

const InformationContainer = styled.div`
  max-width: 155px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
`

export default function GalleryItem({ template, onClick }: GalleryItemProps): JSX.Element {
  return (
    <Grid>
      <a
        href='#'
        onClick={() => onClick(template)}
        {...generateDataAttrs({
          metaName: `${kebabCase(template.templateTitle)}-template-card`,
          metaAction: `${kebabCase(template.templateTitle)}-template-card`,
        })}
      >
        <Box
          sx={{
            width: { xs: '156px', md: '200px' },
            height: { xs: '156px', md: '200px' },
            border: '1px solid #d5d6ea',
          }}
        >
          <img
            src={template.thumbnailUrl}
            alt={`${template.description} template thumbnail`}
            width='100%'
            height='100%'
            style={{ objectFit: 'contain' }}
          />
        </Box>
      </a>
      <InformationContainer onClick={() => onClick(template)}>
        <Typography fontSize={14} variant='body1' fontWeight='300'>
          {template.templateTitle}
        </Typography>
      </InformationContainer>
    </Grid>
  )
}
