import styled from '@emotion/styled'
import { AppBar, Box, Toolbar, Typography } from '@mui/material'
import { PropsWithChildren, ReactNode } from 'react'

const MenusContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: baseline;
  flex-wrap: nowrap;
  flex-grow: 1;
  & > * {
    margin-left: 0.75em;
  }
`

export type TopBarProps = PropsWithChildren<{ title: string | ReactNode; alignItemsOnTop?: boolean }>
export const TopBar = ({ title, alignItemsOnTop, children }: TopBarProps): JSX.Element => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static' color='secondary' sx={{ paddingTop: 1, paddingBottom: 1 }}>
        <Toolbar style={{ alignItems: alignItemsOnTop ? 'flex-start' : 'baseline' }}>
          {typeof title === 'string' ? (
            <Typography color='primary' variant='h6' component='h1' sx={{ cursor: 'pointer' }}>
              {title}
            </Typography>
          ) : (
            title
          )}
          <MenusContainer style={{ marginTop: alignItemsOnTop ? 3 : 1, marginBottom: alignItemsOnTop ? 3 : 1 }}>
            {children}
          </MenusContainer>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
