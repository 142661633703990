import Stack, { StackProps } from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import { MLSDataPhotoType } from '../../../../shared/domain/agent-listing.dto'
import { TitleTypographyStyled } from '../title-typography.styled'
import { TitleWrapper } from '../title-wrapper.styled'
import { SelectableListingPhoto } from './selectable-listing-photo'

export type ListingPhotoSelectorPreviewProps = {
  photos: MLSDataPhotoType[]
  onSeeAllClick: () => void
  selectedPhotos: MLSDataPhotoType[]
  maxSelectedPhotos?: number
  stackSx?: StackProps['sx']
}

export const ListingPhotoSelectorPreview = ({
  photos,
  onSeeAllClick,
  selectedPhotos,
  maxSelectedPhotos = 1,
  stackSx = {},
}: ListingPhotoSelectorPreviewProps): JSX.Element => (
  <Stack spacing={0} sx={stackSx}>
    <TitleWrapper>
      <TitleTypographyStyled variant={'body1'}>
        <strong>
          {maxSelectedPhotos > 0 ? 'Select photos' : 'Photos from MLS'}{' '}
          {photos.length > 0 && maxSelectedPhotos > 0 && `(${selectedPhotos.length} / ${maxSelectedPhotos})`}
        </strong>
      </TitleTypographyStyled>
      {photos.length > 0 && (
        <TitleTypographyStyled variant={'button'} onClick={onSeeAllClick} style={{ cursor: 'pointer' }}>
          See All
        </TitleTypographyStyled>
      )}
    </TitleWrapper>
    <div style={{ marginTop: '4px' }}>
      {photos.length < 1 ? (
        <Grid item>This listing appears to have no MLS Photos</Grid>
      ) : (
        <ImageList variant={'quilted'} cols={2}>
          {photos.slice(0, 2).map((photo, idx) => (
            <ImageListItem key={`mls-photo-#${idx}`} sx={{ maxHeight: '75px' }}>
              <img
                src={photo.thumbnailUrl ?? photo.photoUrl}
                alt={`Listing photo #${idx}`}
                style={{ borderRadius: '8px' }}
              />
              <SelectableListingPhoto
                showCheckOnly={maxSelectedPhotos === 1}
                isSelected={selectedPhotos.includes(photo)}
                selectedIdx={selectedPhotos.indexOf(photo)}
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </div>
  </Stack>
)
