import { generateDataAttrs } from '@avenue-8/platform-shared-util-frontend'
import { defaultTheme } from '@avenue-8/platform-style-util-frontend'
import { ChevronLeftOutlined, ShareOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import { TopBar } from '../../../../shared/components/topbar/topbar'
import { ShareOutlinedIcon } from '../../../assets/icons/share-outlined.icon'

export type MobileEditorTopBarProps = {
  backCtaLink: string
  onShareClick: () => void
}

export const MobileEditorTopBar = ({ backCtaLink, onShareClick }: MobileEditorTopBarProps): JSX.Element => {
  const backButton = (
    <Button
      startIcon={<ChevronLeftOutlined />}
      variant='text'
      component='a'
      href={backCtaLink}
      sx={{
        padding: '12px 0',
        color: defaultTheme.primaryColor,
        fontWeight: 400,
        letterSpacing: 2,
        fontSize: '12px',
      }}
      {...generateDataAttrs({
        metaAction: 'go-back-mobile',
      })}
    >
      Back
    </Button>
  )
  const shareButton = (
    <Button
      variant='text'
      onClick={onShareClick}
      sx={{ maxHeight: '100%' }}
      {...generateDataAttrs({
        metaAction: 'share-project-mobile',
      })}
    >
      <ShareOutlined />
    </Button>
  )
  return <TopBar title={backButton}>{shareButton}</TopBar>
}
