import { useHistory } from 'react-router-dom'
import { datadogRum } from '@avenue-8/platform-shared-util-frontend'
import Box from '@mui/material/Box'
import { MouseEvent, useCallback, useContext, useMemo, useState } from 'react'
import { ProjectExportModal } from '../../shared/components/export-share-modal/project-export-modal'
import { DesignHuddleProject } from '../../shared/domain/design-huddle-project'
import { DesignHuddleProxyJob, DesignHuddleProxyProject } from '../../shared/domain/design-huddle-proxy-project.type'
import { DesignHuddleProxyAdapter } from '../../shared/domain/design-huddle-proxy.adapter'
import ProjectSaveAsTemplateDialog from '../components/project-save-as-template-dialog'
import ProjectsRow from '../components/projects-row'
import { ProjectsContext } from '../contexts/projects.context'
import useInterval from '../../shared/hooks/useInterval'
import { DesignHuddleProxyContext } from '../../shared/contexts/design-huddle-proxy.context'

const POLLING_TIME_INTERVAL = 4500

export const ProjectsPage = (): JSX.Element => {
  const { projects, status, saveAsTemplateDialog, templateToSave, publishTemplate, closeSaveAsTemplateDialog } =
    useContext(ProjectsContext)

  const [selectedProject, setSelectedProject] = useState<DesignHuddleProject | null>(null)
  const selectedDhProject: DesignHuddleProxyProject | null = useMemo(
    () => (selectedProject ? DesignHuddleProxyAdapter.convertProject(selectedProject) : null),
    [selectedProject]
  )

  const [projectId, setProjectId] = useState<string>('')
  const [selectedAssetType, setSelectedAssetType] = useState<string>('')
  const [publishJob, setPublishJob] = useState<string>('')
  const [publishStatus, setPublishStatus] = useState<'IDLE' | 'JOB_CREATED' | 'JOB_FINISHED' | 'DONE'>('IDLE')
  const { downloadPublishJob } = useContext(DesignHuddleProxyContext)
  const history = useHistory()

  const handleOpenProject = useCallback((_event: MouseEvent, _project: DesignHuddleProxyProject) => {
    // TODO: This should be revisited later to be an extra option of opening the export/share modal without opening the project
    // if (postMessage) {
    //   event.preventDefault()
    //   event.nativeEvent.preventDefault()
    //   event.nativeEvent.stopPropagation()
    //   setSelectedProject(project)
    // }
  }, [])

  const handleProjectExportModalClose = useCallback(() => {
    setSelectedProject(null)
  }, [setSelectedProject])

  const isLoading = useMemo(() => status === 'LOADING', [status])

  // Pooling to check presentation job status after its created
  useInterval({
    callback: async () => {
      if (publishJob && publishStatus === 'JOB_CREATED') {
        const result = await downloadPublishJob(projectId, publishJob)
        if (result.success) {
          history.push(`edit/gallery/${selectedAssetType}`)
          history.go(0)
        }
      }
    },
    delay: publishStatus === 'JOB_FINISHED' ? null : POLLING_TIME_INTERVAL,
  })

  const handlePublishTemplate = useCallback(
    (projectId: string, templateTitle: string, categoryId: number, selectedAssetType: string) => {
      closeSaveAsTemplateDialog()
      const STAR_ICON = '\u2B50'
      return publishTemplate(projectId, `${STAR_ICON} ${templateTitle}`, categoryId)
        .then(({ data }) => {
          setProjectId(projectId)
          setSelectedAssetType(selectedAssetType)
          setPublishJob(data.jobId)
          setPublishStatus('JOB_CREATED')
        })
        .catch((error) => {
          setPublishStatus('IDLE')
          datadogRum.addError(error)
          window.alert(`Failed to save as template: ${error?.message || error}`)
        })
    },
    []
  )

  const handleClosePublishTemplate = useCallback(() => {
    closeSaveAsTemplateDialog()
  }, [])

  return (
    <Box sx={{ paddingLeft: { xs: 3, md: 6 }, paddingRight: { xs: 0, md: 6 }, mt: { xs: 1, md: 3 } }}>
      <ProjectExportModal
        open={selectedProject !== null}
        onClose={handleProjectExportModalClose}
        project={selectedDhProject}
      />
      <ProjectSaveAsTemplateDialog
        open={saveAsTemplateDialog}
        projectId={templateToSave.projectId}
        templateTitle={templateToSave.templateTitle}
        categoryId={templateToSave.categoryId}
        selectedAssetType={templateToSave.selectedAssetType}
        onPublishTemplate={handlePublishTemplate}
        onClose={handleClosePublishTemplate}
      />
      <ProjectsRow title='My Drafts' projects={projects || []} loading={isLoading} onProjectClick={handleOpenProject} />
    </Box>
  )
}
