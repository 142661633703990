import { MenuItem, FormControl, Select, Box, TextField, Typography, Grid, Input } from '@mui/material'
import { DateRangePicker, Textarea, useScreenSize, InputLabel } from '@avenue-8/platform-style-util-frontend'
import { ForwardedRef, forwardRef, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { FormError, PriceData } from '../finalize/finalize-wrapper'
import { useDebounce } from '../../../shared/hooks/useDebounce'
import { VisualMarketingFacebookEstimates } from '../facebook-estimates/visual-marketing-facebook-estimates'
import {
  PaymentMethod,
  useAuthContext,
  useBilling,
  ResponseType,
  ErrorBoundary,
} from '@avenue-8/platform-shared-util-frontend'
import { VisualMarketingServiceContext } from '../../../shared/services/visual-marketing/visual-marketing.service.context'
import { SocialMediaRequest } from '../../../shared/services/visual-marketing/visual-marketing.service.type'
import formatDate from 'date-fns/format'
import { useParams } from 'react-router'

type CustomInputProps = {
  onChange: (event: { target: { name: string; value: string } }) => void
}

const generatePriceData = (value: number) => ({
  order: 0,
  label: 'Social Media Post',
  value,
})

const CTAOptions = [
  {
    label: 'Contact',
    value: 'contact',
  },
  {
    label: 'Download',
    value: 'download',
  },
  {
    label: 'Learn More',
    value: 'learnMore',
  },
  {
    label: 'Open Link',
    value: 'openLink',
  },
  {
    label: 'Request Time',
    value: 'requestTime',
  },
  {
    label: 'Send Message',
    value: 'sendMessage',
  },
  {
    label: 'Sign Up',
    value: 'signUp',
  },
  {
    label: 'View Event',
    value: 'viewEvent',
  },
]

const CurrencyInputField = forwardRef<HTMLInputElement, CustomInputProps>(function MoneyInput(
  props: PropsWithChildren<CustomInputProps>,
  ref: ForwardedRef<HTMLInputElement>
): JSX.Element {
  const handleChange = (value) => {
    props.onChange({
      target: {
        name: 'budget-currency-input',
        value,
      },
    })
  }
  return (
    <CurrencyInput
      id='budget-currency-input'
      placeholder='min. $100 spend'
      prefix='$'
      defaultValue={100}
      min={100}
      max={99999}
      decimalsLimit={0}
      ref={ref}
      onValueChange={handleChange}
      style={{ fontSize: '14px' }}
      customInput={Input}
    />
  )
})

export type SocialMediaCampaignFormFields = {
  budget: number
  city: string
  startDate: Date
  endDate: Date
  postCaption: string
  cta: string
  link: string
  estimates?: {
    actions: number
    impressions: number
    reach: number
  }
}

export type SocialMediaCampaignFormProps = {
  showCity?: boolean
  price: PriceData
  onPriceChange: (priceData: { [k: string]: PriceData }) => void
  onFormChange: (isFormValid: boolean) => void
  setSubmitFn: any
}

export const SocialMediaCampaignForm = (props: SocialMediaCampaignFormProps): JSX.Element => {
  const { showCity, onPriceChange, price, onFormChange, setSubmitFn } = props
  const { projectId } = useParams<{ projectId?: string }>()

  const [formPayload, setFormPayload] = useState<{ [key: string]: any }>({})
  const [formError, setFormError] = useState<false | string | string[]>(false)

  const debouncedCity = useDebounce<string>(formPayload.city ?? '', 2000)
  const { agentId } = useAuthContext()
  const { getDefaultPaymentMethod } = useBilling()
  const { postSocialMediaRequest } = useContext(VisualMarketingServiceContext)
  const { isDesktop } = useScreenSize()

  const handleFormPayloadChange = (payload: { [key: string]: any }) => {
    setFormPayload(payload)
  }

  const _handleFormError = (value: FormError) => {
    setFormError(value)
  }

  const handlePriceChange = useCallback(
    (value: PriceData) => {
      if (value.value < 100 || value.value === undefined) {
        setFormError('true')
      }
      if (value.value >= 100) {
        setFormError(false)
      }
      onPriceChange({ 'social-media': value })
    },
    [onPriceChange]
  )

  const updateValue = useCallback(
    (partial: Partial<SocialMediaCampaignFormFields>) => {
      const {
        city = '',
        budget = 100,
        startDate = null,
        endDate = null,
        postCaption = '',
        cta = '',
        link = '',
        estimates = {
          actions: 0,
          impressions: 0,
          reach: 0,
        },
      } = formPayload as Partial<SocialMediaCampaignFormFields>
      handleFormPayloadChange({ budget, city, startDate, endDate, postCaption, cta, link, estimates, ...partial })
    },
    [formPayload]
  )

  useEffect(() => {
    if (price?.value && formPayload?.budget && price.value !== formPayload.budget) {
      handlePriceChange(generatePriceData(formPayload.budget))
    } else if (!price?.value) {
      handlePriceChange(generatePriceData(100))
    }
  }, [price?.value, formPayload.budget])

  useEffect(() => {
    const validSocialMediaFormFields = [
      formPayload?.budget,
      formPayload?.startDate,
      formPayload?.endDate,
      formPayload?.city,
    ].every((value) => !!value)
    const noFormErrors = Object.values(formError).every((error) => error === false)
    const isFormValid = validSocialMediaFormFields && noFormErrors

    onFormChange(isFormValid)
  }, [formError, formPayload, onFormChange])

  const handleSubmit = async () => {
    return getDefaultPaymentMethod()
      .then((billingResponse: ResponseType<{ defaultPaymentMethod: PaymentMethod }>) => {
        const { budget, endDate, startDate, city, cta, link, postCaption, estimates } = formPayload ?? {}
        if (billingResponse.success && billingResponse.data?.defaultPaymentMethod) {
          const paymentMethodId = billingResponse.data?.defaultPaymentMethod.id as string
          const budgetValueInCents = Number(Number.isNaN(budget) ? 0 : budget) * 100
          const dateFormat = 'yyyy-MM-dd'

          const body: SocialMediaRequest = {
            agentId: Number(agentId ?? 0),
            paymentMethodId,
            projectId,
            campaign: {
              endAt: formatDate(endDate, dateFormat),
              startAt: formatDate(startDate, dateFormat),
              city,
              cta,
              budget: budgetValueInCents,
              link,
              postCaption: postCaption ?? '',
              estimates,
            },
            metadata: {},
          }

          return postSocialMediaRequest(body)
        } else {
          const error: Error & { title?: string; body?: string } = Error('Failed to get Payment Method')
          error.title = 'Failed to get payment method'
          error.body = 'Could not get your payment method, please try again later.'
          throw error
        }
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  }

  useEffect(() => {
    setSubmitFn(() => handleSubmit)
  }, [formPayload])

  return (
    <Box ml={2}>
      <Grid container columnSpacing={2} mt={2}>
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <TextField
            required
            label='Budget'
            value={formPayload.budget}
            placeholder='testing'
            variant='standard'
            onChange={({ target: { value } }) => {
              updateValue({ budget: Number(value || 100) })
            }}
            InputLabelProps={{ sx: { marginBottom: 1 } }}
            InputProps={{
              componentsProps: {
                root: { style: { marginTop: 0, border: 'none', borderRadius: 0, padding: 0 } },
              },
              inputComponent: CurrencyInputField as any,
            }}
          />
        </Grid>
        {showCity && (
          <Grid item xs={12} sm={6} md={4} lg={3} sx={{ marginTop: { xs: 2, sm: 0 } }}>
            <TextField
              required
              variant='standard'
              label='City'
              value={formPayload.city}
              onChange={({ target: { value: city } }) => {
                updateValue({ city })
              }}
              InputLabelProps={{ sx: { marginBottom: 1 } }}
              InputProps={{
                componentsProps: { root: { style: { marginTop: 0 } } },
              }}
              inputProps={{
                sx: { fontSize: '14px !important' },
              }}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={showCity ? 6 : 10}
          lg={showCity ? 7 : 10}
          container
          alignItems='center'
          spacing={isDesktop ? 1 : 0}
        >
          <Grid item>
            <DateRangePicker
              disabled={false}
              minStartDate={new Date()}
              cb={(startDate: Date, endDate: Date) => {
                // This if is required because this component has a memory leak that creates an infinite loop
                if (
                  startDate?.toDateString() !== formPayload?.startDate?.toDateString() ||
                  endDate?.toDateString() !== formPayload?.endDate?.toDateString()
                ) {
                  updateValue({ startDate, endDate })
                }
              }}
              startSx={{
                marginTop: { xs: 2, md: 0 },
              }}
              endSx={{
                marginTop: { xs: 2, md: 0 },
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} mt={2}>
        <Grid item lg={12}>
          <ErrorBoundary
            fallback={<Typography>The estimates are not available right now, please try again later.</Typography>}
          >
            <VisualMarketingFacebookEstimates
              budget={formPayload.budget}
              city={debouncedCity}
              startDate={formPayload.startDate}
              endDate={formPayload.endDate}
              onEstimatesChange={(estimates) => {
                updateValue({ estimates })
              }}
            />
          </ErrorBoundary>
        </Grid>
      </Grid>

      <Grid container columnSpacing={isDesktop ? 2 : 0} rowSpacing={isDesktop ? 0 : 2} mt={isDesktop ? 6 : 0}>
        <Grid item xs={12} sm={6} md={2} lg={12}>
          <InputLabel style={{ marginBottom: 8 }}>Caption</InputLabel>
          <Textarea
            maxCharLength={200}
            minRows={2}
            maxRows={6}
            value={formPayload.postCaption}
            onChange={({ target: { value: postCaption } }) => updateValue({ postCaption })}
            placeholder='Say something about your promoted post here.'
            style={{ minHeight: '100px', height: '100px' }}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={isDesktop ? 2 : 0} rowSpacing={isDesktop ? 0 : 2} mt={isDesktop ? 2 : 0}>
        <Grid item xs={12} sm={6}>
          <FormControl>
            <InputLabel style={{ marginBottom: 8 }}>CTA</InputLabel>
            <Select
              fullWidth
              variant='standard'
              onChange={({ target: { value: cta } }) => updateValue({ cta } as { cta: string })}
              inputProps={{
                sx: { fontSize: '14px !important' },
              }}
              SelectDisplayProps={{
                style: { height: '1.4375em' },
              }}
            >
              {CTAOptions.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant='standard'
            label='Link'
            onChange={({ target: { value: link } }) => updateValue({ link })}
            InputLabelProps={{ sx: { marginBottom: 1 } }}
            InputProps={{
              componentsProps: { root: { style: { marginTop: 0 } } },
            }}
            inputProps={{
              sx: { fontSize: '14px !important' },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
