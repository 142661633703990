/* eslint-disable camelcase */
import {
  ErrorBoundary,
  generateDataAttrs,
  useFeatureFlags,
  useUserDataContext,
} from '@avenue-8/platform-shared-util-frontend'
import { ChevronRight } from '@mui/icons-material'
import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined'
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined'
import { Button, Grid, Typography } from '@mui/material'
import kebabCase from 'lodash.kebabcase'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useQuery } from '../../shared/hooks/useQuery'
import { EditorContext } from '../contexts/editor.context'
import { getDHInjectableAgentProfile } from '../utils/get-dh-injectable-agent-profile'
import { EditorLayout } from './editor-layout'
import { DesignHuddleEditor } from './design-huddle-editor'
import { EditorListingsSection } from './sections/editor-listings-section/editor-listings-section'
import { EditorTemplatesSection } from './sections/editor-templates-section'
import { MobileEditorBottomBarSection } from './sections/mobile/mobile-editor-bottom-bar'
import { MobileEditorListingsDrawer } from './sections/mobile/mobile-editor-listings-drawer'
import { MobileEditorTemplatesDrawer } from './sections/mobile/mobile-editor-templates-drawer'
import { OptionEnabledKeys, RightSideEditorColumn, RightSideEditorColumnValue } from './right-side-editor-column'
import { EditorExportShareModal } from './share-modal/editor-export-share-modal'
import { EditorAIAssistSection } from './sections/editor-ai-assist-section/editor-ai-assist-section'

type EditorPageParams = { projectId?: string }

const AGENT_DATA_INJECTION_ATTEMPTS_LIMIT = 3

const FEATURE_KEY_PREFIX = '@BRAND_STUDIO_FEATURE_ENABLED/'

export const EditorPage = (): JSX.Element => {
  const history = useHistory()
  const { projectId } = useParams<EditorPageParams>()
  const { parentCategoryId } = useQuery<{ parentCategoryId?: string }>()
  const [injectionFlag, setInjectionFlag] = useState<number>(Math.random())
  const [dhPromoteCTAEnabled, setDhPromoteCTAEnabled] = useState(false)
  const { hasFeature } = useFeatureFlags()
  const [optionsEnabled, setOptionsEnabled] = useState<Record<OptionEnabledKeys, boolean>>({
    AI_CONTENT_ASSIST: localStorage.getItem(`${FEATURE_KEY_PREFIX}AI_CONTENT_ASSIST`) !== 'false',
  })
  const userData = useUserDataContext()
  const {
    editorRef,
    exportModalOpen,
    setExportModalOpen,
    projectCategory,
    projectMediaType,
    showNextCTA,
    nextCTAText,
    updateElements,
    editorElementToolbarExtraFeatures,
    resetEditorElementToolbarExtraFeaturesState,
  } = useContext(EditorContext)

  const agentDataInjectionAttempts = useRef<number>(0)

  const handleToggleOptionEnabled = (featureKey: OptionEnabledKeys, checked: boolean) => {
    setOptionsEnabled((prev) => ({ ...prev, [featureKey]: checked }))
    localStorage.setItem(`${FEATURE_KEY_PREFIX}${featureKey}`, String(checked))
  }

  useEffect(() => {
    if (parentCategoryId && userData && updateElements && projectCategory && injectionFlag > -1) {
      const timeout = 1000 + agentDataInjectionAttempts.current * 1000
      const timeoutRef = setTimeout(() => {
        agentDataInjectionAttempts.current += 1
        try {
          const agentTCO = getDHInjectableAgentProfile(userData)
          updateElements(agentTCO, 2)
            .then(() => {
              setInjectionFlag(-1)
              clearTimeout(timeoutRef)
            })
            .catch((error) => {
              console.error('Failed to update elements', error)
              const shouldTryAgain = agentDataInjectionAttempts.current > AGENT_DATA_INJECTION_ATTEMPTS_LIMIT
              setInjectionFlag(shouldTryAgain ? Math.random() : -1)
            })
        } catch (err) {
          setInjectionFlag(-1)
        }
      }, timeout)

      return () => {
        clearTimeout(timeoutRef)
      }
    }
  }, [projectMediaType, parentCategoryId, userData, updateElements, projectCategory, injectionFlag])

  const rightColumnSection = useMemo(() => {
    if (!projectMediaType || projectMediaType === 'presentation') return null

    const options: Record<string, RightSideEditorColumnValue> = {
      listings: {
        label: 'Listings',
        Component: EditorListingsSection,
      },
      templates: {
        label: 'Templates',
        Component: EditorTemplatesSection,
      },
    }
    if (optionsEnabled.AI_CONTENT_ASSIST && editorElementToolbarExtraFeatures.elementId) {
      options.aiAssist = {
        label: 'AI Assist',
        Component: EditorAIAssistSection,
        onClose: () => {
          resetEditorElementToolbarExtraFeaturesState()
        },
      }
    }

    return (
      <>
        <RightSideEditorColumn
          options={options}
          optionsEnabled={optionsEnabled}
          handleToggleOptionEnabled={handleToggleOptionEnabled}
        />
      </>
    )
  }, [projectMediaType, editorElementToolbarExtraFeatures, optionsEnabled, resetEditorElementToolbarExtraFeaturesState])

  const bottomBarSections: Record<string, MobileEditorBottomBarSection> = {
    templates: { label: 'Templates', icon: <AutoAwesomeMosaicOutlinedIcon />, component: MobileEditorTemplatesDrawer },
    listings: {
      label: 'Listings',
      icon: <CorporateFareOutlinedIcon />,
      component: MobileEditorListingsDrawer,
    },
  }

  const handleOpenFinalize = useCallback(() => {
    if (exportModalOpen) {
      setExportModalOpen(false)
    }
    history.push(`${projectId}/finalize`, {
      projectCategory,
    })
  }, [exportModalOpen, projectCategory])

  const editorFallbackComponent = (
    <Grid container justifyContent='center' sx={{ width: '100%' }}>
      <Grid item>
        <Typography variant='button' onClick={() => window.location.reload()}>
          Please click here to reload the editor.
        </Typography>
      </Grid>
    </Grid>
  )

  useEffect(() => {
    hasFeature('dh-promote-cta-enabled').then((data) => {
      setDhPromoteCTAEnabled(data)
    })
  }, [hasFeature])

  const finalizeCTA = useMemo(
    () =>
      showNextCTA &&
      dhPromoteCTAEnabled && (
        <Button
          disableElevation
          disableRipple
          variant='contained'
          disabled={!editorRef}
          onClick={handleOpenFinalize}
          sx={{
            paddingX: 4,
            marginRight: 2,
            textTransform: 'uppercase',
            color: 'white',
            backgroundColor: '#2a29a6 !important',
            '&:hover': { backgroundColor: '#2a29a6 !important' },
          }}
          endIcon={<ChevronRight />}
          {...generateDataAttrs({
            metaAction: `${kebabCase(nextCTAText || 'Next')}`,
          })}
        >
          {nextCTAText || 'Next'}
        </Button>
      ),
    [editorRef, showNextCTA, nextCTAText, handleOpenFinalize]
  )

  const backCta = projectCategory?.assetType
    ? {
        label: 'Back to Templates',
        url: `/account/marketing/edit/gallery/${projectCategory.assetType}`,
      }
    : {
        label: 'Back to Discover',
        url: '/account/marketing/discover',
      }

  return (
    <EditorLayout
      key={projectId}
      rightColumn={rightColumnSection}
      bottomBarSections={bottomBarSections}
      topBarSection={finalizeCTA}
      backCtaText={backCta.label}
      backCtaLink={backCta.url}
      onExportClick={() => setExportModalOpen(true)}
    >
      <EditorExportShareModal
        open={exportModalOpen}
        onClose={() => setExportModalOpen(false)}
        mediaType={projectMediaType || 'digital'}
        projectId={projectId}
        promoteCTA={finalizeCTA}
        showDownload
        showEmailShare={false}
        showSocialShare
      />
      <ErrorBoundary fallback={editorFallbackComponent}>{projectId ? <DesignHuddleEditor /> : <div />}</ErrorBoundary>
    </EditorLayout>
  )
}
