import { createContext, ReactNode, useState } from 'react'
import { SwiperNavInfo } from '../domain/swiper-nav-info'

export type SwiperContextValue = {
  id: string
  navInfo: SwiperNavInfo
  updateNavInfo: (update: SwiperNavInfo | ((prev: SwiperNavInfo) => void)) => void
}

export const SwiperContext = createContext<SwiperContextValue>(null)

export const SwiperProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [id] = useState<string>(Math.random().toString(36).slice(2))
  const [navInfo, setNavInfo] = useState<SwiperNavInfo>(null)
  return <SwiperContext.Provider value={{ id, navInfo, updateNavInfo: setNavInfo }}>{children}</SwiperContext.Provider>
}
