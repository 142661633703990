import { Menupicker } from '@avenue-8/platform-style-util-frontend'
import { getFullAddress } from '../../editor/utils/get-full-address'
import { AgentListingDto } from '../../shared/domain/agent-listing.dto'

type ListingPickerType = {
  currentListing: AgentListingDto
  availableListings: AgentListingDto[]
  onChange?: (listing: AgentListingDto) => void
}

export default function ListingPicker({ currentListing, availableListings, onChange }: ListingPickerType): JSX.Element {
  return (
    <Menupicker
      selectedValue={currentListing ? getFullAddress(currentListing).toUpperCase() : ''}
      options={availableListings.map((c) => getFullAddress(c).toUpperCase())}
      onSelect={(value) => {
        onChange?.(availableListings.find((c) => getFullAddress(c).toUpperCase() === value))
      }}
    />
  )
}
