import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react'
import { defaultTheme, CSVUploader, Tooltip } from '@avenue-8/platform-style-util-frontend'
import styled from '@emotion/styled'
import { Select, MenuItem, Typography, SelectChangeEvent } from '@mui/material'
import { thousandsFormatter } from '../../../../shared/utils/formatters'
import { AddressAutoCompleteInput } from '../../address-auto-complete-input'
import { InputFieldContainer, InputLabel, UseType } from './common'
import { AddressDetails, AutocompletedAddress } from '../../../../shared/services/backend-app/backend-app.service.type'
import { csvToArray } from '../../../utils/CSVStringToArray'
import { VisualMarketingServiceContext } from '../../../../shared/services/visual-marketing/visual-marketing.service.context'
import { BackendAppServiceContext } from '../../../../shared/services/backend-app/backend-app.service.context'

const Hint = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 570px;
  height: 112px;
  background: ${defaultTheme.backgroundAlt};
  border-radius: 10px;
  margin: auto;
  margin-top: 15px;
`

const MailingListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${defaultTheme.backgroundAlt};
  border-radius: 10px;
  width: 538px;
  padding: 16px;
  margin: auto;
`

const BuildListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`

const Divider = styled.div`
  height: 20px;
`

const Estimates = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 60%;
`

const EstimatesItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 50px;
`

const ReachTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const EstimatesBlock = ({
  reach,
  listType,
}: {
  reach: number
  listType: 'uploaded-list' | 'data-built-list'
}): JSX.Element => {
  return (
    <Estimates>
      <EstimatesItem>
        <ReachTitleContainer>
          <Typography fontWeight={400} fontStyle='normal' fontSize='12px' lineHeight='12px' letterSpacing={2}>
            REACH
          </Typography>
          {listType === 'data-built-list' ? (
            <Tooltip
              title={''}
              description='This value is an estimation. Reach may be slightly lower if duplicate addresses are found.'
            />
          ) : null}
        </ReachTitleContainer>
        <Typography fontWeight={700} fontStyle='normal' fontSize='14px' lineHeight='14px'>
          {thousandsFormatter(reach) ?? 0}
        </Typography>
      </EstimatesItem>
    </Estimates>
  )
}

type CSVUploadResult = { isValid: boolean; rawData: string[]; fileData: Record<string, string>[] }

const validateFileUploaded = (data: string): CSVUploadResult => {
  const validHeaders = [
    'First Name',
    'Last Name',
    'Address Line 1',
    'Address Line 2',
    'City',
    'State/Province',
    'Country',
    'Zip',
    'Email',
    'Phone',
  ]
  let fileData = csvToArray(data, ',')
  const arrayData = data.split('\n')
  const rawData = [validHeaders.join(',')]

  if (fileData.length) {
    const fileHeaders = Object.keys(fileData[0])
    if (fileHeaders.some((header) => !validHeaders.includes(header))) {
      return { isValid: false, fileData: [], rawData }
    }
    const cleanedFileData = []

    fileData.forEach((row, idx) => {
      const isEmpty = (x: string) => x === null || x === '' || x === undefined
      const isEmptyRow = Object.values(row).every(isEmpty)
      if (!isEmptyRow) {
        cleanedFileData.push(row)
        rawData.push(arrayData[idx + 1])
      }
      fileData = cleanedFileData
    })
  }
  return { isValid: true, fileData, rawData }
}

export type PostcardMailingDetailsFormData = {
  type: 'uploaded-list' | 'data-built-list'
  estimatesCount: number
  postcardQuantity: number
  uploadedCSVFile?: Record<string, string>[]
  startingPoint?: string
  radius?: number
  addressDetails?: AddressDetails
}

type PostcardMailingListFormProps = {
  handlePostcardQuantityChange?: (quantity: number) => void
  handleListTypeChange: (listType: UseType) => void
  handleFileUpload: (fileData: { parsed: Record<string, string>[]; raw: string[] }) => void
  onFormChange: ({ isValid, payload }: { isValid: boolean; payload: PostcardMailingDetailsFormData }) => void
}

export const PostcardMailingListForm = (props: PostcardMailingListFormProps): JSX.Element => {
  const { handlePostcardQuantityChange, handleFileUpload, onFormChange } = props
  const { searchPeopleByGeoLocation, searchPeopleByBuildingAddress } = useContext(VisualMarketingServiceContext)
  const { getAddressDetails } = useContext(BackendAppServiceContext)
  const [useType, setUseType] = useState<UseType>('data-built-list')
  const [radius, setRadius] = useState<number>(1)
  const [loadingEstimates, setLoadingEstimates] = useState<boolean>(false)
  const [addressDetails, setAddressDetails] = useState<AddressDetails>()
  const [estimates, setEstimates] = useState<{ reach: number }>()
  const [formPayload, setFormPayload] = useState<PostcardMailingDetailsFormData>({
    type: 'data-built-list',
    estimatesCount: 0,
    postcardQuantity: 0,
    uploadedCSVFile: null,
    startingPoint: null,
    radius: 1,
    addressDetails: null,
  })

  const isEntireBuildingRadius = useMemo(() => radius && radius < 0, [radius])

  const updateValue = useCallback(
    (partial: Partial<PostcardMailingDetailsFormData>) => {
      const { addressDetails, type, estimatesCount, postcardQuantity, uploadedCSVFile, startingPoint, radius } =
        formPayload as Partial<PostcardMailingDetailsFormData>

      setFormPayload({
        addressDetails,
        type,
        estimatesCount,
        postcardQuantity,
        uploadedCSVFile,
        startingPoint,
        radius,
        ...partial,
      })
    },
    [formPayload]
  )

  const validatePayload = useCallback((postcardMailingDetailsFormData: PostcardMailingDetailsFormData) => {
    let isValid = true
    const errors = []
    const { type, addressDetails, uploadedCSVFile, postcardQuantity, estimatesCount } = postcardMailingDetailsFormData
    if (type === 'data-built-list') {
      if (estimatesCount < 1) {
        isValid = false
        errors.push('Error in estimates')
      }

      if (!addressDetails) {
        isValid = false
        errors.push('Address details can not be null')
      }
    } else {
      if (!uploadedCSVFile) {
        isValid = false
        errors.push('Uploaded file can not be null')
      }
    }

    if (postcardQuantity > estimatesCount) {
      isValid = false
      errors.push('Quantity can not be greater than estimates')
    }
    return { isValid, errors }
  }, [])

  const handleFileUploaded = (files: File[]) => {
    if (files.length > 0) {
      const reader = new FileReader()
      reader.readAsBinaryString(files[0])
      reader.onloadend = () => {
        const fileRawData = reader.result as string
        const cleanedData = fileRawData.replace(/(?:\\[rn]|[\r\n]+)+/g, '\n')
        const { fileData, rawData } = validateFileUploaded(cleanedData)
        setEstimates({ reach: fileData.length })
        handlePostcardQuantityChange(fileData.length)
        updateValue({
          estimatesCount: fileData.length,
          postcardQuantity: fileData.length,
          uploadedCSVFile: fileData,
        })
        handleFileUpload({ parsed: fileData, raw: rawData })
      }
    }
  }

  const handleFileDeleted = () => {
    setEstimates({ reach: 0 })
    handlePostcardQuantityChange(0)
    updateValue({
      estimatesCount: 0,
      postcardQuantity: 0,
    })
  }

  const handleStartingPointChange = async (address: AutocompletedAddress) => {
    try {
      if (!address?.placeId) {
        setEstimates({ reach: 0 })
        handlePostcardQuantityChange(0)
        setAddressDetails(undefined)
        updateValue({
          estimatesCount: 0,
          postcardQuantity: 0,
        })
        return
      }
      const response = await getAddressDetails(address.placeId)
      if (response.success === true) {
        updateValue({ addressDetails: response.data })
        setAddressDetails(response.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleRadiusChange = (event: SelectChangeEvent<number>) => {
    const newRadius = parseFloat(event.target.value.toString())
    updateValue({ radius: newRadius })
    setRadius(newRadius)
  }

  const handleListTypeChange = (event: SelectChangeEvent<UseType>) => {
    const type = event.target.value as UseType
    setUseType(type)
    updateValue({ type, estimatesCount: 0, postcardQuantity: 0 })
    setEstimates({ reach: 0 })
    props.handleListTypeChange(type)
    props.handlePostcardQuantityChange(0)
  }

  useEffect(() => {
    if (addressDetails && isEntireBuildingRadius) {
      setLoadingEstimates((prev) => prev || true)
      const { firstAddressLine, city, zip } = addressDetails
      searchPeopleByBuildingAddress(firstAddressLine, city, zip)
        .then((response) => {
          setEstimates({ reach: response.count })
          handlePostcardQuantityChange(response.count)
          updateValue({
            estimatesCount: response.count,
            postcardQuantity: response.count,
            addressDetails,
            radius,
          })
        })
        .finally(() => {
          setLoadingEstimates((prev) => prev && false)
        })
    } else if (radius && radius > 0 && addressDetails) {
      setLoadingEstimates((prev) => prev || true)
      const geoLocation = { lat: addressDetails.lat, lon: addressDetails.lng }
      searchPeopleByGeoLocation(geoLocation, radius, 'mi')
        .then((response) => {
          setEstimates({ reach: response.count })
          handlePostcardQuantityChange(response.count)
          updateValue({
            estimatesCount: response.count,
            postcardQuantity: response.count,
            addressDetails,
            radius,
          })
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setLoadingEstimates((prev) => prev && false)
        })
    }
  }, [radius, addressDetails, isEntireBuildingRadius])

  useEffect(() => {
    const { isValid } = validatePayload(formPayload)
    onFormChange({ isValid, payload: formPayload })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formPayload])

  return (
    <>
      <MailingListContainer>
        <Select variant='standard' onChange={handleListTypeChange} value={useType}>
          <MenuItem value={'data-built-list'}>Build a list</MenuItem>
          <MenuItem value={'uploaded-list'}>Upload a list</MenuItem>
        </Select>
        <Divider />
        {useType === 'data-built-list' ? (
          <BuildListContainer>
            <InputFieldContainer>
              <InputLabel>STARTING POINT</InputLabel>
              <AddressAutoCompleteInput selectedAddress={null} onSelectedAddressChange={handleStartingPointChange} />
            </InputFieldContainer>
            <Typography
              fontWeight={400}
              lineHeight={'10px'}
              fontSize={'10px'}
              color={defaultTheme.secondaryColor}
              fontStyle='normal'
              letterSpacing={2}
              style={{ marginLeft: '10px', marginRight: '10px' }}
            >
              WITHIN
            </Typography>
            <InputFieldContainer>
              <InputLabel>RADIUS</InputLabel>
              <Select sx={{ marginTop: '10px' }} variant='standard' onChange={handleRadiusChange} value={radius}>
                <MenuItem value={-1}>Entire Building</MenuItem>
                <MenuItem value={0.25}>0.25 miles</MenuItem>
                <MenuItem value={0.5}>0.5 miles</MenuItem>
                <MenuItem value={0.75}>0.75 miles</MenuItem>
                <MenuItem value={1}>1 mile</MenuItem>
                <MenuItem value={5}>5 miles</MenuItem>
                <MenuItem value={10}>10 miles</MenuItem>
              </Select>
            </InputFieldContainer>
          </BuildListContainer>
        ) : (
          <CSVUploader
            onFileUploaded={handleFileUploaded}
            onFileDeleted={handleFileDeleted}
            onUploadError={handleFileDeleted}
            exampleTemplateURL='https://avenue8-content-grid-uploads-production.s3.us-east-2.amazonaws.com/contact-list-template.csv'
          />
        )}
      </MailingListContainer>
      <Hint>
        {loadingEstimates && (
          <Typography
            fontWeight={400}
            lineHeight={'20px'}
            fontSize={14}
            color={defaultTheme.secondaryColor}
            fontStyle='normal'
          >
            Requesting estimates...
          </Typography>
        )}
        {!loadingEstimates && estimates && <EstimatesBlock reach={estimates?.reach} listType={useType} />}
        {!loadingEstimates && !estimates && (
          <Typography
            fontWeight={400}
            lineHeight={'20px'}
            fontSize={16}
            color={defaultTheme.secondaryColor}
            fontStyle='normal'
          >
            Upload or build a list to see delivery estimates.
          </Typography>
        )}
      </Hint>
    </>
  )
}
