const triggerDownload = async (data: Blob, fileName: string, options?: Record<string, unknown>): Promise<void> => {
  const url = window.URL.createObjectURL(data)
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  link.download = fileName
  link.id = 'download-anchor'
  document.body.appendChild(link)
  link.click()
}

export { triggerDownload }
