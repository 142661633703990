import { theme } from '@avenue-8/platform-style-util-frontend'
import { useListings } from '@avenue-8/platform-shared-util-frontend'
import { Check } from '@mui/icons-material'
import SearchOutlined from '@mui/icons-material/SearchOutlined'
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  ModalUnstyled,
  NativeSelect,
  Slide,
  ThemeProvider,
  Typography,
  TypographyProps,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { AgentListingDto, MLSDataPhotoType } from '../../../../shared/domain/agent-listing.dto'
import ListingTcoAdapter from '../../../../shared/domain/listing-to-tco'
import { EditorContext } from '../../../contexts/editor.context'
import { getFullAddress } from '../../../utils/get-full-address'
import { getProjectElements } from '../../../utils/get-project-elements'
import { MobileBottomBarModalProps } from './mobile-editor-bottom-bar'
import { MobileEditorListingsSearchModal } from './mobile-editor-listings-search-modal'
import { MobileModalContentWrapper } from '../../modal/mobile-modal-content-wrapper'
import { ModalTitle } from '../../../../shared/components/modal-title'
import { StyledBackdrop } from '../../../../shared/components/styled-backdrop'
import { ImageOverlay } from '../editor-listings-section/image-overlay'
import { StyledModal } from '../../styled-modal'

const Title = (props: TypographyProps): JSX.Element => (
  <Typography
    variant='button'
    color='secondary'
    marginBottom={2}
    fontSize={14}
    sx={{ textTransform: 'uppercase' }}
    {...props}
  />
)
const Value = (props: TypographyProps): JSX.Element => (
  <Typography variant='body2' sx={{ fontSize: '80%', marginTop: 1 }} {...props}>
    {props.children}
  </Typography>
)

const { format: moneyFormatter } = new Intl.NumberFormat('en-us', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
})

export const MobileEditorListingsDrawer = ({ open, onClose }: MobileBottomBarModalProps): JSX.Element => {
  const { listings } = useListings()
  const { updateElements, editorRef } = useContext(EditorContext)
  const [selectedListing, setSelectedListing] = useState<AgentListingDto | null>(null)
  const [imageCount, setImageCount] = useState<number>(1)
  const [updatingElementsStatus, setUpdatingElementsStatus] = useState<'LOADING' | 'IDLE'>('IDLE')
  const [searchModalOpen, setSearchModalOpen] = useState<boolean>(false)
  const [addedListings, setAddedListings] = useState<AgentListingDto[]>([])
  const [selectedPhotos, setSelectedPhotos] = useState<MLSDataPhotoType[]>([])

  const handleOpenSearchModal = () => {
    setSearchModalOpen(true)
  }
  const handleCloseSearchModal = () => {
    setSearchModalOpen(false)
  }
  const handleListingSelect = (id: number) => {
    const listing = listings.find((listing) => listing.id === id) ?? null
    setSelectedListing(listing)
  }
  const handleListingPhotoClick = (photo: MLSDataPhotoType) => {
    if (selectedPhotos.includes(photo)) {
      setSelectedPhotos(selectedPhotos.filter((p) => p !== photo))
    } else if (selectedPhotos.length < imageCount) {
      setSelectedPhotos([...selectedPhotos, photo])
    } else if (imageCount === 1) {
      setSelectedPhotos([photo])
    }
  }

  const handleListingApply = () => {
    setUpdatingElementsStatus('LOADING')
    updateElements(ListingTcoAdapter.transform({ ...selectedListing, photos: selectedPhotos }, true))
      .then(() => {
        setUpdatingElementsStatus('IDLE')
        onClose()
      })
      .catch((error) => {
        console.error('Failed to update elements with listing data', error)
        setUpdatingElementsStatus('IDLE')
      })
  }

  useEffect(() => {
    if (editorRef) {
      getProjectElements(editorRef).then((pages) => {
        const imageElementsCount: number = pages.reduce((previousCountSum, page) => {
          const imageClassesElementsInThisPage = Object.keys(page.classes).filter((key) => /^image/i.test(key)).length
          return imageClassesElementsInThisPage + previousCountSum
        }, 0)
        setImageCount(imageElementsCount)
      })
    }
  }, [editorRef])
  return (
    <>
      <StyledModal
        aria-labelledby='editor-switch-listing-modal'
        aria-describedby='editor-switch-listing-modal-content'
        open={open}
        BackdropComponent={StyledBackdrop}
      >
        <Slide in={open} direction={'up'} timeout={{ enter: 200, exit: 200 }} style={{ paddingTop: '75vh' }}>
          <div style={{ height: '100vh', overflowY: 'scroll', width: '100%' }}>
            <MobileModalContentWrapper
              style={{ minHeight: '25vh', height: 'auto', overflowY: 'unset', paddingBottom: '50vh' }}
            >
              <ModalUnstyled open={updatingElementsStatus === 'LOADING'} BackdropComponent={StyledBackdrop}>
                <CircularProgress />
              </ModalUnstyled>
              <ModalTitle onClose={onClose}>LISTING</ModalTitle>
              <ThemeProvider theme={theme}>
                <Grid container maxWidth='100%' paddingX={2} marginBottom={2} flexWrap='nowrap'>
                  <Grid item flexGrow={1} paddingRight={1}>
                    <NativeSelect
                      id='agent-listings-dropdown-input'
                      variant='standard'
                      placeholder='Your recent/active listings'
                      value={selectedListing?.id ?? ''}
                      onChange={(event) => {
                        handleListingSelect(Number(event.target.value))
                      }}
                    >
                      <option key=''>Select a listing to apply</option>
                      {[...addedListings, ...listings].map((listing, key) => {
                        return (
                          <option key={'listing-option' + key} value={listing.id}>
                            {getFullAddress(listing)}
                          </option>
                        )
                      })}
                    </NativeSelect>
                  </Grid>
                  <Grid item justifyContent='flex-end'>
                    <Button sx={{ maxWidth: '100%', minWidth: 32 }} onClick={handleOpenSearchModal}>
                      <SearchOutlined color='secondary' />
                    </Button>
                  </Grid>
                </Grid>
                <MobileEditorListingsSearchModal
                  open={searchModalOpen}
                  onClose={handleCloseSearchModal}
                  onListingSelect={(listing: AgentListingDto) => {
                    setAddedListings([listing, ...addedListings])
                    setSelectedListing(listing)
                    handleCloseSearchModal()
                  }}
                />
                {selectedListing && (
                  <Grid
                    container
                    width='calc(100% - 42px)'
                    padding={2}
                    margin={2}
                    sx={{ borderRadius: '8px', border: 'solid 1px #EEE' }}
                  >
                    <Grid item flexGrow={1} flexDirection='column'>
                      <Title>PROPERTY</Title>
                      <Value>{selectedListing.addressLine1}</Value>
                      <Value>{selectedListing.addressLine2}</Value>
                    </Grid>
                    <Grid item flexGrow={1} flexDirection='column'>
                      <Title>DETAILS</Title>
                      <Value>{moneyFormatter(selectedListing.currentPrice)}</Value>
                      <Value color='secondary'>
                        {selectedListing.numBedrooms.toFixed(0)}BD | {selectedListing.numBathrooms}BA
                      </Value>
                    </Grid>
                  </Grid>
                )}
                <Grid container width='100%'>
                  {selectedListing && selectedListing?.photos?.length && (
                    <Grid
                      item
                      container
                      marginY={1}
                      marginX={0}
                      paddingY={1}
                      paddingX={0}
                      flexWrap='nowrap'
                      width='100vw'
                      sx={{ overflowX: 'auto' }}
                    >
                      {selectedListing.photos.map((photo, idx, arr) => {
                        const isFirst = idx === 0
                        const isLast = idx === arr.length - 1
                        const isSelected = selectedPhotos.includes(photo)
                        return (
                          <Grid
                            item
                            key={'listing-photo-#' + (idx + 1)}
                            marginLeft={isFirst ? 2 : 1}
                            marginRight={isLast ? 3 : 1}
                            marginY={1}
                            sx={{ cursor: 'pointer', position: 'relative' }}
                            onClick={() => handleListingPhotoClick(photo)}
                          >
                            <img
                              src={photo.thumbnailUrl}
                              alt={`Listing's photo #${idx + 1}`}
                              height={64}
                              style={{ borderRadius: 8 }}
                            />
                            {isSelected && (
                              <ImageOverlay>
                                {selectedPhotos.length < 2 ? (
                                  <Check style={{ color: 'white' }} />
                                ) : (
                                  <Typography sx={{ color: 'white' }}>{selectedPhotos.indexOf(photo) + 1}</Typography>
                                )}
                              </ImageOverlay>
                            )}
                          </Grid>
                        )
                      })}
                    </Grid>
                  )}
                </Grid>
                <Container>
                  <Button fullWidth disabled={!selectedListing || !selectedPhotos.length} onClick={handleListingApply}>
                    Apply
                  </Button>
                </Container>
              </ThemeProvider>
            </MobileModalContentWrapper>
          </div>
        </Slide>
      </StyledModal>
    </>
  )
}
