/* eslint-disable camelcase */
import {
  CustomizationOptions,
  DesignHuddleTemplateCustomizationsObject,
} from '../../shared/domain/design-huddle-template-customizations-object'
import { PageElementWithId } from '../domain/project-data.type'

// TODO: Improve code readability.
export const generateReplaceableElementsTCO = (
  obj: Record<'elements' | 'classes', Record<string, PageElementWithId>>,
  source: Record<string, CustomizationOptions>
): DesignHuddleTemplateCustomizationsObject => {
  const classesEntries = Object.entries(obj.classes)
    .map(([key, element]): [string, CustomizationOptions] => {
      const { text, url, type, zone, zone_type } = element

      const [, value = { text, url }] =
        Object.entries(source).find(([key]) => element.element_classes.includes(key)) || []

      const isMasked = type === 'masked_media' || zone_type === 'mask' || zone?.zone_type === 'mask'

      const masked = value?.url && isMasked ? { masked_media: { url: value.url } } : null

      return [element.elementId, masked ? { ...value, ...masked } : value]
    })
    .filter(([_key, { text, url }]) => !!text || !!url)

  const classesElements = Object.fromEntries(classesEntries)

  return { elements: { ...classesElements, ...obj.elements } }
}
