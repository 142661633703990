import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  WebviewBridgeContext,
  WebviewBridgeHandlerName,
  MediaType,
  ExportShareModal as ExportShareModalWrapper,
  useFeatureFlags,
} from '@avenue-8/platform-shared-util-frontend'
import {
  useScreenSize,
  ExportShareModal,
  SocialShareOption,
  DownloadFormat,
  Handlers,
} from '@avenue-8/platform-style-util-frontend'
import JSZip from 'jszip'
import { DesignHuddleProxyContext, GenerateContentParams } from '../../../shared/contexts/design-huddle-proxy.context'
import { triggerDownload } from '../../../shared/utils/download'
import { EditorContext } from '../../contexts/editor.context'
import { DesignHuddleProxyJob } from '../../../shared/domain/design-huddle-proxy-project.type'
import useInterval from '../../../shared/hooks/useInterval'
import EditorExportPresentationModal from './editor-export-presentation-modal'

const socialMediaShareOnDesktopMessage = `To share this on social media, please use the Avenue 8 mobile app.`
const POLLING_TIME_INTERVAL = 1000

export type EditorExportShareModalProps = {
  open: boolean
  onClose: () => void
  projectId?: string
  mediaType?: MediaType
  showDownload?: boolean
  showEmailShare?: boolean
  showSocialShare?: boolean | SocialShareOption
  promoteCTA?: JSX.Element
}

export const EditorExportShareModal = (props: EditorExportShareModalProps): JSX.Element => {
  const { open, onClose, projectId, mediaType, showEmailShare, showDownload, showSocialShare } = props
  const { isDesktop, isSmallScreen } = useScreenSize()
  const { editorRef, getLowResPagesRenders, projectMediaType, fileName, pages } = useContext(EditorContext)
  const {
    createDownloadJob,
    getDownloadJobStatus,
    createPresentationLinkJob,
    downloadPresentationLinkJob,
    generateContent,
  } = useContext(DesignHuddleProxyContext)
  const { postMessage } = useContext(WebviewBridgeContext) ?? {}
  const [status, setStatus] = useState<'IDLE' | 'LOADING' | 'ERROR'>('LOADING')
  const [captionStatus, setCaptionStatus] = useState<'IDLE' | 'LOADING' | 'ERROR'>('IDLE')
  const [pagePreviews, setPagePreviews] = useState<{ url: string; id: string }[]>([])
  const [downloadStatus, setDownloadStatus] = useState<'IDLE' | 'JOB_CREATED' | 'JOB_FINISHED' | 'DONE'>('IDLE')
  const [downloadJob, setDownloadJob] = useState<DesignHuddleProxyJob>(null)
  const [downloadedFiles, setDownloadedFiles] = useState<{ url: string; page: number }[]>()
  const [downloadFileName, setDownloadFileName] = useState('')
  const [selectedFormat, setSelectedFormat] = useState<DownloadFormat>()
  const [presentationJob, setPresentationJob] = useState<string>(null)
  const [presentationStatus, setPresentationStatus] = useState<'IDLE' | 'JOB_CREATED' | 'JOB_FINISHED' | 'DONE'>('IDLE')
  const [presentationUrl, setPresentationUrl] = useState<string>('')
  const [caption, setCaption] = useState('')
  const { hasFeature } = useFeatureFlags()
  const [dhPromoteCTAEnabled, setDhPromoteCTAEnabled] = useState(false)

  useEffect(() => {
    hasFeature('dh-promote-cta-enabled').then((data) => {
      setDhPromoteCTAEnabled(data)
    })
  }, [hasFeature])

  const requestProjectMediaDownload = async (projectId: string, format: DownloadFormat, selectedPage: string) => {
    const job = await createDownloadJob(projectId, format, selectedPage)
    setDownloadStatus('JOB_CREATED')
    setDownloadJob(job)
  }

  const requestPresentationDownload = async (projectId: string) => {
    const { data } = await createPresentationLinkJob(projectId)
    setPresentationJob(data.jobId)
    setPresentationStatus('JOB_CREATED')
  }

  // Pooling to check job status after its created
  useInterval({
    callback: async () => {
      if (downloadJob?.jobId && downloadStatus === 'JOB_CREATED') {
        const { pages, status } = await getDownloadJobStatus(downloadJob.jobId)
        if (status === 'complete') {
          // eslint-disable-next-line no-console
          console.log('Download finished:', pages)
          setDownloadedFiles(pages.sort((a, b) => a.page - b.page))
          setDownloadStatus('JOB_FINISHED')
        }
      }
    },
    delay: downloadStatus === 'JOB_FINISHED' ? null : POLLING_TIME_INTERVAL,
  })

  // Pooling to check presentation job status after its created
  useInterval({
    callback: async () => {
      if (presentationJob && presentationStatus === 'JOB_CREATED') {
        setStatus('LOADING')
        const result = await downloadPresentationLinkJob(projectId, presentationJob)
        if (result.success && result.data.presentationUrl) {
          setPresentationUrl(result.data.presentationUrl)
          setPresentationStatus('JOB_FINISHED')
          setStatus('IDLE')
        }
      }
    },
    delay: presentationStatus === 'JOB_FINISHED' ? null : POLLING_TIME_INTERVAL,
  })

  /* TODO: Uncomment these lines to request the presentation url
  useEffect(() => {
    if (projectMediaType === 'presentation') {
      requestPresentationDownload(projectId)
    }
  }, [projectMediaType])
  */

  useEffect(() => {
    if (open && editorRef && projectMediaType !== 'presentation') {
      setStatus('LOADING')
      getLowResPagesRenders()
        .then((pages) => {
          setStatus('IDLE')
          setPagePreviews(
            pages.map(({ pageId, encodedImage, sceneId }) => ({
              url: encodedImage,
              id: pageId || sceneId,
              sceneId: sceneId,
            }))
          )
        })
        .catch((error) => {
          setStatus('ERROR')
          console.error('failed to get low res render', error)
        })
    } else {
      setStatus('IDLE')
      setPagePreviews([])
    }
  }, [projectId, open, editorRef, getLowResPagesRenders])

  // listen to downloadedFiles change
  useEffect(() => {
    if (isSmallScreen && postMessage) {
      postMessage(WebviewBridgeHandlerName.download, { url: downloadedFiles.map((f) => f.url) })
    } else if (isDesktop && downloadedFiles.length) {
      const formattedFiles = downloadedFiles.map((file) => ({ url: file.url, id: file.page.toString() }))
      if (downloadStatus !== 'JOB_FINISHED' && downloadedFiles.length > 1) {
        downloadMultipleImages(downloadFileName, formattedFiles, selectedFormat)
      } else if (downloadStatus !== 'JOB_FINISHED' && downloadedFiles.length === 1) {
        downloadSingleFile(downloadFileName, formattedFiles[0], selectedFormat)
      }
      setStatus('IDLE')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadedFiles]) // TODO: fix this effect loop with the proper deps

  const downloadMultipleImages = async (
    filename: string,
    files: { url: string; id: string }[],
    format: DownloadFormat
  ): Promise<boolean> => {
    try {
      const zip = new JSZip()

      for (let idx = 0; idx < files.length; idx++) {
        const image = files[idx]
        let fileData = null

        const response = await fetch(image.url)
        fileData = await response.blob()

        zip.file(`${idx + 1}_page.${format}`, fileData, { base64: true })
      }

      const zipFile = await zip.generateAsync({ type: 'blob' })
      const cleanFilename = filename.replace(/[.]/g, '')
      await triggerDownload(zipFile, `${cleanFilename}.zip`)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const downloadSingleFile = async (
    filename: string,
    file: { id: string; url: string },
    format: DownloadFormat
  ): Promise<boolean> => {
    try {
      const response = await fetch(file.url)
      const fileData = await response.blob()
      const cleanFilename = filename.replace(/[.]/g, '')
      await triggerDownload(fileData, `${cleanFilename}.${format}`)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const handleDownloadClick = async (fileName: string, fileType: DownloadFormat, selectedPage?: string) => {
    setDownloadFileName(fileName.trim().length ? fileName.trim() : 'av8_design')
    setSelectedFormat(fileType)
    setStatus('LOADING')
    try {
      await requestProjectMediaDownload(projectId, fileType, selectedPage)
    } catch (err) {
      setStatus('IDLE')
      console.error('Error downloading project', err)
    }
  }

  const handleEmailShareClick = (emailAddress: string) => {
    // handle email sharing here
    props.onClose()
  }

  const handleSocialShare = (network: SocialShareOption, mediaArray: string[]) => {
    if (isSmallScreen && postMessage) {
      postMessage(WebviewBridgeHandlerName[network], { url: mediaArray })
    }
  }

  const handleGenerateCaption = useCallback(
    (params: GenerateContentParams) => {
      setCaptionStatus('LOADING')
      generateContent({ ...params, contentType: 'CAPTION' })
        .then((data) => {
          setCaption(data.outputs[0])
        })
        .catch((e) => {
          console.error(e)
          alert(`Error trying to generate caption.`)
        })
        .finally(() => {
          setCaptionStatus('IDLE')
        })
    },
    [generateContent]
  )

  const handlers = useMemo<Handlers[]>(() => {
    return Object.entries({
      preview: isDesktop,
      download: showDownload && handleDownloadClick,
      email: showEmailShare && handleEmailShareClick,
      socialShare: postMessage && showSocialShare && handleSocialShare,
      caption: true,
    })
      .filter(([_, handlerValue]) => !!handlerValue)
      .map(([handlerName]) => handlerName as Handlers)
  }, [
    showDownload,
    showEmailShare,
    showSocialShare,
    isDesktop,
    postMessage,
    handleDownloadClick,
    handleEmailShareClick,
    handleSocialShare,
  ])

  if (projectMediaType === 'presentation') {
    return (
      <EditorExportPresentationModal
        open={open}
        onClose={onClose}
        title='You‘re almost done! Please review your asset.'
        status={status}
        values={{
          fileName,
          presentationUrl,
        }}
        onDownloadClick={props.showDownload && handleDownloadClick}
        handlers={handlers}
        pages={pages}
      />
    )
  }

  return (
    <ExportShareModalWrapper
      PresentionalComponent={ExportShareModal}
      status={status}
      captionStatus={captionStatus}
      open={open}
      onClose={onClose}
      title='You‘re almost done! Please review your asset.'
      subtitle='Please make sure everything looks right before downloading.'
      pagePreviews={pagePreviews}
      handlers={handlers}
      defaultValues={{ caption }}
      mediaType={mediaType}
      socialMediaShareMessage={socialMediaShareOnDesktopMessage}
      onDownloadClick={props.showDownload && handleDownloadClick}
      onEmailShareClick={props.showEmailShare && handleEmailShareClick}
      onSocialShareClick={postMessage && props.showSocialShare && handleSocialShare}
      promoteCTA={dhPromoteCTAEnabled && !isDesktop && props.promoteCTA}
      onGenerateCaptionClick={handleGenerateCaption}
      onChangeCaption={(caption: string) => setCaption(caption)}
    />
  )
}
