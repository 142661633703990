import { PropsWithChildren, ReactNode } from 'react'
import { HistoryUserMenu } from '@avenue-8/platform-shared-util-frontend'
import { UserAvatarDropDown } from '@avenue-8/platform-style-util-frontend'
import { TopBar } from './topbar'

export const BaseTopBar = ({
  title,
  children,
  alignItemsOnTop,
}: PropsWithChildren<{ title: string | ReactNode; alignItemsOnTop?: boolean }>): JSX.Element => {
  return (
    <TopBar title={title} alignItemsOnTop={alignItemsOnTop}>
      {children}
      <HistoryUserMenu UserMenuComponent={UserAvatarDropDown} />
    </TopBar>
  )
}
