/* eslint-disable camelcase, multiline-ternary */
import { InternalNavbar } from '@avenue-8/platform-style-util-frontend'
import { useListings, datadogRum } from '@avenue-8/platform-shared-util-frontend'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { AgentListingDto } from '../../shared/domain/agent-listing.dto'
import { DesignHuddleCategoriesContext } from '../../shared/contexts/design-huddle-categories.context'
import { DesignHuddleProxyTemplate as Template } from '../../shared/domain/design-huddle-proxy-template.type'
import { DesignHuddleContext } from '../../shared/contexts/design-huddle-sdk.context'
import { DesignHuddleProxyContext } from '../../shared/contexts/design-huddle-proxy.context'
import ListingTcoAdapter from '../../shared/domain/listing-to-tco'
import { SwiperProvider } from '../context/swiper-context'
import { createProject } from '../helpers/design-huddle-helpers'
import { getTemplatePreview } from '../helpers/get-template-preview'
import { registerTco } from '../helpers/register-tco'
import { SettledPromisesFilter } from '../helpers/settled-promises-filter'
import { TemplateSlides } from './template-slides'
import ListingPicker from './listing-picker'

export const GalleryPage = (): JSX.Element => {
  const history = useHistory()
  const { templateType } = useParams<{ templateType?: string }>()

  const { dhSdk } = useContext(DesignHuddleContext)
  const { assetTypesDict } = useContext(DesignHuddleCategoriesContext)
  const { getTemplates } = useContext(DesignHuddleProxyContext)

  const { listings: availableListings } = useListings()

  const [currentListing, setCurrentListing] = useState<AgentListingDto>()
  const [loadingOverlay, setLoadingOverlay] = useState<boolean>(false)

  const [tcoGenerationFailed, setTcoGenerationFailed] = useState<boolean>(false)

  const [loadingGenericTemplates, setLoadingGenericTemplates] = useState<boolean>(true)
  const [loadingListingTemplates, setLoadingListingTemplates] = useState<boolean>(true)

  const [listingTemplateCategoryId, setListingTemplateCategoryId] = useState<number>()
  const [generalTemplateCategoryId, setGeneralTemplateCategoryId] = useState<number>()
  const [categoryLabel, setCategoryLabel] = useState<string>('Templates Gallery')

  const [prePopulatedTemplates, setPrePopulatedTemplates] = useState<Template[]>([])

  const [categoryTemplates, setCategoryTemplates] = useState<Template[]>([])
  const [listingTemplates, setListingTemplates] = useState<Template[]>([])

  const [listingLabel, setListingLabel] = useState<string>('FOR LISTING')
  const [generalLabel, setGeneralLabel] = useState<string>('GENERAL PURPOSE')

  useEffect(() => {
    if (!currentListing && availableListings.length > 0) setCurrentListing(availableListings[0])
  }, [availableListings, currentListing])

  const createProjectAndStartEditing = useCallback(
    (template: Template, noTCO = false) => {
      if (dhSdk) {
        const tco = noTCO ? undefined : !currentListing ? null : ListingTcoAdapter.transform(currentListing, true)
        setLoadingOverlay(true)
        createProject(dhSdk, template.templateId, tco)
          .then((projectId) => {
            setLoadingOverlay(false)
            history.push(`/edit/project/${projectId}?parentCategoryId=${template.categoryItem?.id}`)
          })
          .catch((error) => {
            setLoadingOverlay(false)
            console.error('Failed to create project:', error)
          })
      }
    },
    [dhSdk, currentListing, createProject]
  )

  useEffect(() => {
    if (!templateType || !assetTypesDict || !Object.keys(assetTypesDict).length) return
    const category = assetTypesDict[templateType]

    if (category) {
      setGeneralTemplateCategoryId(category.general)
      setListingTemplateCategoryId(category.listing)
      setCategoryLabel(category.label)
      if (category.label === 'Presentation') {
        setListingLabel('LISTING PRESENTATIONS')
        setGeneralLabel('BUYER PRESENTATIONS')
      }
    } else {
      // TODO must set an Error state in this case
      console.error('Could not find category for', templateType)
    }
  }, [assetTypesDict, templateType])

  useEffect(() => {
    if (getTemplates && generalTemplateCategoryId) {
      setLoadingGenericTemplates(true)
      getTemplates(generalTemplateCategoryId.toString())
        .then(({ data }) => {
          setCategoryTemplates((previousData) => {
            return JSON.stringify(previousData) !== JSON.stringify(data) ? data : previousData
          })
        })
        .finally(() => {
          setLoadingGenericTemplates(false)
        })
    } else {
      setLoadingGenericTemplates(false)
    }
  }, [getTemplates, generalTemplateCategoryId])

  useEffect(() => {
    if (getTemplates && listingTemplateCategoryId) {
      setLoadingListingTemplates(true)
      getTemplates(listingTemplateCategoryId.toString()).then(({ data }) => {
        setPrePopulatedTemplates((previousData) =>
          JSON.stringify(previousData) !== JSON.stringify(data) ? data : previousData
        )
        setListingTemplates((previousData) =>
          JSON.stringify(previousData) !== JSON.stringify(data) ? data : previousData
        )
      })
    }
  }, [getTemplates, listingTemplateCategoryId])

  useEffect(() => {
    if (!dhSdk || listingTemplates.length < 1) {
      return
    }
    setLoadingListingTemplates(true)
    if (!currentListing) {
      setPrePopulatedTemplates(listingTemplates)
      setLoadingListingTemplates(false)
    } else {
      const tco = ListingTcoAdapter.transform(currentListing, true)
      const fetchTemplatePreview = getTemplatePreview(dhSdk)
      registerTco(dhSdk)(tco)
        .then((tcoHash: string) => {
          setTcoGenerationFailed(false)
          return listingTemplates.map(
            (template): Promise<{ templateId: number; url: string }> =>
              fetchTemplatePreview(template.templateId, tcoHash ?? undefined)
                .then(({ imageUrl: url }) => ({ templateId: template.templateId, url }))
                .catch((error) => {
                  console.error(template.templateId, error)
                  datadogRum.addError(error, { extra: { template, listing: currentListing, tco } })
                  return { templateId: template.templateId, url: template.previewUrl }
                })
          )
        })
        .then((promises) => Promise.allSettled(promises))
        .then((settledResults) => Array.from(settledResults))
        .then((data) => {
          const successfulResponses = data.filter(SettledPromisesFilter)
          const responseValues = successfulResponses.map(({ value }) => [value.templateId, value.url])
          const dict = Object.fromEntries(responseValues)

          const newPrePopulatedTemplates = listingTemplates.map(
            (t: Template): Template => ({
              ...t,
              thumbnailUrl: dict[t.templateId] ?? t.thumbnailUrl,
            })
          )
          setPrePopulatedTemplates(newPrePopulatedTemplates)
        })
        .catch((error) => {
          console.error(`Failed to get preview thumbnails in gallery page for ${templateType}`, error)
          setTcoGenerationFailed(true)
          datadogRum.addError(error, { extra: { templateType, tco } })
          setPrePopulatedTemplates(listingTemplates)
        })
        .finally(() => {
          setLoadingListingTemplates(false)
        })
    }
  }, [dhSdk, listingTemplates, availableListings, currentListing])

  return (
    <Box maxWidth='xl' sx={{ paddingX: { xs: 2.5, md: 6 } }}>
      <Modal open={loadingOverlay}>
        <Box
          sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress color='secondary' />
        </Box>
      </Modal>
      <InternalNavbar
        title={categoryLabel}
        showBackButton
        onBackButtonClicked={() => {
          window.location.href = '/account/marketing/discover'
        }}
      />
      <SwiperProvider key='listing-template-swiper'>
        <TemplateSlides
          sectionTitle={listingLabel}
          customSectionTitleComponent={
            <>
              {availableListings.length > 0 && (
                <ListingPicker
                  currentListing={currentListing}
                  availableListings={availableListings}
                  onChange={(listing) => {
                    setCurrentListing(listing)
                    setTcoGenerationFailed(false)
                  }}
                />
              )}
              {tcoGenerationFailed && (
                <Typography
                  component={'span'}
                  variant={'caption'}
                  sx={{ fontSize: '0.75em', fontWeight: '600', color: 'rgba(245, 50, 40, 0.6)' }}
                >
                  Sorry, we are having trouble with the listing images. Please add manually.
                </Typography>
              )}
            </>
          }
          templatesList={prePopulatedTemplates}
          onTemplateClick={(template) => {
            createProjectAndStartEditing(template, tcoGenerationFailed)
          }}
          loading={loadingListingTemplates}
        />
      </SwiperProvider>
      <Box sx={{ marginBottom: { xs: 8, md: 2 } }}>
        <SwiperProvider key='general-templates-swiper'>
          <TemplateSlides
            sectionTitle={generalLabel}
            templatesList={categoryTemplates}
            onTemplateClick={(template) => createProjectAndStartEditing(template, true)}
            loading={loadingGenericTemplates}
          />
        </SwiperProvider>
      </Box>
    </Box>
  )
}
