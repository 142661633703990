import { useListings } from '@avenue-8/platform-shared-util-frontend'
import { InfoOutlined } from '@mui/icons-material'
import { Checkbox, FormControlLabel, FormGroup, Grid, Stack, TextField, Tooltip } from '@mui/material'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import { useContext, useEffect, useState } from 'react'
import { AgentListingDto, MLSDataPhotoType } from '../../../../shared/domain/agent-listing.dto'
import ListingTcoAdapter from '../../../../shared/domain/listing-to-tco'
import { EditorContext } from '../../../contexts/editor.context'
import { getFullAddress } from '../../../utils/get-full-address'
import { getProjectElements } from '../../../utils/get-project-elements'
import { ListingsAutoCompleteInput } from '../../listings-auto-complete-input'
import { FoundListingBox } from './found-listing-box'
import { ListingPhotoSelector } from './listing-photo-selector'

const termsKey = 'pam2-visual-accepted-listing-usage-terms'
const imageClassNameRegex = /image\d+/i

export const EditorListingsSection = ({ onClose: _onClose }: { onClose?: () => void }): JSX.Element => {
  const { listings } = useListings()
  const { editorRef, updateElements } = useContext(EditorContext)
  const [selectedListings, setSelectedListings] = useState<AgentListingDto[]>([])
  const [selectedPhotos, setSelectedPhotos] = useState<MLSDataPhotoType[]>([])
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false)
  const [requireTerms, setRequireTerms] = useState<boolean>(false)
  const [showImageSelector, setShowImageSelector] = useState<boolean>(false)
  const [imagesCountFoundInProject, setImagesCountFoundInProject] = useState<number>(0)

  useEffect(() => {
    const value = sessionStorage.getItem(termsKey) ?? ''
    setAcceptedTerms(value === 'true')
  }, [])

  useEffect(() => {
    sessionStorage.setItem(termsKey, JSON.stringify(acceptedTerms))
  }, [acceptedTerms])

  useEffect(() => {
    getProjectElements(editorRef).then((projectPagesElements) => {
      const imageClassesSet = projectPagesElements.reduce((set, page) => {
        Object.keys(page.classes)
          .filter((className) => imageClassNameRegex.test(className))
          .forEach((className) => set.add(className))
        const imagesClasses = Object.values(page.elements)
          .map((element) => element.element_classes.find((className) => imageClassNameRegex.test(className)))
          .filter((v) => !!v)
        imagesClasses.forEach((className) => set.add(className))
        return set
      }, new Set<string>([]))
      setImagesCountFoundInProject(imageClassesSet.size)
    })
  }, [editorRef])

  const handleOpenImageSelector = () => {
    setShowImageSelector(true)
  }

  const handleListingSelect =
    (doRequireTerms = false) =>
    (listing: AgentListingDto | null) => {
      setRequireTerms(doRequireTerms)
      setSelectedPhotos(listing?.photos?.slice(0, imagesCountFoundInProject) ?? [])
      setSelectedListings(listing ? [listing] : [])
    }

  const handleListingApply = (listing: AgentListingDto) => {
    const listingToTransform: typeof listing = {
      ...listing,
      photos: selectedPhotos,
    }
    const tco = ListingTcoAdapter.transform(listingToTransform, true)
    updateElements(tco)
      .then(() => {
        // onClose?.()
      })
      .catch((error) => {
        alert('Failed to apply listing data')
        console.error('Failed to apply listing data', error.message, error)
      })
  }

  if (showImageSelector) {
    return (
      <ListingPhotoSelector
        maxSelectedPhotos={imagesCountFoundInProject}
        photos={selectedListings.flatMap(({ photos }) => photos)}
        selectedImages={selectedPhotos}
        onGoBack={() => {
          setShowImageSelector(false)
        }}
        onSelectedImagesChange={(handler) => {
          setSelectedPhotos(handler)
        }}
      />
    )
  }

  return (
    <Stack spacing={2} sx={{ padding: { md: '8px' } }}>
      <Grid container>
        <Grid item>
          <Typography
            variant='button'
            color='secondary'
            sx={{ textTransform: 'uppercase', color: 'black' }}
            fontSize={16}
          >
            Find a listing{' '}
            <Tooltip title={'Find a listing tooltip'}>
              <InfoOutlined fontSize={'inherit'} style={{ marginBottom: '-4px', fontSize: '120%', color: 'gray' }} />
            </Tooltip>
          </Typography>
          <Typography variant='body1' color='secondary' sx={{ mt: '4px' }}>
            Search for a listing or select from the MLS to apply a listing to your asset.
          </Typography>
        </Grid>
      </Grid>
      <ListingsAutoCompleteInput
        label='SEARCH'
        selectedListing={selectedListings[0]}
        onSelectedListingChange={handleListingSelect(true)}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptedTerms}
              onChange={({ target: { checked } }) => {
                setAcceptedTerms(checked)
              }}
            />
          }
          label={
            <small>
              By selecting a listing that is not submitted to the MLS, I agree to follow the guidelines of the Clear
              Cooperation Policy, regarding marketing to the public.
            </small>
          }
        />
      </FormGroup>
      <TextField
        select
        label='Listings'
        variant='outlined'
        inputProps={{ notched: false, sx: { padding: '4px' } }}
        SelectProps={{ native: true }}
        value={''}
        onChange={(event) => {
          const listingId = event.target.value
          const selected = listings.find(({ mlsSource, mlsId }) => listingId === `${mlsSource}/${mlsId}`) ?? null
          handleListingSelect(false)(selected)
        }}
      >
        <option value=''>Select a listing to apply</option>
        {listings.map((listing, key) => {
          return (
            <option key={key} value={`${listing.mlsSource}/${listing.mlsId}`}>
              {getFullAddress(listing)}
            </option>
          )
        })}
      </TextField>
      <List dense>
        {selectedListings.map((listing, idx) => {
          return (
            <FoundListingBox
              key={`listing-${idx + 1}`}
              applyDisable={requireTerms && !acceptedTerms}
              onApplyClick={(listing) => handleListingApply({ id: -1, ...listing })}
              onOpenImageSelector={handleOpenImageSelector}
              listing={listing}
              selectedPhotos={selectedPhotos}
              maxSelectedPhotos={imagesCountFoundInProject}
            />
          )
        })}
      </List>
    </Stack>
  )
}
